import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import ActorCasting from './Actor/ActorCasting'
import ContributorCasting from './Contributor/ContributorCasting'
import DirectorCasting from './CastingDirector/DirectorCasting'
import { CUSTOMER_ROLE } from 'types/user'

const Casting = (): JSX.Element | null => {
	const { pathname } = useLocation()
	const userRole = pathname?.split('/')[1]

	const component = useMemo(() => {
		const components = {
			[CUSTOMER_ROLE.ACTOR]: <ActorCasting />,
			[CUSTOMER_ROLE.AGENCY]: <DirectorCasting />,
			[CUSTOMER_ROLE.CASTING_DIRECTOR]: <DirectorCasting />,
			[CUSTOMER_ROLE.CONTRIBUTOR]: <ContributorCasting />,
		}

		return userRole ? components[userRole as CUSTOMER_ROLE] : null
	}, [userRole])

	return component
}

export default Casting
