import { createWithEqualityFn } from 'zustand/traditional'
import { ChangeEvent } from 'react'
import { createContentAsset, partialContentUpdate } from 'api/content'
import { ProductionStore } from 'types/store'
import { prodProperties } from 'pages/Casting/CastingDirector/components/CreateProduction/CreateProduction'
import {
	roleProperties,
	RolePropertyKey,
} from 'pages/Casting/CastingDirector/components/RoleBasic/RoleBasic'

const initialState = {
	production: {
		name: '',
		description: '',
		rehearsal: [],
		filming: [],
		nda: false,
		color: '',
		locations: [],
		format: ['intro', 'slate', 'tape'],
		ndaFile: null,
		imdbLink: 'https://www.imdb.com/name/',
		productionType: '',
		directorName: '',
		cover: null,
	},
	roles: {},
	currentRole: '',
	scene: {
		title: '',
		duration: 0,
		code: '',
		attachments: [],
		dress: '',
		instructions: [],
	},
	openImagesSidebar: false,
	openNdaSidebar: false,
	openCoverSidebar: false,
	openInstructionsSidebar: false,
	loading: false,
}

const useStore = createWithEqualityFn<ProductionStore>(
	(set, get) => ({
		...initialState,
		setScene: (scene) =>
			set((state) => ({
				scene: {
					...state.scene,
					...scene,
				},
			})),
		setProduction: (key, value) =>
			set((state) => ({ production: { ...state.production, [key]: value } })),
		setRoles: (role, values, newRoleName) =>
			set((state) => ({
				roles: {
					...state.roles,
					[newRoleName || role]: {
						...state.roles[role],
						...values,
					},
				},
			})),
		setCurrentRole: (role) => set({ currentRole: role }),
		setOpenImagesSidebar: (open) => set({ openImagesSidebar: open }),
		setLoading: (loading) => set({ loading }),
		resetStore: () => set(initialState),
		resetRoles: () => set({ roles: {}, currentRole: '' }),
		setOpenNdaSidebar: (open) => set({ openNdaSidebar: open }),
		setNdaFile: (file) =>
			set((state) => ({ production: { ...state.production, ndaFile: file } })),
		createAsset: async (
			uploadData,
			name,
			fileName,
			objectUrl,
			publicUrl,
			contentId,
			subType,
		) => {
			try {
				await createContentAsset(
					{
						...uploadData,
						originalTitle: name,
						originalLanguage: 'en-US',
						type: 'Image',
						locale: 'en-US',
						localizations: {
							'en-US': { name: fileName },
						},
						originalFileName: fileName,
						objectUrl,
						publicUrl,
						contentId,
						subType: subType || '',
					},
					contentId,
				)
			} catch (error) {
				console.log('error:', error)
			}
		},
		currentScene: '',
		setCurrentScene: (scene) => set({ currentScene: scene }),
		inviteList: [],
		setInviteList: (user) =>
			set((state) => ({
				inviteList: state.inviteList?.find(
					(u) => u.ContentId === user.ContentId,
				)
					? state.inviteList?.filter((u) => u?.ContentId !== user?.ContentId)
					: [...state.inviteList, user],
			})),
		clearInviteList: () => set({ inviteList: [] }),
		openLocationSidebar: false,
		setOpenLocationSidebar: (open) => set({ openLocationSidebar: open }),
		setOpenInstructionsSidebar: (open) =>
			set({ openInstructionsSidebar: open }),
		setOpenCoverSidebar: (open) => set({ openCoverSidebar: open }),
		onChange: (e) => {
			const { setProduction } = get()
			setProduction(e.target.name, e.target.value)
		},
		onBlur: (e, productionId) => {
			const { production } = get()
			let strategy = ''
			let data = {}
			if (e.target.name === 'locations') {
				strategy = strategy + '?strategy=0'
				data = {
					labels: {
						Place: e.target.value,
					},
				}
			} else if (['name', 'description'].includes(e.target.name))
				data = {
					localizations: {
						'en-US': {
							name: production?.name,
							description: production?.description,
							[e.target.name]: e.target.value,
						},
					},
				}
			else
				data = {
					properties: {
						[prodProperties[e.target.name as string]]: [
							'filming',
							'rehearsal',
							'format',
						].includes(e.target.name)
							? JSON.stringify(e.target.value)
							: e.target.value,
					},
				}

			if (productionId) partialContentUpdate(productionId, data, '', strategy)
		},
		onChangeEvent: (name, value, productionId) => {
			const event = {
				target: {
					name,
					value,
				},
			} as ChangeEvent<HTMLInputElement>
			const { onChange, onBlur } = get()

			onChange(event)
			onBlur(event, productionId)
		},
		onChangeRole: (e) => {
			const { currentRole, setRoles } = get()
			setRoles(currentRole, {
				[e.target.name]: e.target.value,
			})
		},
		onBlurRole: (e, productionRoleId) => {
			let strategy = ''
			let data = {}
			const { currentRole, roles } = get()
			const role = roles?.[currentRole]
			if (['title', 'character'].includes(e.target.name))
				data = {
					localizations: {
						'en-US': {
							name: role?.title,
							description: role?.character,
							[e.target.name === 'title' ? 'name' : 'description']:
								e.target.value,
						},
					},
				}
			else if (e.target.name === 'instructions') {
				strategy = strategy + '?strategy=0'
				data = {
					labels: {
						Instruction: e.target.value,
					},
				}
			} else
				data = {
					properties: {
						[roleProperties[e.target.name as RolePropertyKey]]: e.target.value,
					},
				}

			if (productionRoleId)
				partialContentUpdate(productionRoleId, data, '', strategy)
		},
		onChangeEventRole: (name, value, productionRoleId) => {
			const event = {
				target: {
					name,
					value,
				},
			} as ChangeEvent<HTMLInputElement>
			const { onChangeRole, onBlurRole } = get()

			onChangeRole(event)
			onBlurRole(event, productionRoleId)
		},
	}),
	Object.is,
)

export default useStore
