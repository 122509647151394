import { useNavigate, useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'react-toastify'
import { sendChatMessage } from 'api/chat'
import { upload } from 'api/aws'
import { createContent } from 'api/content'
import { multiRelationUpdate } from 'api/relations'
import { ArrowLongIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { capitalize, t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useProductionStore, useUserStore } from 'store'
import { PATHS } from 'pages/Router'
import { StepWizardCustom } from 'types/lib'
import { directorCastingSteps } from '../../DirectorCasting'

const StepControls = (): JSX.Element => {
	const navigate = useNavigate()
	const wizard = useAppStore(useShallow((state) => state.stepWizard))
	const { productionId, productionRoleId } = useParams()

	const nextStep = (): void => {
		wizard?.nextStep()
	}

	const prevStep = (): void => {
		wizard?.previousStep()
	}

	const saveData = (): void => {
		wizard?.goToNamedStep(
			directorCastingSteps.myproduction.substeps[0]?.key + '-3',
		)
		const { user } = useUserStore.getState()
		const { setCurrentRole } = useProductionStore.getState()
		setCurrentRole('')
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${
				PATHS.PROJECT
			}/${productionId}`,
		)
		EventBus.$emit('refreshRoles')
	}

	const openMyProductions = (): void => {
		wizard?.goToNamedStep(
			directorCastingSteps.myproduction.substeps[0]?.key + '-0',
		)
		const { user } = useUserStore.getState()
		navigate(`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}`)
	}

	const createProductionRole = async (): Promise<void> => {
		if (productionRoleId) {
			wizard?.nextStep()

			return
		}
		if (!productionId) {
			wizard?.goToStep(3)

			return
		}
		const { setLoading } = useProductionStore.getState()
		try {
			const { roles, currentRole, production, setRoles, setCurrentRole } =
				useProductionStore.getState()
			setLoading(true)
			const { title, shootingDays, type, deadline, character, instructions } =
				roles[currentRole]
			if (!title || !type) toast.info(t('APP_FILL_FIELDS'))
			else {
				const { user } = useUserStore.getState()
				/* create role content */
				const response = await createContent({
					originalTitle: `${user?.ContentTitle} | ${production.name} | ${title}`,
					localizations: {
						'en-US': {
							name: title,
							description: character,
						},
					},
					Type: 'Playlist',
					published: true,
					properties: {
						'Content:ShootingDays': (shootingDays
							? shootingDays
							: 1
						).toString(),
						'Content:Role:Type': type,
						'Content:Event:Deadline': deadline,
					},
					labels: {
						Instruction: instructions,
					},
				})
				if (response.id && productionId) {
					setRoles(currentRole, {
						id: response.id,
					})
					setCurrentRole(response.id)
					/* create relation */
					multiRelationUpdate(productionId, response.id)

					/* create predefined scenes */
					const { production } = useProductionStore.getState()
					if (production.format?.length) {
						const predefFormatDuration: { [key: string]: number } = {
							tape: 60 * 3,
							slate: 60,
							intro: 60,
						}

						production.format.forEach(async (format) => {
							const sceneResponse = await createContent({
								originalTitle: `${user?.ContentTitle} | ${production.name} | ${title} | ${format}`,
								localizations: {
									'en-US': {
										name: format,
									},
								},
								Type: 'Playlist',
								published: true,
								duration: (predefFormatDuration[format] *
									1000) as unknown as string,
								properties: {
									Duration: (predefFormatDuration[format] *
										1000) as unknown as string,
									'Content:Tape:Type': capitalize(format),
								},
							})
							await multiRelationUpdate(response.id, sceneResponse.id)
						})
					}
					await new Promise((resolve) => setTimeout(resolve, 1000))
					EventBus.$emit('refreshScenes')
					navigate(
						`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${
							PATHS.PROJECT
						}/${productionId}/${response.id}`,
					)
					wizard?.nextStep()
				}
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			EventBus.$emit('refreshRoles')
		}
	}

	const createProductionContent = async (): Promise<void> => {
		if (productionId) {
			wizard?.nextStep()

			return
		}

		const { setLoading } = useProductionStore.getState()
		try {
			setLoading(true)
			const { production } = useProductionStore.getState()
			const {
				name,
				description,
				locations,
				nda,
				color,
				productionType,
				ndaFile,
			} = production
			if (!name || !description || !productionType)
				toast.info(t('APP_FILL_THESE_FIELDS') + ' name, description and type')
			else {
				const { user } = useUserStore.getState()

				const response = await createContent({
					originalTitle: `${user?.ContentTitle} | ${name}`,
					originalDescription: description,
					localizations: {
						'en-US': {
							name,
							description,
						},
					},
					Type: 'Collection',
					published: true,
					properties: {
						'Content:Color': color,
						'Content:Legal:NDA:Required': nda.toString(),
						'Content:Production:Type': productionType,
					},
					labels: {
						Place: locations,
					},
				})
				if (response.id) {
					if (nda && ndaFile) {
						const image = await upload({
							fileObj: ndaFile,
							assetType: 'Content',
						})
						const { createAsset } = useProductionStore.getState()

						await createAsset(
							image,
							`NDA - ${ndaFile.name}`,
							image.originalFileName,
							image.fileAssetUrl,
							image.cdnUrl,
							response.id,
						)
					}
					navigate(
						`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${
							PATHS.PROJECT
						}/${response.id}`,
					)
					const { chatId } = useAppStore.getState()
					sendChatMessage(
						chatId,
						`Created "${name}" production`,
						'Production created',
					)
					wizard?.nextStep()
				}
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			EventBus.$emit('refreshMyProduction')
		}
	}

	const hideNextButton =
		[directorCastingSteps.myproduction.key + '-0'].includes(
			(wizard as StepWizardCustom)?.getHash(),
		) ||
		(wizard as StepWizardCustom)
			?.getHash()
			.includes(directorCastingSteps.board.key)

	const hidePrevButton =
		[directorCastingSteps.myproduction.key + '-0'].includes(
			(wizard as StepWizardCustom)?.getHash(),
		) ||
		(wizard as StepWizardCustom)
			?.getHash()
			.includes(directorCastingSteps.board.key)

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '44px',
			}}>
			<Button
				id="production-wizard-back-button"
				onClick={prevStep}
				style={{
					transition: 'var(--transition)',
					opacity: hidePrevButton ? '0' : '1',
					cursor: hidePrevButton ? 'default' : 'pointer',
				}}>
				{t('APP_BACK_BUTTON')}
			</Button>
			<Button
				variant="themed"
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '10px',
					transition: 'var(--transition)',
					opacity: hideNextButton ? '0' : '1',
					cursor: hideNextButton ? 'default' : 'pointer',
				}}
				onClick={() => {
					if (hideNextButton) return

					if (wizard?.currentStep === wizard?.totalSteps) saveData()
					else if (
						(wizard as StepWizardCustom)?.getHash() ===
						directorCastingSteps.myproduction.key + '-1'
					)
						createProductionContent()
					else if (
						(wizard as StepWizardCustom)?.getHash() ===
						directorCastingSteps.myproduction.key + '-3'
					)
						openMyProductions()
					else if (
						(wizard as StepWizardCustom)?.getHash() ===
						directorCastingSteps.myproduction.key + '-4'
					)
						createProductionRole()
					else nextStep()
				}}>
				{t(
					wizard?.currentStep === wizard?.totalSteps ||
						(wizard as StepWizardCustom)?.getHash() ===
							directorCastingSteps.myproduction.key + '-3'
						? 'APP_SAVE'
						: 'APP_NEXT_BUTTON',
				)}
				{(wizard as StepWizardCustom)?.getHash() ===
				directorCastingSteps.myproduction.key + '-3' ? null : (
					<ArrowLongIcon color="var(--theme-primary)" />
				)}
			</Button>
		</div>
	)
}

export default StepControls
