import { ChangeEvent, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { toast } from 'react-toastify'
import { useShallow } from 'zustand/react/shallow'
import { IngestAsset, upload } from 'api/aws'
import { partialContentUpdate } from 'api/content'
import GenerateImage from 'components/GenerateImage'
import Sidebar from 'components/Sidebar'
import FileUpload from 'components/FileUpload'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Colors from '../Colors'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'
import { useProductionStore } from 'store'
import { roleProperties } from '../RoleBasic/RoleBasic'

const tabs = [
	{
		label: 'APP_COLOR_COVER_PROD',
		value: 0,
	},
	{
		label: 'APP_UPLOAD_COVER_PROD',
		value: 1,
	},
	{
		label: 'APP_GENERATE_COVER_PROD',
		value: 2,
	},
]

const RoleCoverSidebar = (): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()
	const [loading, setLoading] = useState(false)
	const [activeTab, setActiveTab] = useState(tabs[0].value)
	const [openSidebar, setOpenSidebar, currentRole, roles, cover] =
		useProductionStore(
			useShallow((state) => [
				state.openImagesSidebar,
				state.setOpenImagesSidebar,
				state.currentRole,
				state.roles,
				state.roles?.[state.currentRole]?.avatar,
			]),
		)

	const role = roles?.[currentRole]

	const { content } = useContent(currentRole)

	const saveImage = async (avatar: File | string): Promise<void> => {
		const { currentRole, setLoading, setRoles, createAsset } =
			useProductionStore.getState()

		setLoading(true)
		try {
			if (typeof avatar !== 'string') {
				const image = await upload({
					fileObj: avatar,
					assetType: 'Content',
				})

				await createAsset(
					image,
					avatar?.name,
					image.originalFileName,
					image.fileAssetUrl,
					image.cdnUrl,
					currentRole,
					'Square',
				)

				setRoles(currentRole, {
					avatar: image.cdnUrl,
				})
			} else {
				const title = content
					? content['Globalizationen-USContentTitle'] + ' | AI Image'
					: 'AI Image'

				await createAsset(
					{} as IngestAsset,
					title,
					title,
					avatar,
					avatar,
					currentRole,
					'Square',
				)

				setRoles(currentRole, {
					avatar,
				})
			}

			toast.info(t('APP_UPLOADED_SUCCESSFULLY'))
		} catch (error) {
			console.log('error:', error)
		} finally {
			setOpenSidebar(false)
			setLoading(false)
		}
	}

	const onChangeColor = (color: string, id: string): void => {
		const { currentRole, setRoles } = useProductionStore.getState()
		setRoles(currentRole, {
			color: color,
		})

		partialContentUpdate(id, {
			color: color,
			properties: {
				[roleProperties['color']]: color,
			},
		})
	}

	const character = `Character: ${
		role?.title || content?.['Globalizationen-USContentTitle'] || ''
	}`
	const description = `Description: ${
		role?.character || content?.['Globalizationen-USContentDescription'] || ''
	}`
	const age = `Age: ${
		role?.age !== undefined ? role?.age : content?.ContentRoleAge || ''
	}`
	const gender = `Gender: ${role?.gender || content?.ContentRoleGender || ''}`

	const predefinedText = content
		? `${character}\r\n${description}\r\n${age}\r\n${gender}`
		: ''

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => {
				setOpenSidebar(false)
			}}
			loading={loading}
			header={{
				title: t('APP_CHARACTER_IMAGES_SIDEBAR_TITLE')?.replace(
					'{Role}',
					role?.title || content?.['Globalizationen-USContentTitle'] || '',
				),
				description: 'APP_CHARACTER_IMAGES_SIDEBAR_DESCRIPTION',
			}}
			button={{
				children: t('APP_DONE'),
				onClick: () => {
					if (cover) {
						saveImage(cover)
					} else {
						setOpenSidebar(false)
					}
				},
			}}>
			<>
				<SidebarTabs
					active={activeTab}
					options={tabs}
					onChange={(v) => {
						setActiveTab(v as number)
						swiperRef.current?.slideTo(v as number)
					}}
				/>
				<Swiper
					allowTouchMove={false}
					onSwiper={(swiper: SwiperClass) => {
						swiperRef.current = swiper
					}}
					spaceBetween={10}
					style={{ width: '100%' }}>
					<SwiperSlide>
						<Colors onChange={onChangeColor} type="role" />
					</SwiperSlide>
					<SwiperSlide>
						<FileUpload
							fileType="Image"
							label="APP_ROLE_IMAGES_LABEL"
							form="square"
							direction="vertical"
							itemTitle=""
							hintText="APP_ROLE_IMAGE_HINT"
							name="role_image"
							formatsText="APP_PHOTO_FORMATS"
							qualityText="APP_PICTURES_QUALITY"
							fileInputProps={{
								accept: 'image/*',
								id: 'role_image',
							}}
							value={cover}
							onChange={(e) => {
								if (e.target.files && e.target.files[0]) {
									const { onChangeRole } = useProductionStore.getState()
									onChangeRole({
										target: {
											name: 'avatar',
											value: e.target.files[0] as unknown as string,
										},
									} as ChangeEvent<HTMLInputElement>)
								}
							}}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<GenerateImage
							setLoading={setLoading}
							predefinedText={predefinedText}
							onChange={(url) => {
								const { onChangeRole } = useProductionStore.getState()
								onChangeRole({
									target: {
										name: 'avatar',
										value: url,
									},
								} as ChangeEvent<HTMLInputElement>)
								setActiveTab(1)
								swiperRef.current?.slideTo(1)
							}}
							inputType="textarea"
						/>
					</SwiperSlide>
				</Swiper>
			</>
		</Sidebar>
	)
}

export default RoleCoverSidebar
