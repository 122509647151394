import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { contentAuthorize } from 'api/auth'
import { propertiesParser, actionsParser } from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import { ContentCollection, Content, Endpoints, FetchReturn } from 'types/app'
import { ContentAuthorizeType } from 'types/content'

type ReturnData = FetchReturn & {
	videoData: ContentAuthorizeType | undefined
	content: Content | undefined
}

export default (step: string): ReturnData => {
	const { endpoints } = useAppStore.getState()

	let tutorialUrl =
		endpoints?.[('PortfolioStep' + step) as keyof Endpoints] ||
		endpoints?.['PortfolioStepBasic-1']

	if (step === 'Basic-0') {
		const pathes = tutorialUrl?.split('content')
		tutorialUrl =
			pathes?.[0] +
			'content/' +
			'3b6afbee-e09e-48d1-96fe-5aa0263d63c6' +
			'/entity.json'
	}

	const { data, error, isLoading } = useSwrImmutable<ContentCollection>(
		tutorialUrl,
		fetcher,
	)

	const content =
		data &&
		data.Properties &&
		({
			...propertiesParser(data.Properties),
			Actions: actionsParser(data.Actions),
		} as Content)

	const { data: videoData, isLoading: dataLoading } =
		useSwrImmutable<ContentAuthorizeType>(
			content ? content : null,
			contentAuthorizeFetcher,
		)

	return {
		isLoading: isLoading || dataLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: videoData?.error
				? videoData?.error
				: 'something went wrong'
			: undefined,
		content,
		videoData,
	}
}

const contentAuthorizeFetcher = async <T>(content: Content): Promise<T> => {
	const response = await contentAuthorize(
		content.ContentId,
		content.ContentSubType,
	)

	return propertiesParser(response.Properties) as T
}
