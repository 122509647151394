import ReactCountryFlag from 'react-country-flag'

interface Props {
	code: string | undefined
}

const CountryFlag = ({ code }: Props): JSX.Element =>
	code ? (
		<div
			style={{
				position: 'relative',
				overflow: 'hidden',
				maxWidth: '20px',
				maxHeight: '20px',
				minWidth: '20px',
				minHeight: '20px',
				width: '20px',
				height: '20px',
				borderRadius: '50%',
			}}>
			<ReactCountryFlag
				countryCode={code?.toUpperCase()}
				svg
				style={{
					fontSize: '30px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			/>
		</div>
	) : (
		<></>
	)

export default CountryFlag
