import { useShallow } from 'zustand/react/shallow'
import { useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { partialContentUpdate } from 'api/content'
import { CompleteIcon, PlusIcon } from 'assets/icons'
import Sidebar from 'components/Sidebar'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { capitalize, t } from 'helpers'
import { usePortfolioStore } from 'store'
import { Content } from 'types/app'
import { PORTFOLIO_TYPES, RELATIONS } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	picturesFields,
	showreelsFields,
} from 'pages/Portfolio/fields'
import s from './index.module.scss'

const maxItems = 16

const GallerySidebar = (): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(0)
	const [galleryType, setGalleryType, generalType, modalType, galleryValues] =
		usePortfolioStore(
			useShallow((state) => [
				state.galleryType,
				state.setGalleryType,
				state.generalModalType,
				state.modalType,
				state.galleryValues,
			]),
		)

	const selectedMedia = useMemo(
		() =>
			galleryValues && galleryType
				? galleryValues[`${capitalize(modalType)}:${galleryType?.value}`]
				: [],
		[galleryType, galleryValues, modalType],
	)

	const { data, refresh } = useRelationsContent(
		galleryType ? [RELATIONS.IMAGE, RELATIONS.BEST_PERFORMANCES] : null,
		undefined,
		undefined,
		'',
		false,
		true,
	)

	const images = data?.[RELATIONS.IMAGE] || []

	const videos = data?.[RELATIONS.BEST_PERFORMANCES] || []

	const createMediaList = (items: Content[]): Content[] =>
		Array.from({ length: maxItems })?.map(
			(_, index) =>
				items?.find((item) => item.relationIndex === index) || undefined,
		) as Content[]

	const selectMedia = async (id: string, strategy: number): Promise<void> => {
		if (strategy !== 2) {
			const { setGalleryValues } = usePortfolioStore.getState()
			setGalleryValues(`${capitalize(modalType)}:${galleryType?.value}`, id)
		} else {
			refresh()
		}

		await partialContentUpdate(
			id,
			{
				labels: {
					[capitalize(generalType)]: [
						`${capitalize(modalType)}:${galleryType?.value}`,
					],
				},
			},
			`?strategy=${strategy}`,
		)
	}

	const close = (): void => {
		setGalleryType(null)
		setActiveTab(0)
	}

	return (
		<Sidebar
			open={!!galleryType}
			onClose={close}
			iconClose
			header={{
				title: t('APP_ASSIGN_MEDIA_TITLE'),
			}}
			additional>
			<>
				<SidebarTabs
					active={activeTab}
					onChange={(v) => {
						setActiveTab(v as number)
						swiperRef.current?.slideTo(v as number)
					}}
					options={[
						{ label: 'APP_PROFILE_MEDIA', value: 0 },
						{
							label: 'APP_PROFILE_SHOWREELS',
							value: 1,
						},
					]}
				/>
				<Swiper
					onSwiper={(swiper: SwiperClass) => {
						swiperRef.current = swiper
					}}
					allowTouchMove={false}
					style={{ width: '100%' }}>
					<SwiperSlide>
						<div className={s.list}>
							{createMediaList(images)?.map((data, index) => (
								<GalleryItem
									data={data}
									onClick={selectMedia}
									selectedMedia={selectedMedia}
									key={data ? data?.ContentId : index}
									activeTab={activeTab}
									index={index}
								/>
							))}
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className={s.list}>
							{createMediaList(videos)?.map((data, index) => (
								<GalleryItem
									data={data}
									onClick={selectMedia}
									selectedMedia={selectedMedia}
									key={data ? data?.ContentId : index}
									activeTab={activeTab}
									index={index}
								/>
							))}
						</div>
					</SwiperSlide>
				</Swiper>
			</>
		</Sidebar>
	)
}

export default GallerySidebar

const GalleryItem = ({
	data,
	selectedMedia,
	onClick,
	activeTab,
	index,
}: {
	data: Content
	selectedMedia: string[]
	onClick: (id: string, strategy: number) => void
	activeTab: number
	index: number
}): JSX.Element => {
	const [galleryType, generalType, modalType, cardValues] = usePortfolioStore(
		useShallow((state) => [
			state.galleryType,
			state.generalModalType,
			state.modalType,
			state.cardValues,
		]),
	)

	const localName =
		(activeTab === 0 ? PORTFOLIO_TYPES.PICTURE : PORTFOLIO_TYPES.SHOWREEL) +
		index

	const localValue = cardValues?.[localName]

	const selected =
		selectedMedia?.includes(data?.ContentId) ||
		localValue?.selected ||
		(data && 'ContentLabel' + capitalize(generalType) in data
			? (data[
					('ContentLabel' + capitalize(generalType)) as keyof Content
			  ] as string)
			: ''
		)?.includes(`${capitalize(modalType)}:${galleryType?.value}`)

	const bodyTypes = [
		attributeBasicFields.body.value,
		attributeSpecialFields.bodyDone.value,
		attributeSpecialFields.bodyWilling.value,
	].includes(modalType)

	const fullShape = data?.ContentLabelType === 'FULLSHAPE'

	return (
		<div
			className={cn({
				[s.active]: selected,
				[s.disabled]: data && bodyTypes && !fullShape,
			})}
			onClick={() => {
				// if (bodyTypes && !fullShape) return

				let strategy = 1
				if (
					(data && 'ContentLabel' + capitalize(generalType) in data
						? (data[
								('ContentLabel' + capitalize(generalType)) as keyof Content
						  ] as string)
						: ''
					)?.includes(`${capitalize(modalType)}:${galleryType?.value}`) ||
					selectedMedia?.includes(data?.ContentId)
				) {
					strategy = 2
				}

				if (data?.ContentId) onClick(data?.ContentId, strategy)
				else if (localValue?.contentId) {
					onClick(localValue.contentId as string, strategy)
					const { setCardValues } = usePortfolioStore.getState()
					setCardValues(
						{
							selected: true,
						},
						localName,
					)
				} else {
					const {
						setModalType,
						setGeneralModalType,
						setCardData,
						setGalleryType,
						cardData,
						setPrevSidebarData,
					} = usePortfolioStore.getState()
					setPrevSidebarData({
						generalType,
						cardData,
						modalType,
						galleryType,
					})
					setGalleryType(null)
					setGeneralModalType(
						activeTab === 0
							? PORTFOLIO_TYPES.PICTURE
							: PORTFOLIO_TYPES.SHOWREEL,
					)
					setModalType(localName)
					setCardData(
						activeTab === 0 ? picturesFields[index] : showreelsFields[index],
					)
				}
			}}>
			{selected ? (
				<div
					style={{
						display: 'flex',
						position: 'absolute',
						top: '5px',
						left: '5px',
					}}>
					<CompleteIcon />
				</div>
			) : null}
			{localValue?.[localName] instanceof File ? (
				<img src={URL.createObjectURL(localValue[localName] as File)} />
			) : data ? (
				<>
					<img src={data?.ContentSquareImageUrl || '/placeholder.png'} />
					<span className="body2-m">{data?.ContentTitle}</span>
				</>
			) : (
				<div
					className="card addItemCard"
					style={{
						height: '100%',
					}}>
					<PlusIcon color="var(--theme-primary)" />
					<span className="body1-m" style={{ color: 'var(--theme-primary)' }}>
						{t(activeTab === 0 ? 'APP_ADD_PICTURE' : 'APP_ADD_REEL')}
					</span>
				</div>
			)}
		</div>
	)
}
