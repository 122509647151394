import { CSSProperties } from 'react'
import cn from 'classnames'
import dayjs from 'dayjs'
import { FlagImage } from 'react-international-phone'
import {
	FemaleGenderIcon,
	MaleGenderIcon,
	OtherGenderIcon,
	PendingStatusIcon,
	VerifiedStatusIcon,
} from 'assets/icons'
import useUser from 'hooks/api/useUser'
import { useAppStore } from 'store'
import { optionsParser, t } from 'helpers'
import { basicFields, contactFields } from 'pages/Portfolio/fields'
import { User } from 'types/user'
import s from './index.module.scss'

interface Props {
	data: User
	variant?: 'full' | 'small'
	showContacts?: boolean
	onClick?: () => void
	style?: CSSProperties
}

const NetworkCard = ({
	data,
	variant = 'full',
	showContacts = true,
	onClick,
	style,
}: Props): JSX.Element => {
	const { countries } = useAppStore.getState()
	const agencyProperty = contactFields.agency.name

	const agencyId = data?.[
		agencyProperty?.replaceAll(':', '') as keyof User
	] as string

	const { user: agency } = useUser(agencyId)

	const phone = (
		data?.CustomerUserPhoneNumber?.toString()?.startsWith('+')
			? data?.CustomerUserPhoneNumber?.toString()?.slice(1)
			: data?.CustomerUserPhoneNumber?.toString()
	)
		?.replaceAll(' ', '')
		?.replace('(', '')
		?.replace(')', '')

	const iso2 = data.CustomerLocationCountryCode
		? countries?.find(
				(country) =>
					data.CustomerLocationCountryCode?.slice(-2)?.toLowerCase() ===
					country.iso2,
		  )?.iso2
		: null

	const profession = optionsParser(
		t(basicFields?.profession?.optionName || ''),
	)?.find((v) => v.value === data?.CustomerProfession)?.label

	const status = data.CustomerProcessStatus

	const infoValues = [
		profession,
		(data?.CustomUserBirthDate &&
			dayjs().diff(dayjs(data.CustomUserBirthDate), 'year')) ||
			data?.CustomerAge,
	]
		?.filter((v) => !!v)
		?.join(', ')

	const email = agency ? agency?.CustomUserEmail : data?.CustomUserEmail

	return (
		<div
			className={cn(s.container, s[variant])}
			onClick={onClick}
			style={style}>
			<div
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					opacity: '0.08',
					top: 0,
					left: 0,
					background:
						'linear-gradient(0deg, rgb(108, 108, 112), rgb(108, 108, 112))',
				}}
			/>
			<div className={s.avatarWrapper}>
				<div className={s.status}>
					{status === 'Invited' ? (
						<PendingStatusIcon />
					) : status === 'Registered' ? (
						<VerifiedStatusIcon />
					) : status === 'Validated' ? (
						<VerifiedStatusIcon mainColor="var(--ice-blue)" />
					) : status === 'Selected' ? (
						<VerifiedStatusIcon mainColor="var(--agency)" />
					) : null}
				</div>
				<div
					className={s.avatar}
					style={{
						borderColor: `var(--${data?.CustomerRole?.toLowerCase()})`,
					}}>
					<img
						src={data?.CustomerImageSquareUrl || '/placeholder.png'}
						alt={data?.ContentTitle}
					/>
				</div>
			</div>
			<div className={s.infoWrapper}>
				<div className={s.name}>
					<span className="body1-b textEllipsis">{data?.ContentTitle}</span>
					<div style={{ display: 'flex' }}>
						{!data?.CustomUserGender ? null : data?.CustomUserGender ===
						  'MALE' ? (
							<MaleGenderIcon />
						) : data?.CustomUserGender === 'FEMALE' ? (
							<FemaleGenderIcon />
						) : (
							<OtherGenderIcon />
						)}
					</div>
				</div>
				{infoValues || iso2 ? (
					<div className={s.info}>
						<div className={s.flag}>
							{iso2 ? (
								<FlagImage iso2={iso2} />
							) : (
								<img src="/placeholder.png" alt="country flag" />
							)}
						</div>
						<span className="body2-m" style={{ color: 'var(--mono300)' }}>
							{infoValues}
						</span>
					</div>
				) : null}
			</div>
			{showContacts ? (
				<div className={s.contactsWrapper}>
					{variant !== 'small' ? (
						<>
							<a
								href={`tel:+${phone}`}
								className={cn('body2-m', 'textEllipsis', s.contacts)}
								style={{ opacity: phone ? '1' : '0' }}>
								{phone
									? '+' +
									  phone?.slice(0, 2) +
									  ' ' +
									  phone?.slice(2, 5) +
									  ' ' +
									  phone?.slice(5, 7) +
									  ' ' +
									  phone?.slice(7, 9) +
									  ' ' +
									  phone?.slice(9)
									: ''}
							</a>

							<a
								href={`mailto:${email}`}
								className={cn('body2-m', 'textEllipsis', s.contacts)}>
								{email}
							</a>
						</>
					) : null}
				</div>
			) : null}
		</div>
	)
}

export default NetworkCard
