/* eslint-disable no-tabs */
import StepWizard, { StepWizardProps } from 'react-step-wizard'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import Basic from '../../steps/Basic'
import Contact from '../../steps/Contact'
import Personal from '../../steps/Personal'
import Attributes from '../../steps/Attributes'
import Skills from '../../steps/Skills'
import Pictures from '../../steps/Pictures'
import Photos from '../../steps/Photos'
import Showreels from '../../steps/Showreels'
import Summary from '../../steps/Summary'
import StepWrapper from 'components/Wizard/StepWrapper'
import { useAppStore, usePortfolioStore } from 'store'
import s from './index.module.scss'
import GallerySidebar from '../GallerySidebar/GallerySidebar'
import AttributeSidebar from '../AttributeSidebar'
import AdditionalSidebar from '../AdditionalSidebar'
import Biography from 'pages/Portfolio/steps/Biography'
import Card from 'components/Wizard/Card'
import AgencySidebar from '../AgencySidebar'

export const profileSteps = {
	basic: {
		name: 'APP_PROFILE_BASIC',
		key: 'basic',
		main: true,
		substeps: [
			{
				name: 'APP_PROFILE_PHOTOS',
				key: 'basic',
				component: Photos,
				index: 0,
			},
			{
				name: 'APP_PROFILE_BASIC',
				key: 'basic',
				component: Basic,
				index: 1,
			},
			{
				name: 'APP_PROFILE_CONTACT',
				key: 'basic',
				component: Contact,
				index: 2,
			},
			{
				name: 'APP_PROFILE_PERSONAL',
				key: 'basic',
				component: Personal,
				index: 3,
			},
			{
				name: 'APP_PROFILE_BIOGRAPHY',
				key: 'basic',
				component: Biography,
				index: 4,
			},
		],
	},

	pictures: {
		name: 'APP_PROFILE_MEDIA',
		key: 'pictures',
		main: true,
		substeps: [
			{
				name: '1 - 4',
				key: 'pictures',
				component: Pictures,
				index: 0,
			},
			{
				name: '5 - 8',
				key: 'pictures',
				component: Pictures,
				index: 1,
			},
			{
				name: '9 - 12',
				key: 'pictures',
				component: Pictures,
				index: 2,
			},
			{
				name: '13 - 16',
				key: 'pictures',
				component: Pictures,
				index: 3,
			},
		],
	},
	showreels: {
		name: 'APP_PROFILE_SHOWREELS',
		key: 'showreels',
		main: true,
		substeps: [
			{
				name: '1 - 4',
				key: 'showreels',
				component: Showreels,
				index: 0,
			},
			{
				name: '5 - 8',
				key: 'showreels',
				component: Showreels,
				index: 1,
			},
			{
				name: '9 - 12',
				key: 'showreels',
				component: Showreels,
				index: 2,
			},
			{
				name: '13 - 16',
				key: 'showreels',
				component: Showreels,
				index: 3,
			},
		],
	},
	attributes: {
		name: 'APP_PROFILE_ATTRIBUTES',
		key: 'attributes',
		main: true,
		substeps: [
			{
				name: 'APP_PROFILE_ATTRIBUTES',
				key: 'attributes',
				component: Attributes,
				index: 0,
			},
			{
				name: 'APP_PROFILE_SKILLS',
				key: 'attributes',
				component: Skills,
				index: 1,
			},
		],
	},
	summary: {
		name: 'APP_PROFILE_SUMMARY',
		key: 'summary',
		main: true,
		substeps: [
			{
				name: 'APP_PROFILE_SUMMARY',
				key: 'summary',
				component: Summary,
				index: 0,
			},
		],
	},
	// {
	// 	name: 'APP_PROFILE_CAREER',
	// 	key: 'career',
	// 	main: true,
	// 	substeps: [
	// 		{
	// 			name: 'APP_PROFILE_EDUCATION',
	// 			key: 'career',
	// 			component: Education,
	// 			index: 0,
	// 		},
	// 		{
	// 			name: 'APP_PROFILE_AWARDS',
	// 			key: 'career',
	// 			component: Awards,
	// 			index: 1,
	// 		},
	// 		{
	// 			name: 'APP_PROFILE_EXPERIENCE',
	// 			key: 'career',
	// 			component: Experience,
	// 			index: 2,
	// 		},
	// 	],
	// },
}

interface Props {
	assignStepWizard: (instance: StepWizardProps) => void
}

export interface StepContentProps {
	next?: () => void
}

const StepContent = ({ assignStepWizard }: Props): JSX.Element => {
	const [currentCard] = usePortfolioStore(
		useShallow((state) => [state.cardData]),
	)
	const wizard = useAppStore(useShallow((state) => state.stepWizard))
	const lastStep = wizard?.currentStep === wizard?.totalSteps

	return (
		<>
			<GallerySidebar />
			<AttributeSidebar cardData={currentCard} />
			<AdditionalSidebar />
			<AgencySidebar />
			<Card className={cn(s.wizard, { [s.lastStep]: lastStep })}>
				<StepWizard
					initialStep={0}
					instance={assignStepWizard}
					isHashEnabled={true}>
					{Object.values(profileSteps)
						?.map((step) => step.substeps)
						?.flat()
						?.map((step) => (
							<StepWrapper
								key={step.name + '_' + step.index + '-' + step.key}
								hashKey={step.key + '-' + step.index}
								stepName={step.key + '-' + step.index}>
								<step.component index={step.index} />
							</StepWrapper>
						))}
				</StepWizard>
			</Card>
		</>
	)
}

export default StepContent
