import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import Sidebar from 'components/Sidebar'
import { t } from 'helpers'
import { usePortfolioStore } from 'store'
import { ValueLevel } from 'types/app'
import s from './index.module.scss'

const AdditionalSidebar = (): JSX.Element => {
	const [sidebar, setSidebar] = usePortfolioStore(
		useShallow((state) => [
			state.additionalSidebar,
			state.setAdditionalSidebar,
		]),
	)

	const onChange = (
		type: 'Level' | 'Dialect',
		mainValue: string,
		value: string,
	): void => {
		const { modalType, updateFields, setCardValues, cardValues } =
			usePortfolioStore.getState()
		const fieldName = sidebar?.selectorName || ''

		const prev = (cardValues[modalType][fieldName] ||
			[]) as unknown as ValueLevel[]
		const current =
			prev?.find((value) => mainValue === value?.Value) || ({} as ValueLevel)
		const newValue: ValueLevel = {
			...current,
			[type]: value,
		}

		const newValues = [
			...prev.filter((v) => v?.Value !== mainValue),
			newValue,
		]?.filter((v) => !!v && (!!v?.Level || !!v?.Value))

		updateFields([
			{
				Name: fieldName,
				Value: JSON.stringify(newValues),
			},
		])

		setCardValues({
			[fieldName]: newValues,
		})

		setSidebar(null)
	}

	return (
		<Sidebar
			open={!!sidebar}
			onClose={() => setSidebar(null)}
			additional
			iconClose
			style={{
				gap: '12px',
			}}
			header={{
				title:
					sidebar?.valueOption?.label +
					' ' +
					t(sidebar?.type === 'level' ? 'APP_LEVEL_LABEL' : 'APP_ACCENT_LABEL'),
			}}>
			<>
				{sidebar?.options?.map((option, index) => (
					<div
						key={option.value}
						className={cn('br', s.wrapper)}
						onClick={() =>
							onChange(
								sidebar?.type === 'level' ? 'Level' : 'Dialect',
								sidebar.valueOption.value,
								option.value,
							)
						}>
						<div className={s.titles}>
							<span className="body1-b">{option.label}</span>
							{option.hint ? (
								<span className="body1-r" style={{ color: 'var(--mono200)' }}>
									{option.hint}
								</span>
							) : null}
						</div>
						{sidebar?.type === 'level' ? (
							<LevelGraph current={index} length={sidebar?.options?.length} />
						) : null}
					</div>
				))}
			</>
		</Sidebar>
	)
}

export default AdditionalSidebar

const LevelGraph = ({
	current,
	length,
}: {
	current: number
	length: number
}): JSX.Element => (
	<div className={s.levelWrapper}>
		<div className={s.track} />
		<div
			className={s.line}
			style={{
				left: `calc(${((length - current) * 100) / length}% - 4px)`,
			}}
		/>
	</div>
)
