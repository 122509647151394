import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Loader from 'components/Loader'
import Card from 'components/Wizard/Card'
import Header from './components/Header'
import List from './components/List'
import InvitesToPoolCarousel from './components/InvitesToPoolCarousel'
import AgencyCarousel from './components/AgencyCarousel'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { ageFilterParser } from 'helpers/propertiesParser'
import { useFilterStore, useNetworkStore } from 'store'
import { FILTER_CONDITION, SORT_FORMAT } from 'types/enums'
import { CUSTOMER_ROLE, User } from 'types/user'
import { directorNetworkSteps } from '../../CastingDirector/DirectorNetwork'
import { actorNetworkSteps } from 'pages/Network/Actor/ActorNetwork'

interface Props {
	source: string
}

const MyNetwork = ({ source }: Props): JSX.Element => {
	const { hash } = useLocation()
	const { profileId } = useParams()
	const navigate = useNavigate()
	const [activeLetter, setActiveLetter] = useState('A')
	const [agencyId, setAgencyId] = useState('')
	const filters = useFilterStore(useShallow((state) => state.filters))

	const ageFilter = useMemo(
		() =>
			ageFilterParser([
				{
					propName: 'Customer:Age',
					propValue: filters?.['Customer:Age'] as string,
				},
			]),
		[filters],
	)
	const imdbFilter = useMemo(
		() => [
			{
				propName: 'Customer:ImdbUrl',
				propValue:
					Array.isArray(filters?.imdbLink) && filters?.imdbLink?.length
						? 'imdb'
						: '',
				cond: FILTER_CONDITION.EXISTS,
				format: SORT_FORMAT.TEXT,
			},
		],
		[filters?.imdbLink],
	)

	const userStatus =
		(Object.entries(filters).find(
			([key]) => key === 'userStatus',
		)?.[1] as string) || ''
	const userSearch =
		(Object.entries(filters).find(
			([key]) => key === 'userSearch',
		)?.[1] as string) || ''

	const { data, isLoading } = useCustomerSearch({
		fetch: true,
		filters: [
			...Object.entries(filters)
				.filter(
					([key]) =>
						!key.endsWith('Age') && !['userStatus', 'userSearch'].includes(key),
				)
				.filter(([_, value]) => !!value)
				.map(([name, value]) => {
					return {
						propName: name,
						propValue: value
							? typeof value === 'object'
								? JSON.stringify(value)
								: value?.toString()
							: '',
						format: JSON.stringify(value)?.startsWith('[{')
							? SORT_FORMAT.OBJECT
							: JSON.stringify(value)?.startsWith('[')
							? SORT_FORMAT.LIST
							: SORT_FORMAT.TEXT,
					}
				}),
			...ageFilter,
			...imdbFilter,
			{
				propName: 'Customer:Process:Status',
				propValue: userStatus ? 'Invited' : '',
				cond:
					userStatus === 'Active'
						? FILTER_CONDITION.NOT_EQUAL
						: FILTER_CONDITION.EQUAL,
			},
		],
		searchText: userSearch,
		organizationId: hash?.includes(directorNetworkSteps.opennetwork.key)
			? '35ab2507-a3a1-4f9c-ae6d-4ef865655571'
			: agencyId || undefined,
	})

	const letters = Array.from({ length: 26 }, (_, i) =>
		String.fromCharCode(65 + i),
	)

	const usersList = letters
		?.map((letter) => ({
			key: letter,
			data: data?.filter((user) =>
				user?.ContentTitle?.toLowerCase()?.startsWith(letter?.toLowerCase()),
			),
		}))
		?.filter((v) => v?.data?.length)

	const onUserChange = useCallback(
		async (u: User | null): Promise<void> => {
			const { userProfile } = useNetworkStore.getState()
			const users = usersList?.map((l) => l.data).flat() as User[]
			if (users?.length) {
				const { setUserProfile } = useNetworkStore.getState()
				setUserProfile({
					users,
					listSource: Object.values(filters)?.length
						? JSON.stringify(filters)
						: undefined,
				})
			}
			const path = await userProfile.onUserChange(u)
			navigate(path)
		},
		[navigate, usersList, filters],
	)

	useEffect(() => {
		const { setOpenFilterSidebar } = useFilterStore.getState()
		if (hash?.includes('my-0') && !profileId) {
			setOpenFilterSidebar(true)
		} else setOpenFilterSidebar(false)
	}, [hash, profileId])

	useEffect(() => {
		return () => {
			const { setOpenFilterSidebar } = useFilterStore.getState()
			setOpenFilterSidebar(false)
		}
	}, [])

	const [key, role] = source.split('-')

	return profileId ? (
		<></>
	) : (
		<Card
			style={{
				overflow: 'hidden',
			}}
			wrapperStyle={{
				overflow: 'unset',
			}}>
			<>
				<Loader loading={isLoading} visible />
				<Header itemsCount={data?.length || 0} />
				{hash?.includes(actorNetworkSteps?.opennetwork?.key) &&
				window.location?.pathname.includes(CUSTOMER_ROLE.ACTOR) ? (
					<InvitesToPoolCarousel />
				) : null}
				{hash?.includes(directorNetworkSteps.mynetwork.key) ? (
					<AgencyCarousel agencyId={agencyId} setAgencyId={setAgencyId} />
				) : null}
				{hash?.slice(1)?.startsWith(key) &&
				window.location.pathname.includes(role) ? (
					<List
						users={usersList}
						setUser={onUserChange}
						letters={letters}
						activeLetter={activeLetter}
						setActiveLetter={setActiveLetter}
					/>
				) : null}
			</>
		</Card>
	)
}

export default MyNetwork
