import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'

type Return = {
	isLoading: boolean
	image: Blob | undefined
}

export default (link: string): Return => {
	const url =
		'https://ygv.theatroo.ent360.blue/api/v1.0/Render/QR/' +
		encodeURIComponent('https://showrealz.com' + link)

	const { data, isLoading } = useSwrImmutable<Blob>(link ? url : null, fetcher)

	return {
		image: data?.type ? data : undefined,
		isLoading,
	}
}
