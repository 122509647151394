import { useState } from 'react'
import Loader from 'components/Loader'
import RoleCard from 'components/RoleCard'
import useCollection from 'hooks/api/useCollection'
import useContentSearch from 'hooks/api/useContentSearch'
import { useFilterStore } from 'store'
import { Content, SelectOption } from 'types/app'
import { roleProperties } from 'pages/Casting/CastingDirector/components/RoleBasic/RoleBasic'
import { personalFields, skillFields } from 'pages/Portfolio/fields'
import Select from 'components/UI/Select'

interface Props {
	searchFilter: string
}

const CastingFilter = ({ searchFilter }: Props): JSX.Element => {
	const { contents, isLoading } = useContentSearch(true, 'Collection')
	const [prodSearch, setProdSearch] = useState('')
	const [activeRole, setActiveRole] = useState('')

	const productions: SelectOption[] = contents
		? contents?.map((content) => ({
				value: content.ContentId,
				label: content['Globalizationen-USContentTitle'],
		  }))
		: []

	return (
		<>
			<Loader loading={isLoading} adapt />
			<Select
				name="productions"
				options={productions}
				error=""
				label="APP_PRODUCTION_SELECT_LABEL"
				onChange={(e) => {
					setProdSearch(e.target.value)
				}}
				inputProps={{
					value: prodSearch,
				}}
			/>
			{contents
				?.filter((content) =>
					prodSearch ? content?.ContentId === prodSearch : content,
				)
				?.map((content) => (
					<CastingRole
						key={content?.ContentId}
						production={content}
						activeRole={activeRole}
						setActiveRole={setActiveRole}
						searchFilter={searchFilter}
					/>
				))}
		</>
	)
}

export default CastingFilter

const CastingRole = ({
	production,
	activeRole,
	setActiveRole,
	searchFilter,
}: {
	production: Content
	activeRole: string
	setActiveRole: (id: string) => void
	searchFilter: string
}): JSX.Element => {
	const { collection } = useCollection(production)

	const roleParametersFilter = (content: Content): void => {
		const { setFilters } = useFilterStore.getState()
		const { gender, language } = roleProperties

		const filters = {
			[personalFields.gender.name]:
				(content[gender?.replaceAll(':', '') as keyof Content] as string) || '',
			[skillFields.spokenLanguage.selectors[0].name]: [
				{
					Value:
						(content[
							language?.replaceAll(':', '') as keyof Content
						] as string) || '',
				},
			],
		}

		setFilters(filters)
		setActiveRole(content.ContentId)
	}

	return (
		<>
			{collection
				?.filter((content) =>
					content?.['Globalizationen-USContentTitle']
						?.toLowerCase()
						.includes(searchFilter?.toLowerCase()),
				)
				?.map((content) => (
					<div
						key={content?.ContentId}
						style={{
							transition: 'var(--transition)',
							borderRadius: '25px',
							border: `1px solid ${
								activeRole === content.ContentId
									? 'var(--mono300)'
									: 'transparent'
							}`,
						}}>
						<RoleCard
							content={content}
							showProd
							type="horizontal"
							onClick={() => roleParametersFilter(content)}
						/>
					</div>
				))}
		</>
	)
}
