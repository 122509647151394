import { useShallow } from 'zustand/react/shallow'
import dayjs from 'dayjs'
import Sidebar from 'components/Sidebar'
import useContent from 'hooks/api/useContent'
import { optionsParser, t } from 'helpers'
import { useAppStore } from 'store'
import { DROPDOWN_OPTIONS } from 'types/enums'
import s from './index.module.scss'

const ProductionInfoSidebar = (): JSX.Element => {
	const [prodId, setOpenSidebar] = useAppStore(
		useShallow((state) => [state.prodInfoSidebar, state.setProdInfoSidebar]),
	)
	const { content } = useContent(prodId)

	const prodTypes = optionsParser(DROPDOWN_OPTIONS.PRODUCTION_TYPES)

	const rehearsalDate = content?.ContentEventRehearsalDate?.includes('[')
		? (JSON.parse(content?.ContentEventRehearsalDate) as string[])
		: ''
	const filmingDate = content?.ContentEventFilmingDate?.includes('[')
		? (JSON.parse(content?.ContentEventFilmingDate) as string[])
		: ''

	return (
		<Sidebar
			open={!!prodId}
			onClose={() => setOpenSidebar('')}
			header={{
				title: content?.['Globalizationen-USContentTitle'] || '',
				description: content?.['Globalizationen-USContentDescription'],
			}}>
			<>
				<div className={s.wrapper}>
					{content?.ContentProductionType ? (
						<div className={s.item}>
							<span className="body1-b">{t('APP_PRODUCTION_TYPE_LABEL')}</span>
							<span className="body2-s">
								{
									prodTypes.find(
										(option) => option.value === content?.ContentProductionType,
									)?.label
								}
							</span>
						</div>
					) : null}
					{content?.ContentLegalNDARequired === 'true' ? (
						<div className={s.item}>
							<span className="body1-b">{`${t('APP_NDA_INPUT_LABEL')}`}</span>
						</div>
					) : null}
					{content?.ContentLabelPlace ? (
						<div className={s.item}>
							<span className="body1-b">{t('APP_LOCATION_LABEL')}</span>
							<span className="body2-s">{content?.ContentLabelPlace}</span>
						</div>
					) : null}
					{content?.ContentLabelPlace ? (
						<div className={s.item}>
							<span className="body1-b">{t('APP_DIRECTOR_NAME_LABEL')}</span>
							<span className="body2-s">{content?.ContentDirectorName}</span>
						</div>
					) : null}
					{content?.ContentImdbUrl ? (
						<div className={s.item}>
							<span className="body1-b">{t('APP_IMDBLINK_LABEL')}</span>
							<span className="body2-s">{content?.ContentImdbUrl}</span>
						</div>
					) : null}

					{content?.ContentEventRehearsalDate ? (
						<div className={s.item}>
							<span className="body1-b">{t('APP_REHEARSAL_DATE_LABEL')}</span>
							<span className="body2-s">
								{dayjs(rehearsalDate?.[0]).format('MM/DD/YYYY') +
									' - ' +
									dayjs(rehearsalDate?.[1]).format('MM/DD/YYYY')}
							</span>
						</div>
					) : null}
					{content?.ContentEventFilmingDate ? (
						<div className={s.item}>
							<span className="body1-b">{t('APP_FILMING_DATE_LABEL')}</span>
							<span className="body2-s">
								{dayjs(filmingDate?.[0]).format('MM/DD/YYYY') +
									' - ' +
									dayjs(filmingDate?.[1]).format('MM/DD/YYYY')}
							</span>
						</div>
					) : null}
				</div>
			</>
		</Sidebar>
	)
}

export default ProductionInfoSidebar
