import { useEffect } from 'react'
import { SwiperSlide } from 'swiper/react'
import NetworkCard from 'components/NetworkCard'
import RecentCarousel from 'components/RecentCarousel'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { useNetworkStore } from 'store'
import { RELATIONS } from 'types/enums'
import { User } from 'types/user'

const InvitesToPoolCarousel = (): JSX.Element => {
	const { data, refresh } = useRelationsContent([RELATIONS.GROUP_INVITE])
	const invites = data?.[RELATIONS.GROUP_INVITE] as unknown as User[]

	useEffect(() => {
		EventBus.$on('refreshInvitesToPool', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshInvitesToPool')
		}
	}, [refresh])

	return (
		<>
			<span className="body1-b">{t('APP_INVITES_TO_POOL')}</span>
			<RecentCarousel name="" perView={4}>
				<>
					{invites?.map((user) => (
						<SwiperSlide key={user?.ContentId}>
							<NetworkCard
								style={{ width: '100%' }}
								variant="small"
								data={user}
								onClick={() => {
									const { setAcceptInviteToPoolSidebar } =
										useNetworkStore.getState()
									setAcceptInviteToPoolSidebar(user.ContentId)
								}}
							/>
						</SwiperSlide>
					))}
				</>
			</RecentCarousel>
		</>
	)
}

export default InvitesToPoolCarousel
