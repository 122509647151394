import { useState } from 'react'
import Card from 'components/Wizard/Card'
import Button from 'components/UI/Button'
import CheckboxButton from 'components/UI/CheckboxButton'
import { optionsParser, t } from 'helpers'
import { useAppStore } from 'store'
import { DROPDOWN_OPTIONS } from 'types/enums'
import s from '../../index.module.scss'

const Questions = (): JSX.Element => {
	const [questions, setQuestions] = useState<string[]>([])

	return (
		<Card
			step="onboarding_questions"
			noHint
			noTitle
			style={{
				padding: '0',
			}}>
			<div className={s.onboardingWrapper}>
				<div className={s.contentWrapper}>
					<div className={s.titles}>
						<h1 className="h1-m">{t('APP_ONBOARDING_QUESTIONS_TITLE')}</h1>
						<span className="body1-m">
							{t('APP_ONBOARDING_QUESTIONS_DESC')}
						</span>
					</div>
					<div
						style={{
							maxWidth: '300px',
							width: '100%',
							gap: '10px',
							display: 'flex',
							flexDirection: 'column',
						}}>
						{optionsParser(t(DROPDOWN_OPTIONS.ONBOARDING_QUESTIONS))?.map(
							(option) => (
								<CheckboxButton
									label={option.label}
									key={option.value}
									checked={questions?.includes(option.value)}
									onChange={() => {
										if (questions?.includes(option.value))
											setQuestions((prev) =>
												prev?.filter((v) => v !== option.value),
											)
										else setQuestions((prev) => [...prev, option.value])
									}}
								/>
							),
						)}
					</div>
					<div style={{ maxWidth: '300px', width: '100%' }}>
						<Button
							variant="themed"
							disabled={!questions?.length}
							style={{ width: '100%' }}
							onClick={() => {
								const { stepWizard } = useAppStore.getState()
								stepWizard?.nextStep()
							}}>
							{t('APP_NEXT')}
						</Button>
					</div>
				</div>
				<div className={s.imageWrapper}>
					<img src="/card-bg.png" />
				</div>
			</div>
		</Card>
	)
}

export default Questions
