import { useShallow } from 'zustand/react/shallow'
import { PhoneInputRefType } from 'react-international-phone'
import cn from 'classnames'
import Input from 'components/UI/Input'
import Card from 'components/Wizard/Card'
import RangeInput from 'components/UI/RangeInput'
import PhoneInput from 'components/UI/PhoneInput'
import CountrySelector from 'components/CountrySelector'
import CheckboxButton from 'components/UI/CheckboxButton'
import Agency from './components/Agency'
import { t } from 'helpers'
import { usePortfolioStore, useUserStore } from 'store'
import { User } from 'types/user'
import { contactFields } from '../../fields'
import s from '../Basic/index.module.scss'

// km:mile 1:0.62

const Contact = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state.user))
	const [distance, location, travel, fields] = usePortfolioStore(
		useShallow((state) => [
			state.cardValues['contact'][contactFields.distance.name],
			state.cardValues['contact'][contactFields.location.name] as string,
			state.cardValues['contact'][contactFields.travel.name] as string,
			state.cardValues['contact'],
		]),
	)

	const phoneSelector = contactFields.phone

	return (
		<Card step="contact">
			<div className={s.wrapper}>
				<div
					className={cn('grid2column')}
					style={{
						width: '100%',
					}}>
					{Object.values(contactFields)
						?.filter((field) =>
							field?.type === 'range' ? travel !== 'YES' : true,
						)
						?.map((field) =>
							field.type === 'range' ? (
								<RangeInput
									key={field.name}
									checkOption
									checkValue={travel !== 'YES' && !!distance && +distance > 0}
									onCheckClick={() => {
										const { updateFields, setCardValues } =
											usePortfolioStore.getState()
										const newValue =
											travel !== 'YES' && !!distance && +distance > 0 ? '' : '1'
										setCardValues(
											{
												[field.name]: newValue,
											},
											'contact',
										)
										updateFields([{ Name: field.name, Value: newValue }])
									}}
									label={t(field.label)}
									valueLabel={`${distance || 0} km / ${(
										(+distance || 0) * 0.62
									).toFixed(0)} miles`}
									value={+distance || 0}
									name={field.name}
									min={0}
									max={1000}
									onBlur={(e) => {
										const { updateFields } = usePortfolioStore.getState()
										updateFields([
											{
												Name: field.name,
												Value: e.target.value,
											},
										])
									}}
									onChange={(e) => {
										const { setCardValues } = usePortfolioStore.getState()
										setCardValues(
											{
												[field.name]: e.target.value,
											},
											'contact',
										)
									}}
								/>
							) : field?.name === phoneSelector?.name ? (
								<PhoneInput
									key={field.name}
									label={field.label}
									name={field.name}
									onBlur={(e) => {
										const target = e.target as PhoneInputRefType
										if (
											target?.value &&
											target?.value !== '' &&
											target?.value !==
												(user ? user[field.name as keyof User] : '')
										) {
											const { updateFields } = usePortfolioStore.getState()
											updateFields([
												{
													Name: e.target.name,
													Value: target?.value,
												},
											])
										}
									}}
									defaultValue={
										user
											? user[
													field.name?.replaceAll(':', '') as keyof User
											  ]?.toString() || ''
											: ''
									}
								/>
							) : field.name?.includes('Agency') ? (
								<Agency key={field.name} />
							) : field.name === contactFields.location.name ? (
								<CountrySelector
									key={field.name}
									value={location}
									onChange={(e) => {
										const { updateFields, setCardValues } =
											usePortfolioStore.getState()
										setCardValues(
											{
												[field.name]: e.target.value,
											},
											'contact',
										)
										updateFields([{ Name: field.name, Value: e.target.value }])
									}}
								/>
							) : field.type === 'checkbox' ? (
								<div
									key={field.name}
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignSelf: 'start',
									}}>
									<CheckboxButton
										label={field.label}
										checked={!!travel}
										onChange={() => {
											const { updateFields, setCardValues } =
												usePortfolioStore.getState()
											const newValue = travel ? '' : 'YES'
											setCardValues(
												{
													[field.name]: newValue,
												},
												'contact',
											)
											updateFields([{ Name: field.name, Value: newValue }])
										}}
									/>
								</div>
							) : (
								<Input
									key={field.name}
									name={field.name}
									label={field.label}
									placeholder={
										field.name?.endsWith('ImdbUrl')
											? 'https://www.imdb.com/name/'
											: ''
									}
									value={(fields[field?.name] as string) || ''}
									onChange={(e) => {
										const { setCardValues } = usePortfolioStore.getState()
										setCardValues(
											{
												[field.name]: e.target.value,
											},
											'contact',
										)
									}}
									onBlur={(e) => {
										if (
											e.target.value?.trim() !== '' &&
											e.target.value.trim() !== user?.CustomerName
										) {
											const { updateFields } = usePortfolioStore.getState()
											updateFields([
												{ Name: e.target.name, Value: e.target.value },
											])
										}
									}}
								/>
							),
						)}
				</div>
			</div>
		</Card>
	)
}

export default Contact
