import { AppleIcon, ArrowShortIcon, GoogleIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import s from './index.module.scss'

const Form = (): JSX.Element => {
	const handleLogin = (type: 'google' | 'apple'): void => {
		localStorage.setItem('provider', type)

		const redirectLink = `${
			window.location.href.includes('localhost')
				? 'http://localhost:3000'
				: 'https://showrealz.com'
		}/join/login?token={token}`

		const links = {
			google: 'https://bgj.theatroo.ent360.blue/Google/SignIn',
			apple: 'https://bgj.theatroo.ent360.blue/Apple/SignIn',
		}

		const link = links[type] + `?state=webredirection%23%23${redirectLink}`

		window.location.href = link
	}

	return (
		<div className={s.wrapper}>
			<div className={s.titles}>
				<h2 className="h2-m">{t('APP_LOGIN_WELCOME_TITLE')}</h2>
				<span className="body1-m">{t('APP_LOGIN_WELCOME_SUBTITLE')}</span>
			</div>
			<div className={s.buttonWrapper}>
				<Button variant="outlined" onClick={() => handleLogin('google')}>
					<div style={{ display: 'flex' }}>
						<GoogleIcon />
					</div>
					{t('APP_GOOGLE_AUTH')}
					<div style={{ display: 'flex' }}>
						<ArrowShortIcon />
					</div>
				</Button>
			</div>
			<div className={s.buttonWrapper}>
				<Button variant="outlined" onClick={() => handleLogin('apple')}>
					<div style={{ display: 'flex' }}>
						<AppleIcon />
					</div>
					{t('APP_APPLE_AUTH')}
					<div style={{ display: 'flex' }}>
						<ArrowShortIcon />
					</div>
				</Button>
			</div>
		</div>
	)
}

export default Form
