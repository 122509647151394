import { createWithEqualityFn } from 'zustand/traditional'
import { ModalStore } from 'types/store'

const useStore = createWithEqualityFn<ModalStore>(
	(set) => ({
		open: {
			show: false,
			children: null,
		},
		setOpen: (open) => set({ open }),
	}),
	Object.is,
)

export default useStore
