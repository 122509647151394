import { InputHTMLAttributes, useState } from 'react'
import { toast } from 'react-toastify'
import { useShallow } from 'zustand/react/shallow'
import { upload } from 'api/aws'
import { contentDelete, createContentUpload } from 'api/content'
import { FileIcon, HDIcon } from 'assets/icons'
import FileInput from 'components/UI/FileInput'
import Hint from 'components/Wizard/Hint'
import { usePortfolioStore } from 'store'
import { t } from 'helpers'
import { imageUploadWorkflowId, videoUploadWorkflowId } from 'helpers/constants'
import { getVideoDuration } from 'helpers/getVideoMetadata'
import { FileFormVariant } from 'types/components'
import s from './index.module.scss'

interface Props {
	form: FileFormVariant
	label: string
	itemTitle: string
	hintText: string
	formatsText: string
	qualityText: string
	name: string
	fileType: 'Image' | 'Video'
	fileInputProps?: InputHTMLAttributes<HTMLInputElement>
	uploadImmediately?: boolean
	index?: number
	update?: boolean
	deleteItem?: boolean
}

const FileUploadCard = ({
	label,
	itemTitle,
	form,
	hintText,
	formatsText,
	qualityText,
	fileType,
	name,
	fileInputProps,
	uploadImmediately = true,
	update,
	deleteItem,
}: Props): JSX.Element => {
	const [updateFile, setUpdateFile] = useState(false)
	const [refreshSrc, setRefreshSrc] = useState(false)
	const contentId = usePortfolioStore(
		useShallow(
			(state) =>
				state.cardValues[name] && (state.cardValues[name]?.contentId as string),
		),
	)
	const value = usePortfolioStore(
		useShallow((state) =>
			state.cardValues[name] && state.cardValues[name][name]
				? (state.cardValues[name][name] as File)
				: undefined,
		),
	)

	const uploadFile = async (name: string, file: File): Promise<void> => {
		try {
			const response = await upload({
				fileObj: file,
				assetType: 'Content',
			})

			let fileDuration = '0'
			if (fileType === 'Video') fileDuration = await getVideoDuration(file)

			await createContentUpload({
				...response,
				duration: +fileDuration ? +fileDuration * 1000 : 0,
				originalTitle: file.name,
				workflowId:
					fileType === 'Image' ? imageUploadWorkflowId : videoUploadWorkflowId,
				originalLanguage: 'en-US',
				localizations: {
					'en-US': { name: response.originalFileName },
				},
				type: fileType,
				originalFileName: response.originalFileName,
				objectUrl: response.fileAssetUrl,
				publicUrl: response.cdnUrl,
				exhibitionWindow: {
					'--': {
						availableFrom:
							response.availableFrom?.toISOString &&
							response.availableFrom.toISOString(),
						availableUntil:
							response.availableUntil?.toISOString &&
							response.availableUntil.toISOString(),
					},
				},
				allowMinting: true,
				allowRemix: true,
				allowComments: true,
			})

			if (name) {
				const { updateFields } = usePortfolioStore.getState()
				await updateFields([
					{
						Name: name,
						Value: response.cdnUrl,
					},
				])
			}

			if (fileType === 'Video')
				toast.info(t('APP_CONTENT_UPLOAD_DELAY_MESSAGE'))
		} catch (error) {
			console.log('error:', error)
		}
	}

	const verticalInfo = ['circle-s', 'landscape', 'portrait'].includes(form)

	const mediaSidebarStyle = verticalInfo
		? {
				minWidth: 'unset',
		  }
		: {}

	return (
		<div
			className={s.itemWrapper}
			style={
				['rectangle-h', 'portrait-v'].includes(form)
					? { flexDirection: 'column', gap: '10px' }
					: verticalInfo
					? { gap: '10px' }
					: {}
			}>
			<div
				className={s.fileWrapper}
				style={{
					margin: '5px 0',
					...mediaSidebarStyle,
				}}>
				<FileInput
					{...fileInputProps}
					update={update || updateFile}
					form={form}
					file={value}
					label={label}
					fileType={fileType}
					refreshSrc={refreshSrc}
					setRefreshSrc={setRefreshSrc}
					onChange={(e) => {
						if (e.target.files?.length && e.target.files[0]) {
							if (uploadImmediately) {
								const { setCardValues } = usePortfolioStore.getState()
								setCardValues(
									{
										[name]: e.target.files[0],
									},
									name,
								)
								uploadFile(name, e.target.files[0])
								if (update) setRefreshSrc(true)
							} else {
								const { setCardValues } = usePortfolioStore.getState()
								setCardValues({
									[name]: e.target.files[0],
									newUpload: true,
								})
							}
						}
					}}
				/>
			</div>
			<div
				className={s.info}
				style={
					['rectangle-h', 'portrait-v'].includes(form)
						? {
								width: '100%',
						  }
						: {}
				}>
				<h3 className="h3-m">{t(itemTitle)}</h3>
				<div
					className={s.desc}
					style={
						verticalInfo
							? { flexDirection: 'column', alignItems: 'flex-start' }
							: ['rectangle-h', 'portrait-v'].includes(form)
							? {
									justifyContent: 'space-around',
							  }
							: {}
					}>
					<div>
						<div style={{ display: 'flex' }}>
							<HDIcon />
						</div>
						<span className="caption-m">{t(qualityText)}</span>
					</div>
					<div>
						<div style={{ display: 'flex' }}>
							<FileIcon />
						</div>
						<span className="caption-m">{t(formatsText)}</span>
					</div>
				</div>
				{value &&
				(typeof value === 'string' || (value instanceof File && contentId)) &&
				deleteItem ? (
					<div
						className={s.desc}
						style={
							verticalInfo
								? { flexDirection: 'column', alignItems: 'flex-start' }
								: ['rectangle-h', 'portrait-v'].includes(form)
								? {
										justifyContent: 'space-around',
								  }
								: {}
						}>
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => {
								if (typeof value === 'string') {
									const id = (value as string).split('/')[3]
									if (id) contentDelete(id)
								} else if (contentId) contentDelete(contentId)
								const { setCardValues } = usePortfolioStore.getState()
								setCardValues(
									{
										[name]: '',
									},
									name,
								)
								setUpdateFile(true)
								setRefreshSrc(true)
							}}>
							<span className="body1-m" style={{ color: 'var(--mono300)' }}>
								{t('APP_REMOVE_LABEL')}
							</span>
						</div>
						<div></div>
					</div>
				) : null}
				<Hint hintText={t(hintText)} />
			</div>
		</div>
	)
}

export default FileUploadCard
