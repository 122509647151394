import { useMemo } from 'react'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import Loader from 'components/Loader'
import Sidebar from 'components/Sidebar'
import Hint from 'components/Wizard/Hint'
import AttributeSidebarContent from './AttributeSidebarContent'
import { optionsParser, t } from 'helpers'
import { getCardName, getRandomCardName } from 'helpers/propertiesParser'
import { usePortfolioStore } from 'store'
import { PORTFOLIO_TYPES } from 'types/enums'
import { AttributeCardType } from 'types/app'
import s from './index.module.scss'

interface Props {
	cardData: AttributeCardType | null
}

const AttributeSidebar = ({ cardData }: Props): JSX.Element => {
	const [loading, type, generalType, iconClose] = usePortfolioStore(
		useShallow((state) => [
			state.loading,
			state.modalType,
			state.generalModalType,
			!!state.additionalSidebar || !!state.galleryType,
		]),
	)

	const values = usePortfolioStore(
		useShallow((state) => state.cardValues[type] || {}),
	)

	const selectorsData = useMemo(
		() =>
			cardData?.selectors?.map((select) => ({
				...select,
				options: optionsParser(t(select.optionName || '')),
			})),
		[cardData?.selectors],
	)

	const hideVoiceSelectors = useMemo(
		() =>
			cardData?.value === 'voice' &&
			(values?.[cardData?.selectors?.[0].name] as string[])?.[0] ===
				optionsParser(cardData?.selectors?.[0]?.optionName || '')?.[0]?.value,
		[cardData?.selectors, cardData?.value, values],
	)

	const mediaType = [
		PORTFOLIO_TYPES.PICTURE,
		PORTFOLIO_TYPES.SHOWREEL,
	].includes(generalType)

	const mediaCardName =
		mediaType &&
		selectorsData &&
		(getCardName(selectorsData, values) || getRandomCardName(selectorsData))

	const title =
		type && generalType
			? `APP_${
					type.toUpperCase() + '_'
			  }${generalType.toUpperCase()}_MODAL_TITLE`
			: ''

	const closeSidebar = (): void => {
		const {
			clearModalType,
			setGalleryType,
			setCardData,
			setAdditionalSidebar,
		} = usePortfolioStore.getState()
		clearModalType()
		setGalleryType(null)
		setCardData(null)
		setAdditionalSidebar(null)
	}

	return (
		<Sidebar
			open={!!cardData}
			onClose={() => {
				closeSidebar()
			}}
			iconClose={iconClose}
			header={{
				title: mediaType
					? t(`APP_${generalType?.toUpperCase()}_CARD`) +
					  ` #${(cardData?.index || 0) + 1}`
					: mediaCardName || t(title),
			}}
			button={{
				children: t('APP_DONE'),
				onClick: () => {
					if (
						[PORTFOLIO_TYPES.SHOWREEL, PORTFOLIO_TYPES.PICTURE].includes(
							generalType,
						)
					) {
						const { setConfirmModal, setGalleryType, setAdditionalSidebar } =
							usePortfolioStore.getState()
						setConfirmModal(true)
						setGalleryType(null)
						setAdditionalSidebar(null)
					} else closeSidebar()
				},
			}}>
			<>
				<Loader loading={loading} adapt />
				{cardData && selectorsData ? (
					<>
						<Hint
							hintText={t(
								`APP_${(mediaType
									? generalType
									: type
								).toUpperCase()}_MODAL_HINT`,
							)}
							style={{ marginTop: '0' }}
						/>
						<div className={cn(s.contentWrapper, 'hideScrollBar')}>
							<AttributeSidebarContent
								cardData={cardData}
								selectorsData={
									hideVoiceSelectors
										? selectorsData?.slice(0, 1)
										: selectorsData
								}
							/>
						</div>
					</>
				) : null}
			</>
		</Sidebar>
	)
}

export default AttributeSidebar
