import { Fragment, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'react-toastify'
import { PlusIcon } from 'assets/icons'
import { bindInvites, getUserData, usersBatchInvite } from 'api/user'
import Tooltip from 'components/Tooltip'
import Textarea from 'components/UI/Textarea'
import Card from 'components/Wizard/Card'
import Controls from 'components/Wizard/Controls'
import UsersSearchSidebar from './components/UsersSearchSidebar'
import { t } from 'helpers'
import { useMessageStore } from 'store'
import { MessageData } from 'types/app'
import { User } from 'types/user'
import s from './index.module.scss'
import { CASTING_STATUS, MESSAGE_TYPE, RELATIONS } from 'types/enums'

const Message = (): JSX.Element => {
	const navigate = useNavigate()
	const firstLoad = useRef(true)
	const { search } = useLocation()
	const params = new URLSearchParams(search)
	const token = params.get('data')
	const key = params.get('key')?.toUpperCase()
	const data = (token ? JSON.parse(window.atob(token)) : {}) as MessageData

	const [inviteList] = useMessageStore(useShallow((state) => [state.users]))
	const messageRef = useRef<HTMLTextAreaElement>(null)
	const {
		sourceId,
		controlsProps,
		redirect,
		openSidebar,
		messageValue,
		userIds,
		messageType,
	} = data

	const getUsers = async (userIds: string[]): Promise<void> => {
		try {
			const users = await Promise.all(userIds?.map((id) => getUserData(id)))
			if (users?.filter((v) => !!v)?.length) {
				const { setValue } = useMessageStore.getState()
				setValue({ users: users?.filter((v) => !!v) as User[] })
			}
		} catch (error) {
			console.log('error:', error)
		}
	}

	const sendMessage = async (): Promise<void> => {
		try {
			if (inviteList?.length) {
				await usersBatchInvite({
					sourceId,
					customerIds: inviteList?.map((user) => user?.ContentId),
					message: messageRef.current?.value || '',
					type: messageType === MESSAGE_TYPE.CASTING ? 'Content' : 'Customer',
					relation:
						messageType === MESSAGE_TYPE.CASTING
							? RELATIONS.ACCESS
							: RELATIONS.CUSTOMER_INVITE,
					status:
						messageType === MESSAGE_TYPE.CASTING ? CASTING_STATUS.INVITED : '',
					subject:
						messageType === MESSAGE_TYPE.CASTING
							? '[YourName] invited for Casting'
							: '[YourName] requested profile update',
				})

				if (messageType === MESSAGE_TYPE.CASTING) {
					await bindInvites(sourceId)
				}

				toast.info(t('APP_SENDED_SUCCESSFULLY'))
			}
		} catch (error) {
			console.log('error:', error)
		}
	}

	useEffect(() => {
		if (!token) navigate('/')
	}, [token, navigate])

	useEffect(() => {
		return () => {
			const { resetStore } = useMessageStore.getState()
			resetStore()
		}
	}, [])

	useEffect(() => {
		if (openSidebar) {
			const { setValue } = useMessageStore.getState()
			setValue({ openUsersSearchSidebar: true })
		}
	}, [openSidebar])

	useEffect(() => {
		if (userIds?.length && firstLoad.current) {
			firstLoad.current = false
			getUsers(userIds)
		}
	}, [userIds])

	return (
		<div className={s.container}>
			{messageType === MESSAGE_TYPE.CASTING ? (
				<UsersSearchSidebar contentId={sourceId} />
			) : null}
			<Card step={`${key}_message`} className="cardHeight">
				<>
					{messageType === MESSAGE_TYPE.CASTING ? (
						<div className={s.searchWrapper}>
							<div
								className={s.addButton}
								onClick={() => {
									const { setValue } = useMessageStore.getState()
									setValue({ openUsersSearchSidebar: true })
								}}>
								<PlusIcon />
							</div>
							<div className={s.users}>
								{inviteList?.map((user, index) => (
									<Tooltip key={user.ContentId} title={user?.ContentTitle}>
										<div
											className={s.userWrapper}
											style={
												index === 0
													? {
															border: `1px solid var(--${user?.CustomerRole?.toLowerCase()})`,
													  }
													: {
															border: `1px solid var(--${user?.CustomerRole?.toLowerCase()})`,
															transform: `translateX(-${20 * index}px)`,
													  }
											}>
											<div
												className={s.remove}
												onClick={() => {
													const { setUser } = useMessageStore.getState()
													setUser(user)
												}}>
												<PlusIcon style={{ transform: 'rotate(45deg)' }} />
											</div>
											<div className={s.user}>
												<img
													src={user?.CustomerImageSquareUrl}
													alt={user?.ContentTitle}
												/>
											</div>
										</div>
									</Tooltip>
								))}
							</div>
						</div>
					) : null}
					<Textarea
						label={`APP_${key}_MESSAGE_LABEL`}
						defaultValue={messageValue || t(`APP_${key}_MESSAGE_BODY`)}
						inputRef={messageRef}
						rows={messageType === MESSAGE_TYPE.CASTING ? 11 : 13}
					/>
				</>
			</Card>
			<Controls
				{...controlsProps}
				nextClick={() => {
					sendMessage()
					if (redirect) navigate(redirect)
				}}
				prevClick={() => {
					if (redirect) navigate(redirect)
				}}
			/>
		</div>
	)
}

export default Message
