/* eslint-disable no-tabs */
import { lazy, useEffect } from 'react'
// import { useShallow } from 'zustand/react/shallow'
import { useNavigate } from 'react-router-dom'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import cn from 'classnames'
import s from '../index.module.scss'
import { useAppStore } from 'store'
import StepWrapper from 'components/Wizard/StepWrapper'

const Recent = lazy(() => import('./components/Recent'))

export const contributorCastingSteps = {
	recent: {
		name: 'APP_CASTING_RECENT',
		key: 'recent',
		main: true,
		substeps: [
			{
				name: 'APP_CASTING_RECENT',
				key: 'recent',
				component: Recent,
				index: 0,
			},
		],
	},
}

const ContributorCasting = (): JSX.Element => {
	const navigate = useNavigate()
	// const stepWizard = useAppStore(useShallow((state) => state.stepWizard))

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					'#' +
					contributorCastingSteps.recent?.key +
					'-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	// const nextStep = (): void => {
	// 	stepWizard?.nextStep()
	// }

	// const prevStep = (): void => {
	// 	stepWizard?.previousStep()
	// }

	// const onStepChange = (tab: string): void => {
	// 	stepWizard?.goToNamedStep(tab)
	// }

	// const onSubmitStep = (): void => {
	// 	// const submitButton = document.getElementById(`next-${hash?.slice(1)}`)
	// 	// if (submitButton) submitButton.click()
	// 	// else nextStep()
	// 	nextStep()
	// }

	return (
		<div className={s.container}>
			<div className={cn(s.wrapper, 'flex')}>
				<StepWizard
					initialStep={0}
					instance={assignStepWizard}
					isHashEnabled={true}
					className={s.wizard}>
					{Object.values(contributorCastingSteps)
						?.map((step) => step.substeps)
						?.flat()
						?.map((step) => (
							<StepWrapper
								key={step.name + '_' + step.index}
								hashKey={step.key + '-' + step.index}
								stepName={step.key + '-' + step.index}>
								<step.component />
							</StepWrapper>
						))}
				</StepWizard>
			</div>
		</div>
	)
}

export default ContributorCasting
