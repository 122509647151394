import { useShallow } from 'zustand/react/shallow'
import { ChangeEvent, Fragment } from 'react'
import Dropdown from 'components/UI/Dropdown'
import CheckboxButton from 'components/UI/CheckboxButton'
import { optionsParser, t } from 'helpers'
import { useFilterStore } from 'store'
import { AttributeSelector } from 'types/app'

interface Props {
	skillField: AttributeSelector
	levelField: AttributeSelector
}

const FilterTable = ({ skillField, levelField }: Props): JSX.Element => {
	const [values, setValue] = useFilterStore(
		useShallow((state) => [
			(state.filters[skillField.name] || []) as Array<{
				[key: string]: string
			}>,
			state.setFilters,
		]),
	)

	const onChange = (
		e: ChangeEvent<HTMLInputElement>,
		type: 'value' | 'level',
		value?: string,
	): void => {
		const currentValue = values?.find(
			(v) => v['Value'] === (type === 'value' ? e.target.value : value),
		)
		if (currentValue) {
			const filtered = values?.filter(
				(v) => v['Value'] !== (type === 'value' ? e.target.value : value),
			)
			const newValues =
				type === 'value'
					? filtered
					: [
							...filtered,
							{
								['Value']: value || '',
								['Level']: e.target.value,
							},
					  ]
			setValue({ [skillField.name]: newValues })
		} else {
			const newValue =
				type === 'value'
					? {
							['Value']: e.target.value,
							['Level']: '',
					  }
					: {
							['Value']: value || '',
							['Level']: e.target.value,
					  }

			setValue({
				[skillField.name]: [...values, newValue],
			})
		}
	}

	return (
		<div className="grid2column" style={{ gap: '10px' }}>
			{optionsParser(t(skillField.optionName || ''))?.map((option) => (
				<Fragment key={option.value}>
					<CheckboxButton
						checked={!!values?.find((v) => v['Value'] === option.value)}
						label={option.label}
						onChange={() =>
							onChange(
								{
									target: {
										value: option.value,
									},
								} as ChangeEvent<HTMLInputElement>,
								'value',
							)
						}
						style={{
							alignItems: 'center',
						}}
					/>
					<Dropdown
						options={optionsParser(t(levelField.optionName || ''))}
						sort={'none'}
						value={
							values?.find((v) => v['Value'] === option.value)?.['Level'] || ''
						}
						onChange={(e) => onChange(e, 'level', option.value)}
						placeholder={t('APP_LEVEL_LABEL')}
					/>
				</Fragment>
			))}
		</div>
	)
}

export default FilterTable
