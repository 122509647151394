import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChatToken } from 'amazon-ivs-chat-messaging'
import dayjs from 'dayjs'
import { CompleteIcon } from 'assets/icons'
import { connectIVSChatRoom } from 'api/chat'
import Loader from 'components/Loader'
import Sidebar from 'components/Sidebar'
import useMessageHistory from 'hooks/api/useMessageHistory'
import { useChatRoom } from 'hooks/useChatRoom'
import { t, uuidv4 } from 'helpers'
import { getUser } from 'helpers/storage'
import { notif } from 'helpers/notif'
import { useAppStore } from 'store'
import { ExtendedMessage } from 'types/chat'
import { Content } from 'types/app'
import { SIDEBARS } from 'types/enums'
import s from './index.module.scss'
import * as config from './config'
import { PATHS } from 'pages/Router'

interface Props {
	chatData: Content
}

const NotificationSidebar = ({ chatData }: Props): JSX.Element => {
	const { search } = useLocation()
	const navigate = useNavigate()
	const openSidebar =
		new URLSearchParams(search).get('sidebar') === SIDEBARS.NOTIFICATIONS

	const { room } = useChatRoom({
		regionOrUrl: config.CHAT_WEBSOCKET,
		tokenProvider: () =>
			tokenProvider(getUser()?.['Custom:User:Id'], true, {
				src: getUser()?.['Custom:User:Avatar'],
			}),
	})
	const {
		data: messageHistory,
		refreshData,
		isLoading,
	} = useMessageHistory(chatData.ContentId)

	const tokenProvider = useCallback(
		async (
			selectedUsername: string | undefined,
			isModerator: boolean,
			avatarUrl: { src: string | undefined },
		): Promise<ChatToken> => {
			const uuid = uuidv4()
			const permissions = isModerator
				? ['SEND_MESSAGE', 'DELETE_MESSAGE', 'DISCONNECT_USER']
				: ['SEND_MESSAGE']

			const data = {
				userId:
					getUser()?.['Custom:User:Avatar'] || `${selectedUsername}.${uuid}`,
				arn: chatData.ContentChatArn,
				attributes: {
					username: `${selectedUsername}`,
					avatar: `${avatarUrl.src}`,
				},
				capabilities: permissions,
			}

			let token = {} as ChatToken

			try {
				const response = await connectIVSChatRoom(chatData.ContentId, data)

				token = {
					token: response.token,
					sessionExpirationTime: new Date(response.sessionExpiration),
					tokenExpirationTime: new Date(response.tokenExpiration),
				} as ChatToken
			} catch (error) {
				console.log('error:', error)
			}

			return token
		},
		[chatData.ContentChatArn, chatData.ContentId],
	)

	useEffect(() => {
		const unsubscribeOnMessageReceived = room.addListener(
			'message',
			(message) => {
				const parsedMessage = {
					...message,
					content: JSON.parse(message.content),
				}

				if (
					window.location.search.includes('sidebar=' + SIDEBARS.NOTIFICATIONS)
				) {
					refreshData()

					return
				}

				notif('info', [
					'New notification',
					dayjs(parsedMessage.sendTime).format('MMM D, h:mm'),
					parsedMessage.content.body,
				])
			},
		)

		const unsubscribeOnMessageDeleted = room.addListener(
			'messageDelete',
			() => {
				refreshData()
			},
		)

		return () => {
			unsubscribeOnMessageDeleted()
			unsubscribeOnMessageReceived()
		}
	}, [room, refreshData])

	useEffect(() => {
		if (openSidebar) {
			refreshData()
		}
	}, [openSidebar, refreshData])

	useEffect(() => {
		room.connect()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const { setChatId, chatId } = useAppStore.getState()
		if (chatData?.ContentId && !chatId) {
			setChatId(chatData?.ContentId)
		}
	}, [chatData?.ContentId])

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => {
				navigate(window.location.pathname + window.location.hash)
			}}
			header={{
				title: 'APP_NOTIF_SIDEBAR_TITLE',
				description: 'APP_NOTIF_SIDEBAR_DESCRIPTION',
			}}>
			<>
				<div className={s.list}>
					<Loader loading={isLoading} compressed />
					{messageHistory?.length ? (
						messageHistory?.map((message) => (
							<Notification message={message} key={message.id} />
						))
					) : (
						<span className="body1-m">{t('APP_NO_NOTIFICATIONS')}</span>
					)}
				</div>
			</>
		</Sidebar>
	)
}

export default NotificationSidebar

const Notification = ({
	message,
}: {
	message: ExtendedMessage
}): JSX.Element => {
	const hoursAgo = dayjs(Date.now()).diff(message.sendTime, 'h') >= 12 // 12 hours ago
	const navigate = useNavigate()

	return (
		<div
			className={s.notif}
			style={{
				cursor: message?.sender ? 'pointer' : 'default',
			}}
			onClick={() => {
				if (message.sender?.ContentId)
					navigate(
						'/' + PATHS.PROFILE?.replace(':userId', message.sender.ContentId),
					)
			}}>
			<div
				className={s.image}
				style={{
					border: message?.sender
						? `2px solid var(--${message?.sender?.CustomerRole?.toLowerCase()})`
						: 'none',
				}}>
				{message?.sender ? (
					<img
						src={message.sender?.CustomerImageSquareUrl || '/placeholder.png'}
						alt={message?.sender?.ContentTitle}
					/>
				) : (
					<div
						className="notifInfoIcon"
						style={
							hoursAgo ? { backgroundColor: 'rgba(255, 255, 255, 0.1)' } : {}
						}>
						<CompleteIcon
							color={hoursAgo ? 'var(--mono300)' : 'var(--success)'}
						/>
					</div>
				)}
			</div>
			<div className="notifMessageClass">
				<span className="body1-m">
					{message?.sender ? message.sender?.ContentTitle : 'Notification'}
				</span>
				{message.sendTime ? (
					<span className="caption-r">
						{dayjs(message.sendTime).format('MMM D, HH:mm')}
					</span>
				) : null}
				{message.content.body ? (
					<p className="caption-s">{message.content.body}</p>
				) : null}
			</div>
		</div>
	)
}
