import { Suspense, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Loader from 'components/Loader'
import MobileBlockModal from 'components/MobileBlockModal'
import useAppEndpoints from 'hooks/api/useAppEndpoints'
import useLocalization from 'hooks/api/useLocalization'
import useUser from 'hooks/api/useUser'
import { useAppStore, useUserStore } from 'store'
import { getUser, logout, setStorageLocales } from 'helpers/storage'
import Router from 'pages/Router'

const App = (): JSX.Element => {
	const { isLoading: endpointsLoading, data: endpointsData } = useAppEndpoints()
	const {
		isLoading: localesLoading,
		data: localesData,
		error: localesError,
	} = useLocalization()
	const endpoints = useAppStore(useShallow((state) => state.endpoints))
	const locales = useAppStore(
		useShallow((state) => state?.locales && Object.keys(state.locales)?.length),
	)
	const userId = useUserStore(useShallow((state) => state.userId))
	const { user, isLoading } = useUser(userId, true)
	const [userLoading, setUserLoading] = useState(true)

	useEffect(() => {
		const { setEndpoints, endpoints } = useAppStore.getState()
		if (!endpointsLoading && endpointsData && !endpoints) {
			setEndpoints(endpointsData)
		}
	}, [endpointsLoading, endpointsData])

	useEffect(() => {
		const { setLocales } = useAppStore.getState()
		if (!localesLoading && localesData) {
			const now = Date.now()
			const in1hour = now + 60 * 60 * 1000
			localStorage.setItem('LocalesExpiry', in1hour.toString())

			setStorageLocales(localesData)
			setLocales(localesData)
		}
	}, [localesLoading, localesData])

	useEffect(() => {
		if (localesError) {
			const { setLocales } = useAppStore.getState()
			setLocales({})
			localStorage.removeItem('LocalesExpiry')
		}
	}, [localesError])

	useEffect(() => {
		const { user: userData, setUser } = useUserStore.getState()
		if (user && (!userData || userData?.ContentId !== user?.ContentId)) {
			setUser(user)
			setUserLoading(false)
		} else if (!user && !isLoading && !getUser()?.['Custom:User:Id']) {
			setUserLoading(false)
		}
	}, [user, isLoading])

	useEffect(() => {
		const { userId, setUserId } = useUserStore.getState()
		const userData = getUser()
		const id = userData?.['Custom:User:Id']
		if (id && (!userId || userId !== id)) setUserId(id)
	}, [])

	useEffect(() => {
		if (endpoints && !user && !isLoading) {
			logout()
			setUserLoading(false)
		}
	}, [user, isLoading, endpoints])

	const loading = endpointsLoading || localesLoading || userLoading

	return (
		<Suspense fallback={<Loader loading />}>
			<MobileBlockModal />
			<Loader loading={loading} />
			{loading || !endpoints || !locales ? <></> : <Router />}
		</Suspense>
	)
}

export default App
