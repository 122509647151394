import { ChangeEvent } from 'react'
import cn from 'classnames'
import CheckboxButton from '../CheckboxButton'
import { t } from 'helpers'
import { SelectOption } from 'types/app'

interface Props {
	name: string
	label?: string
	value: string
	options: SelectOption[]
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const CheckboxSelect = ({
	name,
	label,
	value,
	options,
	onChange,
}: Props): JSX.Element => (
	<div style={{ display: 'flex', flexDirection: 'column' }}>
		{label ? (
			<span className={cn('inputLabel', 'caption-m')}>{t(label)}</span>
		) : null}
		<div
			className="grid2column"
			style={{
				gap: '20px',
				width: '100%',
			}}
		>
			{options?.map((option) => (
				<CheckboxButton
					key={option.value}
					checked={value?.includes(option.value)}
					label={option.label}
					onChange={() =>
						onChange({
							target: {
								name: name,
								value: option.value,
							},
						} as ChangeEvent<HTMLInputElement>)
					}
				/>
			))}
		</div>
	</div>
)

export default CheckboxSelect
