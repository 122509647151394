import { useLocation, useNavigate } from 'react-router-dom'
import { useModalStore, useNetworkStore, useUserStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import { addRelation, deleteRelation } from 'api/relations'
import {
	AddNetworkIcon,
	AddPersonIcon,
	PlusIcon,
	RequestIcon,
} from 'assets/icons'
import Tooltip from 'components/Tooltip'
import Button from 'components/UI/Button'
import { prepareMessageData, t } from 'helpers'
import { getUser } from 'helpers/storage'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useRelations from 'hooks/api/useRelations'
import { MESSAGE_TYPE, RELATIONS } from 'types/enums'
import { CUSTOMER_ROLE, User } from 'types/user'
import { Relation } from 'types/app'
import { PATHS } from 'pages/Router'
import s from './index.module.scss'

interface Props {
	user: User | undefined
}

const Actions = ({ user }: Props): JSX.Element | null => {
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const [currentUser] = useUserStore(useShallow((state) => [state.user]))
	const userRole = currentUser?.CustomerRole?.toLowerCase()
	const director = userRole === CUSTOMER_ROLE.CASTING_DIRECTOR

	const agency = userRole === CUSTOMER_ROLE.AGENCY

	const showUpdateRequest =
		director && currentUser?.ContentId !== user?.ContentId

	const openPoolSidebar = (): void => {
		const { setOpenInviteUserSidebar } = useNetworkStore.getState()
		setOpenInviteUserSidebar(true)
	}

	const requestUpdate = (): void => {
		const data = {
			key: 'UPDATEREQUEST',
			messageValue: t('APP_UPDATEREQUEST_MESSAGE_BODY')?.replace(
				'[YourName]',
				currentUser?.ContentTitle || '',
			),
			sourceId: currentUser?.ContentId || '',
			userIds: [user?.ContentId || ''],
			redirect: window.location.pathname + window.location.hash,
			openSidebar: false,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.CUSTOMER,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
	}

	const removeFromGroup = async (
		refresh: () => void,
		organizationId?: string,
	): Promise<void> => {
		const { setOpen } = useModalStore.getState()
		setOpen({
			show: true,
			children: (
				<div
					className="card"
					style={{
						maxWidth: '500px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '20px',
					}}>
					<>
						<h4 className="h4-m">{t('APP_ACCEPT_REMOVE_FROM_GROUP')}</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={async () => {
									try {
										if (user && organizationId)
											await deleteRelation(
												user?.ContentId,
												'Customer',
												RELATIONS.ORGANIZATION,
												organizationId,
											)
										refresh()
									} catch (error) {
										console.log('error:', error)
									} finally {
										setOpen(null)
									}
								}}>
								{t('APP_CONFIRM')}
							</Button>
							<Button
								style={{ width: '100%' }}
								onClick={() => {
									setOpen(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</div>
			),
		})
	}

	return pathname?.includes(PATHS.NETWORK) && (director || agency) ? (
		<div className={s.actions}>
			<Tooltip title="APP_ADD_TO_POOL_ACTION" placement="top">
				<div onClick={openPoolSidebar}>
					<AddPersonIcon color="var(--theme-primary)" />
				</div>
			</Tooltip>
			{showUpdateRequest ? (
				<>
					<Tooltip title="APP_REQUEST_PROFILE_UPDATE_ACTION" placement="top">
						<div onClick={requestUpdate}>
							<RequestIcon color="var(--theme-primary)" />
						</div>
					</Tooltip>
				</>
			) : null}
			{director ? (
				<GroupCDAction user={user} removeFromGroup={removeFromGroup} />
			) : agency ? (
				<GroupAgencyAction user={user} removeFromGroup={removeFromGroup} />
			) : null}
		</div>
	) : null
}

export default Actions

const GroupCDAction = ({
	user,
	removeFromGroup,
}: {
	user: User | undefined
	removeFromGroup: (refresh: () => void, org?: string) => void
}): JSX.Element => {
	const { data: orgs } = useRelationsContent([RELATIONS.ORGANIZATION])
	const organization = orgs ? orgs?.[RELATIONS.ORGANIZATION]?.[0] : undefined

	const { data: group, refresh } = useRelations(
		RELATIONS.ORGANIZATION,
		organization?.ContentId && user?.ContentId ? organization?.ContentId : '',
		organization?.ContentId && user?.ContentId ? user?.ContentId : '',
		'Customer',
	)

	const inGroup = (group as unknown as Relation)?.relation

	const addToGroup = async (): Promise<void> => {
		try {
			await addRelation({
				relation: RELATIONS.ORGANIZATION,
				type: 'Customer',
				sourceId: user?.ContentId || '',
				targetId: organization?.ContentId || '',
			})
			refresh()
		} catch (error) {
			console.log('error:', error)
		}
	}

	return (
		<>
			<Tooltip
				title={
					inGroup
						? 'APP_REMOVE_USER_FROM_GROUP_ACTION'
						: 'APP_ADD_USER_TO_GROUP_ACTION'
				}
				placement="top">
				<div
					onClick={
						inGroup
							? () => removeFromGroup(refresh, organization?.ContentId)
							: addToGroup
					}>
					{inGroup ? (
						<PlusIcon
							style={{ transform: 'rotate(45deg)' }}
							color="var(--theme-primary)"
						/>
					) : (
						<AddNetworkIcon color="var(--theme-primary)" />
					)}
				</div>
			</Tooltip>
		</>
	)
}

const GroupAgencyAction = ({
	user,
	removeFromGroup,
}: {
	user: User | undefined
	removeFromGroup: (refresh: () => void, org?: string) => void
}): JSX.Element => {
	const { data: orgs } = useRelationsContent([RELATIONS.ORGANIZATION])
	const organization = orgs ? orgs?.[RELATIONS.ORGANIZATION]?.[0] : undefined

	const { data: invite, refresh: refreshInvites } = useRelations(
		RELATIONS.GROUP_INVITE,
		getUser()?.['Custom:User:Id'] || '',
		user?.ContentId || '',
		'Customer',
	)

	const invited =
		invite &&
		'targetId' in invite &&
		invite.targetId === getUser()?.['Custom:User:Id']

	const { data: group, refresh } = useRelations(
		RELATIONS.ORGANIZATION,
		organization?.ContentId && user?.ContentId ? organization?.ContentId : '',
		organization?.ContentId && user?.ContentId ? user?.ContentId : '',
		'Customer',
	)
	const inGroup = (group as unknown as Relation)?.relation

	const inviteToGroup = async (): Promise<void> => {
		try {
			await addRelation({
				relation: RELATIONS.GROUP_INVITE,
				type: 'Customer',
				sourceId: user?.ContentId || '',
				targetId: getUser()?.['Custom:User:Id'] || '',
			})
			refreshInvites()
		} catch (error) {
			console.log('error:', error)
		}
	}

	return (
		<>
			<Tooltip
				title={
					!inGroup && !invited
						? 'APP_SEND_INVITE_TO_JOIN_GROUP'
						: !inGroup && invited
						? 'APP_JOIN_GROUP_INVITE_SENT'
						: 'APP_REMOVE_USER_FROM_GROUP_ACTION'
				}
				placement="top">
				<div
					onClick={
						inGroup
							? () => removeFromGroup(refresh, organization?.ContentId)
							: invited
							? () => null
							: inviteToGroup
					}>
					{inGroup ? (
						<PlusIcon
							style={{ transform: 'rotate(45deg)' }}
							color="var(--theme-primary)"
						/>
					) : invited ? (
						<AddNetworkIcon color="var(--mono400)" />
					) : (
						<AddNetworkIcon color="var(--theme-primary)" />
					)}
				</div>
			</Tooltip>
		</>
	)
}
