import { InputHTMLAttributes } from 'react'
import cn from 'classnames'
import { PhoneInput as ReactPhoneInput } from 'react-international-phone'
import InputLabel from '../InputLabel'
import 'react-international-phone/style.css'
import inputStyle from '../Input/index.module.scss'
import s from './index.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	defaultValue: string
	label?: string
}

const PhoneInput = ({
	defaultValue,
	onBlur,
	label,
	name,
}: Props): JSX.Element => (
	<div style={{ display: 'flex', flexDirection: 'column' }}>
		<InputLabel label={label} />
		<ReactPhoneInput
			value={defaultValue}
			className={cn(inputStyle.container, s.container)}
			inputClassName={cn(s.input, 'body1-m')}
			onBlur={onBlur}
			name={name}
			inputProps={{
				autoComplete: 'new-password',
			}}
		/>
	</div>
)

export default PhoneInput
