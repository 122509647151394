import { defaultCountries, parseCountry } from 'react-international-phone'
import { createWithEqualityFn } from 'zustand/traditional'
import { AppStore } from 'types/store'

const useStore = createWithEqualityFn<AppStore>(
	(set) => ({
		countries: defaultCountries.map((country) => parseCountry(country)),
		endpoints: null,
		setEndpoints: (endpoints) => set({ endpoints }),
		locales: localStorage.getItem('Locales')
			? JSON.parse(localStorage.getItem('Locales') as string)
			: null,
		setLocales: (locales) => set({ locales }),
		stepWizard: null,
		setStepWizard: (wizard) => set({ stepWizard: wizard }),
		chatId: '',
		setChatId: (id) => set({ chatId: id }),
		showBackgroundBlur: false,
		setShowBackgroundBlur: (show) => set({ showBackgroundBlur: show }),
		openQRSidebar: false,
		setOpenQRSidebar: (open) =>
			set({
				openQRSidebar: open,
			}),
		roleInfoSidebar: '',
		setRoleInfoSidebar: (id) => set({ roleInfoSidebar: id }),
		prodInfoSidebar: '',
		setProdInfoSidebar: (id) => set({ prodInfoSidebar: id }),
		userInfoSidebar: '',
		setUserInfoSidebar: (id) => set({ userInfoSidebar: id }),
		localSearchSidebar: null,
		setLocalSearchSidebar: (search) =>
			set((state) => ({
				localSearchSidebar: state.localSearchSidebar
					? {
							...state.localSearchSidebar,
							...search,
					  }
					: (search as null),
			})),
		documentViewerSidebar: null,
		setDocumentViewerSidebar: (data) => set({ documentViewerSidebar: data }),
		openFeedbackSidebar: false,
		setOpenFeedbackSidebar: (open) => set({ openFeedbackSidebar: open }),
		addSceneSidebar: {
			open: false,
			openInstructions: false,
			setOpenInstructions: (open) =>
				set((state) => ({
					addSceneSidebar: {
						...state.addSceneSidebar,
						openInstructions: open,
					},
				})),
			scene: {
				title: '',
				duration: 0,
				attachments: [],
				code: '',
				dress: '',
				instructions: [],
			},
			setScene: (value) =>
				set((state) => ({
					addSceneSidebar: {
						...state.addSceneSidebar,
						scene: {
							...state.addSceneSidebar.scene,
							...value,
						},
					},
				})),
		},
		setOpenAddSceneSidebar: (open) =>
			set((state) => ({ addSceneSidebar: { ...state.addSceneSidebar, open } })),
	}),
	Object.is,
)

export default useStore
