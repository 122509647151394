import { useParams } from 'react-router-dom'
import ProfileLayout from 'components/ProfileLayout'
import useUser from 'hooks/api/useUser'
import s from './index.module.scss'

const Profile = (): JSX.Element | null => {
	const { userId } = useParams()
	const { user } = useUser(userId)

	return user ? (
		<div className={s.container}>
			<ProfileLayout user={user} />
		</div>
	) : null
}

export default Profile
