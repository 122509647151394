import cn from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { PlayIcon, PlusIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import Button from 'components/UI/Button'
import useShakaPlayer from 'hooks/useShakaPlayer'
import useTutorialContent from 'hooks/api/useTutorialContent'
import { useOutsideClick } from 'hooks'
import { capitalize, t } from 'helpers'
import s from './index.module.scss'
import { useAppStore } from 'store'

interface Props {
	step: string
}

const Tutorial = ({ step }: Props): JSX.Element | null => {
	const containerRef = useRef(null)
	const { videoData, content } = useTutorialContent(capitalize(step))
	const videoRef = useRef<HTMLVideoElement | null>(null)
	const [showTutorial, setShowTutorial] = useState(false)
	const { refreshInstance } = useShakaPlayer(
		showTutorial && videoData?.ContentUrl ? videoData?.ContentUrl : null,
		`tutorial-video_${step}`,
	)

	const openTutorial = (): void => {
		const { setShowBackgroundBlur } = useAppStore.getState()
		setShowBackgroundBlur(true)
		setShowTutorial(true)
	}

	const closeTutorial = (): void => {
		const { setShowBackgroundBlur } = useAppStore.getState()
		setShowBackgroundBlur(false)
		setShowTutorial(false)
		if (videoRef.current) {
			videoRef.current?.pause()
			videoRef.current.currentTime = 0
		}
	}

	useOutsideClick(containerRef, closeTutorial)

	const playChange = useCallback(() => {
		if (videoRef.current) {
			if (videoRef.current?.paused) {
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
	}, [])

	useEffect(() => {
		refreshInstance()
	}, [step, refreshInstance])

	useEffect(() => {
		if (showTutorial) {
			setTimeout(() => {
				videoRef?.current?.play()
			}, 300)
		}
	}, [showTutorial, playChange])

	return step?.includes('summary') ? null : (
		<Card
			className={cn(s.container, { [s.open]: showTutorial })}
			containerRef={containerRef}>
			<>
				{showTutorial ? null : (
					<img
						src={content?.ContentSquareImageUrl}
						alt="tutorial placeholder"
					/>
				)}
				<video
					ref={videoRef}
					controls
					id={`tutorial-video_${step}`}
					style={{
						display: showTutorial ? 'block' : 'none',
					}}
					className={s.video}
				/>
				<div
					className={cn(s.close, { [s.showClose]: showTutorial })}
					onClick={closeTutorial}>
					<PlusIcon
						width="48"
						height="48"
						style={{ transform: 'rotate(45deg)' }}
					/>
				</div>
				<div className={cn(s.description, { [s.hideDesc]: showTutorial })}>
					<div className={s.descContent}>
						<h5
							dangerouslySetInnerHTML={{
								__html: content?.['Globalizationen-USContentDescription'] || '',
							}}></h5>
						<Button
							variant="filled"
							onClick={openTutorial}
							className="body1-m"
							style={{ gap: '8px' }}>
							{t('APP_SHOW_BUTTON')}
							<PlayIcon />
						</Button>
					</div>
				</div>
			</>
		</Card>
	)
}

export default Tutorial
