export const MagnifyingGlassIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<g clipPath="url(#clip0_2228_44345)">
			<path
				d="M23.4375 20.8125C24.1406 21.5625 24.1406 22.7344 23.4375 23.4844C22.6875 24.1875 21.5156 24.1875 20.7656 23.4844L15.1875 17.8594C13.2656 19.125 10.9219 19.7812 8.39062 19.4062C4.07812 18.8438 0.609375 15.3281 0.046875 11.0625C-0.75 4.64062 4.59375 -0.703125 11.0156 0.09375C15.2812 0.65625 18.7969 4.125 19.4062 8.4375C19.7344 10.9688 19.0781 13.3125 17.8125 15.1875L23.4375 20.8125ZM13.5 10.875C14.1094 10.875 14.625 10.4062 14.625 9.75C14.625 9.14062 14.1094 8.625 13.4531 8.625H10.8281V6C10.8281 5.39062 10.2656 4.875 9.70312 4.875C9.09375 4.875 8.625 5.39062 8.625 6V8.625H5.95312C5.34375 8.625 4.875 9.14062 4.875 9.75C4.875 10.4062 5.34375 10.875 5.95312 10.875H8.625V13.5C8.625 14.1562 9.09375 14.625 9.75 14.625C10.3125 14.625 10.8281 14.1562 10.8281 13.5V10.875H13.5Z"
				fill={color}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2228_44345">
				<rect width="24" height="24" fill={color} />
			</clipPath>
		</defs>
	</svg>
)
