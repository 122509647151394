import dayjs from 'dayjs'
import cn from 'classnames'
import { CSSProperties } from 'react'
import {
	FemaleGenderIcon,
	InfoFilledIcon,
	InfoOutlinedIcon,
	MaleGenderIcon,
	MasksIcon,
	OtherGenderIcon,
	ShowreelsIcon,
} from 'assets/icons'
import Card from 'components/Wizard/Card'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useUser from 'hooks/api/useUser'
import { optionsParser, t } from 'helpers'
import { useAppStore } from 'store'
import { DROPDOWN_OPTIONS, RELATIONS } from 'types/enums'
import { Content } from 'types/app'
import s from './index.module.scss'

interface Props {
	content: Content | undefined
	onClick?: () => void
	children?: JSX.Element
	type?: 'aside' | 'card' | 'horizontal' | 'casting'
	descriptionLines?: number
	showInfo?: boolean
	showDescription?: boolean
	showProd?: boolean
	relatedUserId?: string
	hoverContainerStyle?: CSSProperties
	style?: CSSProperties
}

const RoleCard = ({
	content,
	onClick,
	children,
	type = 'card',
	showDescription,
	descriptionLines,
	showInfo = false,
	showProd,
	relatedUserId,
	hoverContainerStyle,
	style,
}: Props): JSX.Element => {
	const languageOptions = optionsParser(DROPDOWN_OPTIONS.LANGUAGES_CHECKBOX)
	const genderOptions = optionsParser(DROPDOWN_OPTIONS.GENDERS)

	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	const { user } = useUser(relatedUserId)

	const color = content?.ContentColor

	return (
		<Card
			className={cn(s.container, s[type], 'hoverWrapperParent', {
				cardWrapperSize: type === 'aside',
			})}
			onClick={!children ? onClick : () => null}
			style={{
				cursor: onClick ? 'pointer' : 'default',
				// background: `radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, ${color} 20%, transparent) 0%, color-mix(in srgb, ${color} 0%, transparent) 100%)`,
				background:
					type === 'casting'
						? ''
						: `radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, ${color} 75%, transparent) -50%, color-mix(in srgb, ${color} 0%, transparent) 100%)`,
				...style,
			}}>
			<>
				<div
					style={{
						zIndex: 1,
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: 0,
						left: 0,
						opacity: type === 'casting' ? '1' : '0.08',
						// background: `linear-gradient(0deg, ${color}, ${color})`,
						background:
							type === 'casting'
								? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.8) 100%)'
								: 'linear-gradient(0deg, rgb(108, 108, 112), rgb(108, 108, 112))',
					}}
				/>
				{children || showInfo ? (
					<div className="hoverWrapper">
						<div
							style={hoverContainerStyle}
							className={cn(
								'hoverContent',
								type === 'horizontal' ? 'hoverContentHorizontal' : '',
							)}>
							<>
								{content && showInfo ? (
									<div
										className={cn(
											'hoverButtonWrapper',
											type === 'horizontal' ? 'hoverButtonIcon' : '',
										)}
										onClick={() => {
											const { setRoleInfoSidebar } = useAppStore.getState()
											setRoleInfoSidebar(content?.ContentId)
										}}>
										<InfoFilledIcon />
										{type === 'horizontal' ? null : (
											<span>{t('APP_INFO')}</span>
										)}
									</div>
								) : null}
								{children}
							</>
						</div>
					</div>
				) : null}

				<div
					className={s.wrapper}
					style={
						type === 'horizontal'
							? {
									paddingLeft: '20px',
									paddingRight: '20px',
							  }
							: {}
					}>
					<div className={s.head} onClick={children ? onClick : () => null}>
						{relatedUserId && type !== 'casting' ? (
							<div
								className={s.avatar}
								style={{
									borderRadius: '50%',
								}}>
								<img
									src={
										user
											? user?.CustomerImageSquareUrl
											: '/picture-placeholder.png'
									}
									alt={user?.ContentTitle}
								/>
							</div>
						) : null}
						{type === 'horizontal' ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '4px',
									width: 'calc(100%)',
								}}>
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
									<span className="body1-b textEllipsis">
										{content?.['Globalizationen-USContentTitle']}
									</span>
									<Gender content={content} />
								</div>
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
									<MasksIcon color="var(--mono200)" />
									<span className="body2-m" style={{ color: 'var(--mono200)' }}>
										{content?.ContentRoleType}
									</span>
								</div>
								{showProd ? (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '5px',
										}}>
										<ShowreelsIcon color="var(--mono200)" />
										<span
											className="body2-m"
											style={{ color: 'var(--mono200)' }}>
											{prod?.['Globalizationen-USContentTitle']}
										</span>
									</div>
								) : null}
							</div>
						) : ['card'].includes(type) ? (
							<>
								{user ? (
									<span className="body1-b textEllipsis">
										{user?.ContentTitle}
									</span>
								) : null}
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
									<span className="body1-b textEllipsis">
										{content?.['Globalizationen-USContentTitle']}
									</span>
									<Gender content={content} />
								</div>
								<>
									{content?.ContentCastingStatus ? (
										<div
											style={{
												paddingTop: '12px',
												display: 'flex',
												flexDirection: 'column',
												gap: '4px',
											}}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '4px',
												}}>
												<InfoOutlinedIcon color="var(--mono200)" />
												<span
													className="body2-m"
													style={{ color: 'var(--mono200)' }}>
													{content?.ContentCastingStatus}
												</span>
											</div>
										</div>
									) : null}
									<div
										style={{
											paddingTop: content?.ContentCastingStatus ? '0' : '12px',
											display: 'flex',
											flexDirection: 'column',
											gap: '4px',
										}}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '4px',
											}}>
											<MasksIcon color="var(--mono200)" />
											<span
												className="body2-m"
												style={{ color: 'var(--mono200)' }}>
												{content?.ContentRoleType}
											</span>
										</div>
										{prod ? (
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '4px',
												}}>
												<ShowreelsIcon color="var(--mono200)" />
												<span
													className="body2-m"
													style={{ color: 'var(--mono200)' }}>
													{prod['Globalizationen-USContentTitle']}
												</span>
											</div>
										) : null}
									</div>
								</>
							</>
						) : type === 'casting' ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '4px',
								}}>
								<h3>{user?.ContentTitle}</h3>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: '8px',
										flexWrap: 'wrap',
									}}>
									{['casting', content?.ContentRoleType].map((tag) => (
										<div
											key={tag}
											style={{
												padding: '2px 8px',
												boxSizing: 'border-box',
												backgroundColor: 'rgba(255, 255, 255, .3)',
												borderRadius: '10px',
											}}>
											<span
												className="body2-b"
												style={{
													textTransform: 'uppercase',
													color: 'var(--mono100)',
												}}>
												{tag}
											</span>
										</div>
									))}
								</div>
								<span className="body1-b" style={{ color: 'var(--mono100)' }}>
									{prod?.['Globalizationen-USContentTitle']}
								</span>
								<span className="body1-r" style={{ color: 'var(--mono200)' }}>
									{content?.['Globalizationen-USContentTitle']}
								</span>
							</div>
						) : (
							<h2
								className="h2-m textEllipsis"
								style={{ color: 'var(--mono200)' }}>
								{content?.['Globalizationen-USContentTitle']}
							</h2>
						)}
					</div>

					{type === 'aside' || showDescription ? (
						<div
							style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
							<span className="body2-m">{t('APP_DESCRIPTION_LABEL')}</span>
							<h5
								className="textClamp"
								style={{
									WebkitLineClamp: descriptionLines || 5,
									color: 'var(--mono200)',
								}}>
								{content?.['Globalizationen-USContentDescription']}
							</h5>
						</div>
					) : null}

					{children || type === 'horizontal' ? null : (
						<div
							className="grid3column"
							style={{ gap: '8px', rowGap: '12px', marginTop: 'auto' }}>
							<div className={s.itemWrapper}>
								<span className="caption-m">{t('APP_ROLE_AGE_LABEL')}</span>
								<span className="body1-m">
									{content?.ContentRoleAge
										? content?.ContentRoleAge + ' yr'
										: 'N/A'}
								</span>
							</div>
							<div className={s.itemWrapper}>
								<span className="caption-m">{t('APP_LANGUAGE_LABEL')}</span>
								<span className="body1-m">
									{languageOptions?.find(
										(option) => option.value === content?.ContentRoleDialect,
									)?.label || 'N/A'}
								</span>
							</div>
							<div className={s.itemWrapper}>
								<span className="caption-m">{t('APP_GENDER_LABEL')}</span>
								<span className="body1-m">
									{genderOptions?.find(
										(option) => option.value === content?.ContentRoleGender,
									)?.label || 'N/A'}
								</span>
							</div>
							<div className={s.itemWrapper}>
								<span className="caption-m">{t('APP_ROLE_SHOOTING_DAYS')}</span>
								<span className="body1-m">
									{content?.ContentShootingDays
										? content?.ContentShootingDays + ' day'
										: 'N/A'}
								</span>
							</div>
							<div className={s.itemWrapper}>
								<span className="caption-m">{t('APP_DEADLINE_LABEL')}</span>
								<span className="body1-m">
									{content?.ContentEventDeadline
										? dayjs(content?.ContentEventDeadline).format('MM/DD/YYYY')
										: 'N/A'}
								</span>
							</div>
						</div>
					)}
				</div>
				{type === 'casting' && relatedUserId ? (
					<img
						src={user?.CustomerImageSquareUrl || '/placeholder.png'}
						alt="casting role card background"
					/>
				) : null}
			</>
		</Card>
	)
}

export default RoleCard

const Gender = ({ content }: { content: Content | undefined }): JSX.Element => (
	<div style={{ display: 'flex' }}>
		{content?.ContentRoleGender === 'FEMALE' ? (
			<FemaleGenderIcon color="var(--agency)" />
		) : content?.ContentRoleGender === 'MALE' ? (
			<MaleGenderIcon color="var(--ice-blue)" />
		) : (
			<OtherGenderIcon />
		)}
	</div>
)
