import { ButtonHTMLAttributes, CSSProperties, useRef } from 'react'
import cn from 'classnames'
import { PlusIcon } from 'assets/icons'
import Loader from 'components/Loader'
import SidebarButton from './SidebarButton'
import { useOutsideClick } from 'hooks'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	open: boolean
	onClose: () => void
	children: JSX.Element
	header?: {
		title: string
		description?: string
	}
	style?: CSSProperties
	containerStyle?: CSSProperties
	iconClose?: boolean
	additional?: boolean
	button?: ButtonHTMLAttributes<HTMLButtonElement>
	disableBlur?: boolean
	loading?: boolean
}

const Sidebar = ({
	open,
	children,
	onClose,
	style,
	containerStyle,
	header,
	iconClose,
	additional,
	button,
	loading = false,
	disableBlur = false,
}: Props): JSX.Element => {
	const sidebarRef = useRef(null)

	useOutsideClick(
		sidebarRef,
		iconClose || !open
			? () => null
			: () => {
					const popup = document.getElementById('select-popup')
					if (!popup) onClose()
			  },
	)

	return (
		<>
			{open && !disableBlur ? <div className={s.blur} /> : null}
			<div
				className={cn(s.container, 'hideScrollBar', {
					[s.open]: open && !additional,
					[s.openAdditional]: open && additional,
					[s.additional]: additional,
				})}
				ref={sidebarRef}
				style={containerStyle}>
				<div
					className={s.wrapper}
					style={{
						height: button ? '100%' : 'unset',
						paddingBottom: button ? '0px' : '34px',
						...style,
					}}>
					<Loader loading={loading} adapt />
					<>
						{header ? (
							<div className={s.header}>
								<div className={s.titleWrapper}>
									<h3 className="h3-b">{t(header?.title)}</h3>
									<div onClick={onClose}>
										<PlusIcon
											width="30px"
											height="30px"
											color="var(--mono400)"
											style={{ transform: 'rotate(45deg)' }}
										/>
									</div>
								</div>
								{header?.description ? (
									<p className="caption-m" style={{ color: 'var(--mono300)' }}>
										{t(header.description)}
									</p>
								) : null}
							</div>
						) : iconClose ? (
							<div className={s.close}>
								<div onClick={onClose}>
									<PlusIcon
										width="30px"
										height="30px"
										color="var(--mono400)"
										style={{ transform: 'rotate(45deg)' }}
									/>
								</div>
							</div>
						) : null}
					</>
					{children}
					{button ? <SidebarButton {...button} /> : null}
				</div>
			</div>
		</>
	)
}

export default Sidebar
