import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { FetchReturn, RelationListResponse, Relation } from 'types/app'
import { RELATIONS } from 'types/enums'
import { useAppStore } from 'store'
import { KeyedMutator } from 'swr'

type ReturnData = FetchReturn & {
	data: Relation[] | RelationListResponse | undefined
	refresh: KeyedMutator<RelationListResponse>
}

export default (
	relation: RELATIONS,
	targetId: string,
	sourceId: string,
	type = 'Content',
): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const RelationType: 'single' | 'list' = sourceId ? 'single' : 'list'
	let relationUrl = endpoints?.CustomApplicationV3ConnectionPostUrl

	if (relation && targetId && sourceId)
		relationUrl += '/' + sourceId + '/' + type + '/' + relation + '/' + targetId
	else if (relation && targetId)
		relationUrl += '/' + targetId + '/' + type + '/' + relation
	else relationUrl = ''

	const { data, isLoading, error, mutate } =
		useSwrImmutable<RelationListResponse>(
			relationUrl ? relationUrl : null,
			fetcher,
			{
				shouldRetryOnError: false,
			},
		)

	return {
		data: data
			? RelationType === 'single'
				? data
				: data?.pageContent?.length
				? data.pageContent
				: undefined
			: undefined,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		refresh: mutate,
	}
}
