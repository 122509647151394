import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import Sidebar from 'components/Sidebar'
import Loader from 'components/Loader'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Message from './components/Message'
import Details from './components/Details'
import useContent from 'hooks/api/useContent'
import { useModalStore } from 'store'
import { SIDEBARS } from 'types/enums'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'

const tabs = [
	{
		label: 'APP_MESSAGE_TAB',
		value: 0,
	},
	{
		label: 'APP_DETAILS_TAB',
		value: 1,
	},
]

const AcceptSidebar = (): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()
	const [available, setAvailable] = useState(false)
	const [approve, setApprove] = useState(false)
	const [activeTab, setActiveTab] = useState(tabs[0].value)
	const [modalOpened] = useModalStore(useShallow((state) => [!!state.open]))
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const { search } = useLocation()
	const openSidebar =
		new URLSearchParams(search).get('sidebar') ===
		SIDEBARS.ACCEPT_CASTING_INVITE
	const acceptId = new URLSearchParams(search).get('contentId')
	const { content } = useContent(acceptId)

	const onClose = (): void => {
		navigate(window.location.pathname + window.location.hash)
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={onClose}
			iconClose={modalOpened}
			header={{
				title: 'APP_ACCEPT_ROLE_SIDEBAR_TITLE',
				description: 'APP_ACCEPT_ROLE_SIDEBAR_DESCRIPTION',
			}}
			button={
				activeTab === 1
					? {
							children: t('APP_ACCEPT'),
							disabled: !approve || !available,
							onClick: () => EventBus.$emit('acceptActorInvite'),
					  }
					: undefined
			}
			containerStyle={loading ? { height: 'unset' } : {}}>
			<>
				<Loader adapt loading={loading} />
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '20px',
						height: '100%',
					}}>
					<SidebarTabs
						active={activeTab}
						options={tabs}
						onChange={(v) => {
							setActiveTab(v as number)
							swiperRef.current?.slideTo(v as number)
						}}
					/>
					<Swiper
						onSwiper={(swiper: SwiperClass) => {
							swiperRef.current = swiper
						}}
						allowTouchMove={false}
						style={{ width: '100%', height: '100%' }}>
						<SwiperSlide>
							<Message content={content} />
						</SwiperSlide>
						<SwiperSlide>
							<Details
								content={content}
								setLoading={setLoading}
								onClose={onClose}
								approve={approve}
								available={available}
								setApprove={setApprove}
								setAvailable={setAvailable}
							/>
						</SwiperSlide>
					</Swiper>
				</div>
			</>
		</Sidebar>
	)
}

export default AcceptSidebar
