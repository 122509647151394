import { useCallback, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useLocation, useNavigate } from 'react-router-dom'
import { StepWizardChildProps, StepWizardProps } from 'react-step-wizard'
import cn from 'classnames'
import { ArrowLongIcon } from 'assets/icons'
import StepContent from './components/StepContent'
import Button from 'components/UI/Button'
import Tutorial from './components/Tutorial'
import { profileSteps } from './components/StepContent/StepContent'
import { t } from 'helpers'
import { useAppStore, usePortfolioStore, useUserStore } from 'store'
import {
	basicFields,
	biographyFields,
	contactFields,
	personalFields,
} from './fields'
import { User } from 'types/user'
import s from './index.module.scss'

const Portfolio = (): JSX.Element => {
	const { hash } = useLocation()
	const navigate = useNavigate()
	const [storeLoading, setStoreLoading] = useState(true)
	const profileWizard = useAppStore(useShallow((state) => state.stepWizard))

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					window.location.search +
					'#' +
					profileSteps?.basic?.key +
					'-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	const nextStep = (): void => {
		profileWizard?.nextStep()
	}

	const prevStep = (): void => {
		profileWizard?.previousStep()
	}

	// const onStepChange = (tab: string): void => {
	// 	profileWizard?.goToNamedStep(tab)
	// }

	const onSubmitStep = (): void => {
		// const submitButton = document.getElementById(`next-${hash?.slice(1)}`)
		// if (submitButton) submitButton.click()
		// else nextStep()
		nextStep()
	}

	const refreshUserData = useCallback(async () => {
		const { refreshUser } = useUserStore.getState()
		const user = await refreshUser()

		const { setCardValues, cardValues } = usePortfolioStore.getState()

		if (user) {
			const steps = [
				{ key: 'basic', fields: basicFields },
				{ key: 'contact', fields: contactFields },
				{ key: 'personal', fields: personalFields },
				{ key: 'biography', fields: biographyFields },
			]
			steps.forEach((step) => {
				setCardValues(
					{
						...Object.values(step.fields)
							?.map((field) => ({
								[field.name]:
									(user?.[
										field.name?.replaceAll(':', '') as keyof User
									] as string) || '',
							}))
							.reduce(
								(prev, next) => ({
									...prev,
									...next,
								}),
								{},
							),
					},
					step.key,
				)
			})

			if (user && !cardValues['Custom:User:BirthDate']) {
				setCardValues(
					{
						'Custom:User:BirthDate': user?.CustomUserBirthDate || '',
					},
					'basic',
				)
			}
		}

		setStoreLoading(false)
	}, [])

	useEffect(() => {
		refreshUserData()
	}, [refreshUserData])

	return (
		<div className={s.container}>
			<div className={cn(s.wrapper, 'flex')}>
				{storeLoading ? null : (
					<StepContent assignStepWizard={assignStepWizard} />
				)}
				<Tutorial step={hash?.slice(1)} />
			</div>
			<div className={s.buttonsWrapper}>
				<Button
					onClick={prevStep}
					style={
						profileWizard?.currentStep === 1
							? {
									opacity: 0,
									visibility: 'hidden',
							  }
							: {}
					}>
					{t('APP_BACK_BUTTON')}
				</Button>
				<Button
					variant="themed"
					className={s.next}
					onClick={onSubmitStep}
					style={
						profileWizard?.currentStep === profileWizard?.totalSteps
							? {
									opacity: 0,
									visibility: 'hidden',
							  }
							: {}
					}>
					{t('APP_NEXT_BUTTON')}
					<ArrowLongIcon color="var(--theme-primary)" />
				</Button>
			</div>
		</div>
	)
}

export default Portfolio
