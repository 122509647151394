import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import Select from 'components/UI/Select'
import { optionsParser, t } from 'helpers'
import { usePortfolioStore } from 'store'
import { personalFields } from '../../fields'

const Personal = (): JSX.Element => {
	const values = usePortfolioStore(
		useShallow((state) => state.cardValues['personal']),
	)

	return (
		<Card step="personal">
			<div className="grid2column">
				{Object.values(personalFields)?.map((field) => (
					<Select
						key={field.name}
						options={optionsParser(t(field.optionName || ''))}
						name={field.name}
						label={field.label}
						onChange={(e) => {
							const { setCardValues } = usePortfolioStore.getState()
							setCardValues(
								{
									[field.name]: e.target.value,
								},
								'personal',
							)
							const { updateFields } = usePortfolioStore.getState()
							updateFields([
								{
									Name: field.name,
									Value: e.target.value,
								},
							])
						}}
						inputProps={{
							value: (values[field.name] as string) || '',
						}}
					/>
				))}
			</div>
		</Card>
	)
}

export default Personal
