/* eslint-disable no-tabs */
import { fetcher } from 'api'
import { useAppStore } from 'store'
import { RelationListResponse } from 'types/app'
import { ChatConnectingData, ChatCreated, Message } from 'types/chat'

export const NOTIFICATIONS_DETAILS = [
	{
		description: '',
		title: 'No',
		options: [],
		value: 'off',
	},
	{
		description: 'Notify my followers',
		value: 'followed',
		title: 'Basic',
		options: [],
	},
	{
		description: 'Notify my invited members',
		title: 'Invite only',
		value: 'selected',
		options: [],
	},
]

export const CHAT_MODERATIONS = [
	{
		description: '',
		title: 'Auto',
		value: 'auto',
		options: [],
	},
	{
		title: 'Assign to someone',
		value: 'assign_to_someone',
		description: '',
		options: [],
	},
	{
		title: 'Select one',
		value: 'select_one',
		description: '',
		options: [],
	},
]

export const VISIBILITY = [
	{
		title: 'Public',
		value: 'public',
		description: '',
		options: [],
	},
	{
		title: 'Private',
		value: 'personal',
		description: '',
		options: [],
	},
]

export const PUBLISH_RULE = [
	{
		title: 'Now',
		value: 'intime',
		description: '',
		options: [],
	},
	{
		title: 'Later',
		value: 'upcoming',
		description: '',
		options: [],
	},
]

export const CHAT_INPUT_INIT_STATE = {
	notification: NOTIFICATIONS_DETAILS[1].value,
	publishingRule: PUBLISH_RULE[0].value,
	moderation: CHAT_MODERATIONS[0].value,
	visibility: VISIBILITY[1].value,
	shortinfo: '',
	type: 'voice',
	accessFee: 0,
	reward: '',
	name: '',
}

export const createIVSChatRoom = (
	inputInformation: any,
): Promise<ChatCreated> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.ContentApplicationV3ChatUrl
	if (!url) {
		throw new Error('URL not found')
	}

	const payload = {
		localizations: {
			'en-US': {
				shortInfo: inputInformation?.shortinfo?.trim(),
				name: inputInformation?.name?.trim(),
			},
		},
		originalTitle: inputInformation?.name?.trim(),
		// publishingRule: inputInformation.publishingRule,
		// notification: inputInformation.notification,
		// visibility: inputInformation.visibility,
		// properties: inputInformation.properties,
		// type: inputInformation.type,
		originalLanguage: 'en-US',
		published: true,
		...inputInformation,
		// allowEmailNotification: true,
		// exhibitionWindow: {},
	}

	// if (selectedPublishTime) {
	// 	const availableUntil = new Date(selectedPublishTime).setFullYear(
	// 		new Date(selectedPublishTime).getFullYear() + 10,
	// 	)
	// 	payload.exhibitionWindow = {
	// 		'en-US': {
	// 			availableUntil: new Date(availableUntil).toJSON(),
	// 			availableFrom: selectedPublishTime,
	// 			ageLimit: 99,
	// 		},
	// 	}
	// }

	return fetcher(`${url}/Create/`, {
		method: 'post',
		body: JSON.stringify(payload),
	})
}

export const createVoiceChat = (
	inputInformation: any,
): Promise<ChatCreated> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationV3RecordingUrl
	if (!url) {
		throw new Error('URL not found')
	}

	const payload = {
		localizations: {
			'en-US': {
				shortInfo: inputInformation.shortinfo.trim(),
				name: inputInformation.name.trim(),
			},
		},
		originalTitle: inputInformation.name.trim(),
		...inputInformation,
		// publishingRule: inputInformation.publishingRule,
		// notification: inputInformation.notification,
		// visibility: inputInformation.visibility,
		// allowChat: inputInformation.allowChat,
		// releaseDate: inputInformation?.releaseDate,
		// duration: inputInformation?.duration,
		// assets: inputInformation.assets,
		originalLanguage: 'en-US',
		// allowEmailNotification: true,
		published: true,
		// exhibitionWindow: {},
		type: 'LiveStream',
		properties: {},
	}

	if (inputInformation.properties) {
		payload.properties = { ...inputInformation.properties }
	}

	return fetcher(`${url}`, {
		method: 'post',
		body: JSON.stringify(payload),
	})
}

export const setReferenceToContent = async (
	relation: 'Reference' | 'Creator' | 'Customer' | 'Playlist',
	mainContentId: string,
	targetId: string,
): Promise<ResponseType> => {
	const url = 'https://nup.theatroo.ent360.blue/api/v3.0'
	if (!url) {
		throw new Error('URL not found')
	}

	const payload = {
		sourceId: mainContentId,
		targetId: targetId,
		relation: relation,
		type: 'Content',
	}

	return fetcher(url, {
		method: 'post',
		body: JSON.stringify(payload),
	})
}

export const connectIVSChatRoom = (
	activeRoomID: string,
	data: ChatConnectingData,
): Promise<any> => {
	const { endpoints } = useAppStore.getState()
	const url = `${endpoints?.ContentApplicationV3ChatUrl}/${activeRoomID}/Connect`

	return fetcher(url, { method: 'post', body: JSON.stringify(data) })
}

export const getDiscussion = (
	targetType: string,
	targetId: string,
	limit = 100,
): Promise<RelationListResponse<Message[]>> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationV3DiscussionUrl.replace(
		'${targetType}',
		targetType,
	).replace('${targetId}', targetId)

	if (!url) {
		throw new Error('URL not found')
	}

	return fetcher(`${url}?descending=true&allowed=true&itemLimit=${limit}`, {
		method: 'get',
	})
}

export const sendChatMessage = (
	contentId: string,
	body: string,
	title: string,
): Promise<any> => {
	const { endpoints } = useAppStore.getState()
	const url = `${endpoints?.ContentApplicationV3ChatUrl}/${contentId}/Message`

	const data = {
		message: {
			type: 'text',
			body: body,
			title: title,
			visibility: 'private',
		},
	}

	return fetcher(url, { method: 'post', body: JSON.stringify(data) })
}
