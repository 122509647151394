import { ChangeEvent, useMemo, useRef, useState } from 'react'
import { ArrowShortIcon } from 'assets/icons'
import { useOutsideClick } from 'hooks'
import { SelectOption } from 'types/app'
import s from './index.module.scss'

interface Props {
	error?: string
	options: SelectOption[]
	placeholder?: string
	value: string
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
	sort?: 'abc' | 'none'
}

const Dropdown = ({
	options,
	value,
	onChange,
	sort,
	placeholder,
}: Props): JSX.Element => {
	const [showOptions, setShowOptions] = useState(false)
	const selectRef = useRef<HTMLDivElement>(null)
	const position = useRef<null | {
		width: string
		top: string
		left: string
	}>(null)

	useOutsideClick(selectRef, () => {
		setShowOptions(false)
		position.current = null
	})

	const inputValue = useMemo(() => {
		const option = options?.find((o) => o.value === value)

		return option?.label || value
	}, [options, value])

	const calculateOptionsPosition = (): void => {
		if (selectRef.current) {
			const { offsetTop, offsetLeft, offsetWidth, offsetHeight } =
				selectRef.current
			let top = offsetTop
			const wrapper = document.getElementById('modal-content-fields')
			const wrapperScroll = wrapper?.scrollTop || 0

			if (top - wrapperScroll + 300 > window.innerHeight)
				top -= 200 - offsetHeight

			const calculated = {
				width: 200 + 'px',
				left: offsetLeft + offsetWidth - 200 + 'px',
				top: top - wrapperScroll + 'px',
			}

			position.current = calculated
		}
	}

	return (
		<div className={s.container} ref={selectRef}>
			<div
				className={s.fieldWrapper}
				onClick={() => {
					if (!showOptions) calculateOptionsPosition()
					setShowOptions((prev) => !prev)
				}}>
				<span
					className="textEllipsis body1-m"
					style={{
						color: inputValue ? 'var(--mono100)' : 'var(--mono300)',
					}}>
					{inputValue || placeholder}
				</span>
				<div className={s.arrow}>
					<ArrowShortIcon
						color={inputValue ? 'var(--mono100)' : 'var(--mono300)'}
						style={{
							transform: showOptions ? 'rotate(-90deg)' : 'rotate(90deg)',
						}}
					/>
				</div>
			</div>
			{showOptions ? (
				<div className={s.optionsWrapper}>
					<div
						className={s.options}
						style={
							position?.current ? position.current : { position: 'absolute' }
						}>
						{options
							.sort(
								sort === 'abc'
									? (a, b) => a.label.localeCompare(b.label)
									: undefined,
							)
							?.map((option) => (
								<div
									key={option.value}
									className={s.option}
									onClick={() => {
										onChange({
											target: {
												name: option.label,
												value: option.value,
											},
										} as ChangeEvent<HTMLInputElement>)
										setShowOptions(false)
									}}>
									<div className={s.labelWrapper}>
										<span className="body2-m textEllipsis">{option.label}</span>
										{option?.color ? (
											<div
												className={s.color}
												style={{ backgroundColor: option.color }}
											/>
										) : null}
									</div>
									{option?.hint ? (
										<span className={s.hint}>{option.hint}</span>
									) : null}
								</div>
							))}
					</div>
				</div>
			) : null}
		</div>
	)
}

export default Dropdown
