import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react'
import { FileIcon } from 'assets/icons'
import { contentAuthorize } from 'api/auth'
import useShakaPlayer from 'hooks/useShakaPlayer'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	src: { url: string; local: boolean }
	setSrc: Dispatch<SetStateAction<{ url: string; local: boolean }>>
	file: File | string | undefined | null
	label?: string
	name: string
	fileType: 'Image' | 'Video'
	update?: boolean
}

const FileInputLayout = ({
	src,
	setSrc,
	file,
	label,
	fileType,
	update,
	name,
}: Props): JSX.Element => {
	const [videoError, setVideoError] = useState(false)
	useShakaPlayer(
		fileType === 'Video' && src.url ? src?.url : null,
		'video_' + name,
	)

	const fetchVideoUrl = useCallback(
		async (file: string): Promise<void> => {
			try {
				const id = file?.startsWith('http') ? file?.split('/')?.[3] : file
				const response = await contentAuthorize(id)
				if (response?.Properties) {
					setSrc({
						url:
							response.Properties.find((value) => value.Name === 'Content:Url')
								?.Value || '',
						local: false,
					})
				} else {
					setVideoError(true)
				}
			} catch (error) {
				console.log('error:', error)
			}
		},
		[setSrc],
	)

	useEffect(() => {
		if (file && !src?.url) {
			if (fileType === 'Image')
				setSrc({
					url: typeof file === 'string' ? file : URL.createObjectURL(file),
					local: true,
				})
			else {
				if (typeof file === 'string') fetchVideoUrl(file)
				else setSrc({ url: URL.createObjectURL(file), local: true })
			}
		}
	}, [file, src?.url, fileType, setSrc, fetchVideoUrl])

	return (
		<>
			{!file ? (
				<>
					<div className={s.content}>
						<div>
							<FileIcon />
						</div>
						<span>{t(label || '')}</span>
					</div>
					<img src="/photo-placeholder.png" className={s.image} />
				</>
			) : file && update ? (
				<div className={s.content}>
					<div>
						<FileIcon />
					</div>
					<span>{t(label || '')}</span>
				</div>
			) : null}
			{fileType === 'Video' ? (
				<video
					id={'video_' + name}
					controls
					style={!src?.local && src?.url ? {} : { display: 'none' }}
				/>
			) : null}
			{src?.url && !(fileType === 'Video' && !src?.local) ? (
				<>
					<div className={s.content}>
						{fileType === 'Video' ? <span>{t('APP_VIDEO_UPLOAD')}</span> : null}
					</div>
					<img
						className={s.image}
						src={
							fileType === 'Video' && src?.local
								? '/video-placeholder.png'
								: src.url?.startsWith('http') || src?.local
								? src.url
								: '/placeholder.png'
						}
					/>
				</>
			) : null}
			{videoError && !src?.url ? (
				<div className={s.content}>
					<span
						className="body1-m"
						style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
						}}>
						Something went wrong
					</span>
				</div>
			) : null}
		</>
	)
}

export default FileInputLayout
