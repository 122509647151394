import { SwiperSlide } from 'swiper/react'
import RecentCarousel from 'components/RecentCarousel'
import NetworkCard from 'components/NetworkCard'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { t } from 'helpers'

interface Props {
	agencyId: string
	setAgencyId: (agencyId: string) => void
}

const AgencyCarousel = ({ agencyId, setAgencyId }: Props): JSX.Element => {
	const { data = [] } = useCustomerSearch({
		fetch: true,
		filters: [
			{
				propName: 'Customer:Role',
				propValue: 'Agency',
			},
		],
	})

	return (
		<>
			<span className="body1-b">{t('APP_AGENCY_LIST')}</span>
			<RecentCarousel name="">
				<>
					{data?.map((user) => (
						<SwiperSlide key={user?.ContentId}>
							<NetworkCard
								variant="small"
								data={user}
								onClick={() => {
									setAgencyId(
										user?.CustomerPoolDefault === agencyId
											? ''
											: user?.CustomerPoolDefault || '',
									)
								}}
								style={{
									border: `2px solid ${
										agencyId === user?.CustomerPoolDefault
											? 'var(--theme-primary)'
											: 'transparent'
									}`,
								}}
							/>
						</SwiperSlide>
					))}
				</>
			</RecentCarousel>
		</>
	)
}

export default AgencyCarousel
