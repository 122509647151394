import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { addRelation, deleteRelation } from 'api/relations'
import Sidebar from 'components/Sidebar'
import Button from 'components/UI/Button'
import SidebarButton from 'components/Sidebar/SidebarButton'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useUser from 'hooks/api/useUser'
import { getUser } from 'helpers/storage'
import { EventBus } from 'helpers/EventBus'
import { t } from 'helpers'
import { useModalStore, useNetworkStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'

const AcceptInviteToPoolSidebar = (): JSX.Element => {
	const [loading, setLoading] = useState(false)
	const [inviteUserId, setOpenSidebar] = useNetworkStore(
		useShallow((state) => [
			state.acceptInviteToPoolSidebar,
			state.setAcceptInviteToPoolSidebar,
		]),
	)

	const { user } = useUser(inviteUserId)

	const { data: orgs } = useRelationsContent(
		inviteUserId ? [RELATIONS.ORGANIZATION] : [],
		inviteUserId,
	)
	const organization = orgs ? orgs?.[RELATIONS.ORGANIZATION]?.[0] : undefined

	const acceptInvite = async (): Promise<void> => {
		try {
			setLoading(true)
			await addRelation({
				relation: RELATIONS.ORGANIZATION,
				type: 'Customer',
				sourceId: getUser()?.['Custom:User:Id'] || '',
				targetId: organization?.ContentId || '',
			})
			await deleteRelation(
				getUser()?.['Custom:User:Id'] || '',
				'Customer',
				RELATIONS.GROUP_INVITE,
				inviteUserId,
			)
			EventBus.$emit('refreshInvitesToPool')
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			setOpenSidebar('')
		}
	}

	const rejectInvite = async (): Promise<void> => {
		try {
			setLoading(true)
			await deleteRelation(
				getUser()?.['Custom:User:Id'] || '',
				'Customer',
				RELATIONS.GROUP_INVITE,
				inviteUserId,
			)
			EventBus.$emit('refreshInvitesToPool')
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			setOpenSidebar('')
		}
	}

	return (
		<Sidebar
			open={!!inviteUserId}
			onClose={() => setOpenSidebar('')}
			header={{
				title: 'APP_INVITE_TO_POOL_SIDEBAR_TITLE',
				description: 'APP_INVITE_TO_POOL_SIDEBAR_DESCRIPTION',
			}}
			loading={loading}
			button={{
				children: t('APP_ACCEPT'),
				onClick: () => {
					const { user } = useUserStore.getState()
					const { setOpen } = useModalStore.getState()
					setOpen({
						show: true,
						children: (
							<div
								className="card"
								style={{
									maxWidth: '500px',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									gap: '20px',
								}}>
								<>
									<h4 className="h4-m">{t('APP_ACCEPT_AGENCY_DATA_POLICY')}</h4>
									<span>
										{t('APP_AGENCY_DATA_POLICY')?.replace(
											'[Your Name]',
											user?.ContentTitle || '',
										)}
									</span>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '10px',
										}}>
										<Button
											style={{ width: '100%' }}
											variant="themed"
											onClick={() => {
												acceptInvite()
												setOpen(null)
											}}>
											{t('APP_AUTHORIZE')}
										</Button>
										<Button
											style={{ width: '100%' }}
											onClick={() => {
												setOpen(null)
											}}>
											{t('APP_IGNORE')}
										</Button>
									</div>
								</>
							</div>
						),
					})
				},
				style: {
					marginTop: '0',
				},
			}}>
			<>
				<div
					style={{
						width: '150px',
						height: '150px',
						borderRadius: '50%',
						overflow: 'hidden',
					}}>
					<img
						style={{ width: '100%', height: '100%', objectFit: 'cover' }}
						src={user?.CustomerImageSquareUrl}
						alt={user?.ContentTitle}
					/>
				</div>
				<h2>{user?.ContentTitle}</h2>
				<span className="body1-b">{user?.CustomUserEmail}</span>
				<span className="body1-b">{user?.CustomGeoLocation}</span>
				<SidebarButton
					children={t('APP_REJECT')}
					style={{ marginTop: 'auto' }}
					onClick={rejectInvite}
				/>
			</>
		</Sidebar>
	)
}

export default AcceptInviteToPoolSidebar
