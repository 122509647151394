import dayjs from 'dayjs'
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ArrowShortIcon, DownloadIcon } from 'assets/icons'
import { upload } from 'api/aws'
import { addRelation } from 'api/relations'
import Button from 'components/UI/Button'
import CheckboxButton from 'components/UI/CheckboxButton'
import DragDrop from 'components/UI/DragDrop'
import Card from 'components/Wizard/Card'
import SidebarButton from 'components/Sidebar/SidebarButton'
import useContentFullRelation from 'hooks/api/useContentFullRelation'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { optionsParser, t, textParser } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { getUser } from 'helpers/storage'
import { useModalStore, useProductionStore } from 'store'
import { Content } from 'types/app'
import { CASTING_STATUS, DROPDOWN_OPTIONS, RELATIONS } from 'types/enums'
import { updateUser } from 'api/user'

interface Props {
	content: Content | undefined
	setLoading: Dispatch<SetStateAction<boolean>>
	onClose: () => void
	available: boolean
	approve: boolean
	setAvailable: Dispatch<SetStateAction<boolean>>
	setApprove: Dispatch<SetStateAction<boolean>>
}

const Details = ({
	content,
	available,
	approve,
	setApprove,
	setAvailable,
	setLoading,
	onClose,
}: Props): JSX.Element => {
	const [file, setFile] = useState<File | null>(null)
	const [showNdaDesc, setShowNdaDesc] = useState(false)

	const { search } = useLocation()
	const acceptId = new URLSearchParams(search).get('contentId')

	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	const ndaRequired = prod?.ContentLegalNDARequired === 'true'

	const { contents } = useContentFullRelation(
		ndaRequired ? prod?.ContentId : undefined,
		RELATIONS.PLAYLIST,
		true,
	)

	const production = contents?.[0]

	const onAccept = useCallback(async (): Promise<void> => {
		if (ndaRequired && !file) {
			toast.error(t('APP_UPLOAD_NDA_ERROR'))

			return
		}
		try {
			setLoading(true)
			if (ndaRequired && file) {
				const { createAsset } = useProductionStore.getState()
				if (acceptId && file) {
					const uploadedFile = await upload({
						fileObj: file,
						assetType: 'Content',
					})
					await createAsset(
						uploadedFile,
						`NDA - ${content?.['Globalizationen-USContentTitle']} - ${file.name}`,
						uploadedFile.originalFileName,
						uploadedFile.fileAssetUrl,
						uploadedFile.cdnUrl,
						acceptId,
					)
				}
			}

			await addRelation({
				sourceId: acceptId || '',
				targetId: getUser()?.['Custom:User:Id'] || '',
				type: 'Content',
				relation: RELATIONS.ACCESS,
				status: ndaRequired ? CASTING_STATUS.WAITING : CASTING_STATUS.READY,
			})
			await updateUser(getUser()?.['Custom:User:Id'] || '', [
				{
					Name: 'Customer:Process:Status',
					Value: 'Selected',
				},
			])
			onClose()
			EventBus.$emit('refreshActorBoard')
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
		}
	}, [acceptId, content, file, ndaRequired, onClose, setLoading])

	const onReject = async (): Promise<void> => {
		await addRelation({
			sourceId: acceptId || '',
			targetId: getUser()?.['Custom:User:Id'] || '',
			type: 'Content',
			relation: RELATIONS.ACCESS,
			status: CASTING_STATUS.REJECTED,
		})
		onClose()
		EventBus.$emit('refreshActorBoard')
	}

	const languageOptions = optionsParser(DROPDOWN_OPTIONS.LANGUAGES_CHECKBOX)
	const genderOptions = optionsParser(DROPDOWN_OPTIONS.GENDERS)
	const filmingDate = production?.ContentEventFilmingDate?.includes('[')
		? (JSON.parse(production?.ContentEventFilmingDate) as string[])
		: ''

	useEffect(() => {
		EventBus.$on('acceptActorInvite', () => {
			onAccept()
		})

		return () => {
			EventBus.$off('acceptActorInvite')
		}
	}, [onAccept])

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
				marginBottom: '-20px',
				height: '100%',
			}}>
			<h4 className="h4-m">{content?.['Globalizationen-USContentTitle']}</h4>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '5px',
				}}>
				{content?.ContentRoleType ? (
					<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
						'APP_ROLE_TYPE_LABEL',
					)}: ${content?.ContentRoleType}`}</span>
				) : null}
				{content?.ContentRoleGender ? (
					<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
						'APP_GENDER_LABEL',
					)}: ${
						genderOptions.find(
							(option) => option.value === content?.ContentRoleGender,
						)?.label
					}`}</span>
				) : null}
				{content?.ContentRoleDialect ? (
					<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
						'APP_LANGUAGE_LABEL',
					)}: ${
						languageOptions.find(
							(option) => option.value === content?.ContentRoleDialect,
						)?.label
					}`}</span>
				) : null}
				{content?.ContentShootingDays ? (
					<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
						'APP_ROLE_SHOOTING_DAYS',
					)}: ${content?.ContentShootingDays} day`}</span>
				) : null}
				{content?.ContentShootingDays ? (
					<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
						'APP_FILMING_DATE_LABEL',
					)}: ${
						dayjs(filmingDate?.[0]).format('MM/DD/YYYY') +
						' - ' +
						dayjs(filmingDate?.[1]).format('MM/DD/YYYY')
					}`}</span>
				) : null}
			</div>

			{ndaRequired ? (
				<>
					{production &&
					production?.assets?.find((asset) => asset.publicUrl?.endsWith('pdf'))
						?.publicUrl ? (
						<Button
							variant="outlined"
							onClick={(e) => {
								if (
									(e.target as HTMLElement).tagName?.toLowerCase() === 'button'
								) {
									const a = (e.target as HTMLButtonElement)
										.children?.[1] as HTMLAnchorElement
									if (a) a.click()
								}
							}}
							style={{
								gap: '10px',
								borderColor: 'var(--theme-primary)',
							}}>
							<div style={{ display: 'flex' }}>
								<DownloadIcon />
							</div>
							<a
								className="body1-b"
								href={
									production?.assets?.find((asset) =>
										asset.publicUrl?.endsWith('pdf'),
									)?.publicUrl
								}
								target="_blank"
								download
								style={{
									textDecoration: 'none',
									textTransform: 'none',
									color: 'var(--theme-primary)',
								}}>
								{t('APP_DOWNLOAD_NDA')}
							</a>
						</Button>
					) : null}
					<div
						style={{
							display: 'flex',
							gap: '5px',
							height: showNdaDesc ? 'auto' : '55px',
						}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								overflow: 'hidden',
								gap: '5px',
							}}>
							{textParser(t('APP_NDA_EXPLANATION'))?.map((text) => (
								<span
									className="body2-m"
									style={{ color: 'var(--mono300)' }}
									key={text}>
									{text}
								</span>
							))}
						</div>
						<div onClick={() => setShowNdaDesc((prev) => !prev)}>
							<ArrowShortIcon
								style={{
									cursor: 'pointer',
									transform: showNdaDesc ? 'rotate(-90deg)' : 'rotate(90deg)',
								}}
							/>
						</div>
					</div>

					<DragDrop
						hint="APP_DRAG_&_DROP_ROLE_NDA"
						name="nda-attachments"
						accept=".doc, .docx, application/pdf"
						layoutStyle={{
							padding: '50px 20px',
							textAlign: 'center',
						}}
						style={{
							padding: '50px 20px',
						}}
						onChange={(e) => {
							if (e.target.files?.length) {
								setFile(e.target.files[0])
							}
						}}
					/>
					<span>{file?.name}</span>
				</>
			) : null}

			<CheckboxButton
				checked={available}
				onChange={() => setAvailable((prev) => !prev)}
				label="APP_SHOOTINGDAYS_ACCEPT_LABEL"
			/>
			<CheckboxButton
				checked={approve}
				onChange={() => setApprove((prev) => !prev)}
				label="APP_PERSONALDATA_ACCEPT_LABEL"
			/>

			<div
				style={{ display: 'flex', flexDirection: 'column', marginTop: 'auto' }}>
				<SidebarButton
					style={{
						borderTop: 'none',
					}}
					children={t('APP_REJECT')}
					onClick={() => {
						const { setOpen } = useModalStore.getState()
						setOpen({
							show: true,
							children: (
								<Card
									style={{
										maxWidth: '400px',
									}}>
									<>
										<h4 className="h4-m">{t('APP_CONFIRM_ROLE_REJECT')}</h4>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
											}}>
											<Button
												style={{ width: '100%' }}
												onClick={() => {
													onReject()
													setOpen(null)
												}}>
												{t('APP_YES')}
											</Button>
											<Button
												style={{ width: '100%' }}
												variant="themed"
												onClick={() => {
													setOpen(null)
												}}>
												{t('APP_CANCEL')}
											</Button>
										</div>
									</>
								</Card>
							),
						})
					}}
				/>
			</div>
		</div>
	)
}

export default Details
