export type UserActions = {
	SubscriptionRetrieveExternalIds: string
	SubscriptionUpdateExternalIds: string
	RelationshipCustomerLike: string
	RelationshipCustomerDislike: string
	RelationshipCustomerUpvote: string
	RelationshipCustomerDownvote: string
	RelationshipCustomerBookmark: string
	RelationshipCustomerPlaylist: string
	RelationshipCustomerPlayback: string
	RelationshipCustomerRating: string
	RelationshipCustomerReview: string
	RelationshipCustomerFullPlay: string
	RelationshipCustomerFollow: string
	RelationshipCustomerEvent: string
	RelationshipCustomerOrganization: string
	RelationshipCustomerContent: string
	RelationshipCustomerContest: string
	RelationshipCustomerFavContent: string
	RelationshipCustomerFavCustomer: string
	RelationshipCustomerFavCatalog: string
	RelationshipCustomerProject: string
	RelationshipCustomerBanDiscussion: string
	RelationshipCustomerPromos: string
	RelationshipCustomerHighlights: string
	RelationshipCustomerContracts: string
	RelationshipCustomerInvitedCreators: string
	RelationshipCustomerPermanentCreators: string
	RelationshipCustomerAwards: string
	RelationshipCustomerHistory: string
	RelationshipCustomerUnfinished: string
	RelationshipCustomerPosition: string
	RelationshipCustomerBestPerformance: string
	RelationshipCustomerImage: string
}
export type User = {
	Actions?: UserActions
	CustomUserId: string
	ContentCreatedDate: string
	CustomerSettingsTutorialLanguage: string
	ContentId: string
	CustomUserPhoneNumber: string
	CustomUserBiography: string
	ContentTitle: string
	CustomerSettingsApplicationLanguage: string
	CustomerSettingsTutorialAgent: string
	CustomerImageLandscapeUrl: string
	CustomerImagePortraitUrl: string
	CustomerImageSquareUrl: string
	CustomGeoLocation: string
	CustomUserAvatar: string
	CustomUserEmail: string
	CustomerPortfolioUpdated: string
	CustomUserGender: string
	CustomUserUserName: string
	CustomerPortfolioCreated: string
	CustomerPoolDefault: string
	CustomerChatRoom: string
	CustomerChatId: string
	CustomerProfileReadiness: string
	CustomerImdbUrl: string
	// CustomerStatSocialMessage30Day: string
	// CustomerLabelSku: string
	// CustomerLabelGenre: string
	CustomerGeoLocation: string
	CustomerLocationCountry: string
	CustomerLocationCountryCode: string
	CustomerLocationCity: string
	// CustomerStatConnectionTotal: string
	// CustomerUsername: string
	CustomerType: string
	// CustomerMembershipColor: string
	// CustomerMembershipPrice: string
	// CustomerStatUpvotesTotal: string
	// CustomerStatDownvotesTotal: string
	// CustomerLabelRole: string
	CustomerLabelLocation: string
	CustomerName: string
	CustomerProfession: string
	CustomUserBirthDate: string
	CustomerRole: string
	CustomerUserPhoneNumber: string
	CustomUserGenre: string
	CustomUserSexuality: string
	CustomUserRace: string
	CustomUserDisability: string
	CustomerNationality: string
	CustomerAgency: string
	CustomerDistance: string
	CustomerAge: string
	CustomerLanguage: string
	CustomerProcessStatus: string
	CustomerPhotoPinned: string
	CustomerShowreelPinned: string
	empty?: boolean
	relationExtraData?: string
}

export enum CUSTOMER_ROLE {
	ACTOR = 'actor',
	CASTING_DIRECTOR = 'castingdirector',
	AGENCY = 'agency',
	CONTRIBUTOR = 'contributor',
}
