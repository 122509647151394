import { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { contentAuthorize } from 'api/auth'
import useShakaPlayer from 'hooks/useShakaPlayer'
import { optionsParser, t } from 'helpers'
import { filterDuplicates } from 'helpers/propertiesParser'
import { useUserStore } from 'store'
import { Content, SelectOption } from 'types/app'
import { DROPDOWN_OPTIONS } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	picturesFields,
	skillFields,
} from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	item: Content
	active: boolean
}

const MainSliderSlide = ({ item, active }: Props): JSX.Element => {
	const activeTag = useUserStore(useShallow((state) => state.activeAttribute))
	const styleType = optionsParser(DROPDOWN_OPTIONS.PICTURE_TYPES).concat(
		optionsParser(DROPDOWN_OPTIONS.SHOWREEL_STYLES),
	)

	const typeOption = filterDuplicates<SelectOption>(
		styleType?.filter((type) => item.ContentLabelType === type.value),
		'value',
	)?.[0]

	const styleOption = filterDuplicates<SelectOption>(
		styleType?.filter((type) => item?.ContentLabelStyle === type.value),
		'value',
	)?.[0]

	const attProp = [
		item?.ContentLabelAttribute || '',
		item?.ContentLabelSkill || '',
	]
		?.filter((v) => !!v)
		.join(',')
		.split(',')
		?.filter((v) => !!v)
		.map((prop) => prop.split(':'))

	const options = Object.values(attributeBasicFields)
		.concat(Object.values(attributeSpecialFields))
		.concat(Object.values(skillFields))
		.filter((field) =>
			attProp.find((prop) => field.value === prop[0].toLowerCase()),
		)
		.map((field) => field.selectors)
		.flat()
		?.filter((selector) => !!selector.optionName)
		.map((selector) => optionsParser(t(selector.optionName || '')))
		.flat()

	const tags = attProp
		.map(([key, value]) => ({
			type: key,
			option: options.find((option) => option.value === value),
		}))
		.concat([
			{
				type: t(picturesFields?.[0]?.selectors?.[0]?.label),
				option: typeOption,
			},
			{
				type: t(picturesFields?.[0]?.selectors?.[1]?.label),
				option: styleOption,
			},
		])
		.filter((v) => !!v.option)

	const tagTypes = [...new Set(tags?.map((d) => d.type))]

	const parsedTags = tagTypes?.map((type) => ({
		type,
		options: tags
			?.map((d) => d.type === type && d.option)
			?.filter((v) => !!v) as SelectOption[],
	}))

	return (
		<>
			<div className={s.tagsPlaceholder} />
			<div className={s.slide}>
				<div className={classNames(s.tags, 'hideScrollBar')}>
					{parsedTags.map((data) => (
						<div
							key={data.type}
							className={classNames(s.tag, {
								[s.activeTag]:
									activeTag?.startsWith(data.type) &&
									activeTag?.endsWith(data.options?.[0].value),
							})}>
							<span className="caption-r" style={{ color: 'var(--mono200)' }}>
								{data.type}
							</span>
							<span>
								{'# ' + data.options?.map((option) => option?.label).join(', ')}
							</span>
						</div>
					))}
				</div>
				{item?.ContentSubType === 'Video' ? (
					<VideoItem item={item} active={active} />
				) : (
					<img
						src={item?.ContentSquareImageUrl || '/placeholder.png'}
						alt={item.ContentTitle}
						onError={(e) => {
							const target = e.target as HTMLImageElement
							target.src = '/placeholder.png'
						}}
					/>
				)}
			</div>
		</>
	)
}

const VideoItem = ({
	item,
	active,
}: {
	item: Content
	active: boolean
}): JSX.Element => {
	const [url, setUrl] = useState('')

	const { shakaInstance } = useShakaPlayer(
		url && active ? url : null,
		item.ContentId,
	)

	const fetchVideoUrl = useCallback(
		async (contentId: string): Promise<void> => {
			try {
				const response = await contentAuthorize(contentId)
				if (response?.Properties) {
					setUrl(
						response.Properties.find((value) => value.Name === 'Content:Url')
							?.Value || '',
					)
				}
			} catch (error) {
				console.log('error:', error)
			}
		},
		[],
	)

	useEffect(() => {
		if (item?.ContentId && active) fetchVideoUrl(item.ContentId)
	}, [fetchVideoUrl, active, item?.ContentId])

	useEffect(() => {
		const video = shakaInstance?.getMediaElement()
		if (!active && video && video.played) video.pause()
		else video?.play()
	}, [active, shakaInstance])

	return (
		<video
			id={item.ContentId}
			controls
			autoPlay
			style={{
				// width: 'calc(100% * var(--videoAspect))',
				objectFit: 'contain',
				// height: '100%',
			}}
		/>
	)
}

export default MainSliderSlide
