import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { propertiesParser, actionsParser } from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import { ContentCollection, Content, FetchReturn } from 'types/app'
import { KeyedMutator } from 'swr'

type ReturnData = FetchReturn & {
	content: Content | undefined
	refreshData: KeyedMutator<ContentCollection>
}

export default (id: string | undefined | null, bag?: boolean): ReturnData => {
	const { endpoints } = useAppStore.getState()

	const cacheUrl = id
		? endpoints?.CustomApplicationV3ContentUrl.replace('${contentId}', id)
		: null
	const bagUrl = id
		? endpoints?.CustomApplicationContentUrl + '/' + id + '/PropertyBag'
		: null

	const contentUrl = bag ? bagUrl : cacheUrl

	const { data, error, isLoading, mutate } = useSwrImmutable<ContentCollection>(
		contentUrl ? contentUrl : null,
		fetcher,
	)

	const content =
		data &&
		data.Properties &&
		({
			...propertiesParser(data.Properties),
			Actions: actionsParser(data.Actions),
		} as Content)

	return {
		isLoading: isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		content,
		refreshData: mutate,
	}
}
