import { useLocation } from 'react-router-dom'
import { FilterIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { useFilterStore } from 'store'
import s from './index.module.scss'
import { directorNetworkSteps } from 'pages/Network/CastingDirector/DirectorNetwork'

interface Props {
	itemsCount: number
}

const Header = ({ itemsCount }: Props): JSX.Element => {
	const { hash } = useLocation()

	return (
		<div className={s.header}>
			<div className={s.titleWrapper}>
				<h3 className="h3-m">
					{t(
						`APP_${
							hash?.includes(directorNetworkSteps.opennetwork.key)
								? 'ACTOR_OPENNETWORK'
								: 'MYNETWORK'
						}_TITLE`,
					)}
				</h3>
				<p className="caption-m" style={{ color: 'var(--mono300)' }}>
					{t(
						`APP_${
							hash?.includes(directorNetworkSteps.opennetwork.key)
								? 'ACTOR_OPENNETWORK'
								: 'MYNETWORK'
						}_DESCRIPTION`,
					)?.replace('{result}', itemsCount?.toString())}
				</p>
			</div>

			<div>
				<Button
					style={{ gap: '8px', color: 'var(--mono100)' }}
					onClick={() => {
						const { setOpenFilterSidebar, openFilterSidebar } =
							useFilterStore.getState()
						setOpenFilterSidebar(!openFilterSidebar)
					}}>
					{t('APP_SHOW_FILTERS')}
					<FilterIcon />
				</Button>
			</div>
		</div>
	)
}

export default Header
