import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import Select from 'components/UI/Select'
import Button from 'components/UI/Button'
import { getDefaultUserPath, optionsParser, t } from 'helpers'
import { getRedirectUrl, setRedirectUrl } from 'helpers/storage'
import { usePortfolioStore, useUserStore } from 'store'
import { DROPDOWN_OPTIONS } from 'types/enums'
import s from '../../index.module.scss'

const Goals = (): JSX.Element => {
	const [goal, setGoal] = useState('')
	const navigate = useNavigate()
	const user = useUserStore(useShallow((state) => state.user))

	const redirect = (): void => {
		const redirectUrl = getRedirectUrl()
		if (redirectUrl) {
			navigate(redirectUrl)
			setRedirectUrl('')
		} else navigate(getDefaultUserPath(user?.CustomerRole))
	}

	return (
		<Card
			step="onboarding_goals"
			noHint
			noTitle
			style={{
				padding: '0',
			}}>
			<div className={s.onboardingWrapper}>
				<div className={s.contentWrapper}>
					<div className={s.titles}>
						<h1 className="h1-m">{t('APP_ONBOARDING_GOALS_TITLE')}</h1>
						<span className="body1-m">{t('APP_ONBOARDING_GOALS_DESC')}</span>
					</div>
					<div
						style={{
							maxWidth: '300px',
							width: '100%',
						}}>
						<Select
							name="Customer:Goal"
							options={optionsParser(
								t(DROPDOWN_OPTIONS.ONBOARDING_GOALS || ''),
							)}
							label="APP_GOAL_LABEL"
							onChange={(e) => {
								const { updateFields } = usePortfolioStore.getState()
								setGoal(e.target.value)
								updateFields([
									{
										Name: 'Customer:Goal',
										Value: e.target.value,
									},
								])
							}}
							inputProps={{
								value: goal || '',
							}}
						/>
					</div>
					<div style={{ maxWidth: '300px', width: '100%' }}>
						<Button
							variant="themed"
							disabled={!goal}
							style={{ width: '100%' }}
							onClick={redirect}>
							{t('APP_NEXT')}
						</Button>
					</div>
				</div>
				<div className={s.imageWrapper}>
					<img src="/card-bg.png" />
				</div>
			</div>
		</Card>
	)
}

export default Goals
