import Modal from 'components/Modal'
import { useModalStore } from 'store'
import { useShallow } from 'zustand/react/shallow'

const GlobalModal = (): JSX.Element | null => {
	const [open, setOpen] = useModalStore(
		useShallow((state) => [state.open, state.setOpen]),
	)

	return open?.show ? (
		<Modal onClose={() => (open?.onClose ? open.onClose() : setOpen(null))}>
			{open?.children || <></>}
		</Modal>
	) : null
}

export default GlobalModal
