import { useShallow } from 'zustand/react/shallow'
import Sidebar from 'components/Sidebar'
import { t } from 'helpers'
import { useCastingStore } from 'store'

const UpdateRequestSidebar = (): JSX.Element => {
	const [user, setOpenSidebar] = useCastingStore(
		useShallow((state) => [
			state.openRequestSidebar,
			state.setOpenRequestSidebar,
		]),
	)

	return (
		<Sidebar
			open={!!user}
			onClose={() => setOpenSidebar(null)}
			header={{
				title: 'APP_REQUEST_PROFILE_UPDATE_SIDEBAR_TITLE',
			}}
			button={{
				children: t('APP_DONE'),
				onClick: () => setOpenSidebar(null),
			}}>
			<>
				{user?.relationExtraData ? (
					<p
						style={{ color: 'var(--mono200)' }}
						dangerouslySetInnerHTML={{
							__html: user?.relationExtraData,
						}}></p>
				) : null}
			</>
		</Sidebar>
	)
}

export default UpdateRequestSidebar
