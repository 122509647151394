import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import Switch from 'components/UI/Switch'
import Select from 'components/UI/Select'
import { usePortfolioStore, useUserStore } from 'store'
import { optionsParser, t } from 'helpers'
import { DROPDOWN_OPTIONS } from 'types/enums'
import { User } from 'types/user'
import s from './index.module.scss'

const switches = [
	{
		name: 'Customer:Settings:Notification:Email',
		label: 'APP_EMAIL_NOTIFICATION_LABEL',
	},
]

const Notifications = (): JSX.Element => {
	const [settings, setSettings] = useState<{ [key: string]: boolean }>({})
	const [agent, setAgent] = useState('')
	const user = useUserStore(useShallow((state) => state.user))

	useEffect(() => {
		if (user?.CustomerSettingsTutorialAgent && !agent)
			setAgent(user?.CustomerSettingsTutorialAgent)
		if (user && !Object.values(settings)?.length) {
			const values = switches
				?.map((s) => ({
					[s.name]:
						(user[s.name?.replaceAll(':', '') as keyof User] as string) === '1',
				}))
				.reduce(
					(prev, next) => ({
						...prev,
						...next,
					}),
					{},
				)
			setSettings(values)
		}
	}, [user, settings, user?.CustomerSettingsTutorialAgent, agent])

	return (
		<Card step="settings_notifications" noHint>
			<>
				<div className="grid3column">
					<div className={s.column}>
						{switches?.map((option) => (
							<Switch
								style={{ maxWidth: '100%' }}
								key={option.name}
								label={option.label}
								value={option.name}
								checked={settings[option.name]}
								onChange={(v) => {
									const newValue = !settings[v]
									setSettings((prev) => ({
										...prev,
										[v]: newValue,
									}))
									if (v?.includes('Email')) {
										const { updateFields } = usePortfolioStore.getState()
										updateFields([
											{
												Name: v,
												Value: newValue ? '1' : '0',
											},
										])
									}
								}}
							/>
						))}
						<Select
							name="Customer:Settings:Tutorial:Agent"
							options={optionsParser(t(DROPDOWN_OPTIONS.TUTORIAL_AGENT))}
							label="APP_TUTORIAL_AGENT_LABEL"
							onChange={(e) => {
								const { updateFields } = usePortfolioStore.getState()
								setAgent(e.target.value)
								updateFields([
									{
										Name: e.target.name,
										Value: e.target.value,
									},
								])
							}}
							inputProps={{
								value: agent,
							}}
						/>
					</div>
				</div>
			</>
		</Card>
	)
}

export default Notifications
