import jwtDecode from 'jwt-decode'
import { fetcher } from 'api'
import { getLanguage, uuidv4 } from 'helpers'
import { getDeviceData } from 'helpers/deviceData'
import { getUser } from 'helpers/storage'
import { useAppStore } from 'store'
import { CollectionsType } from 'types/app'
import { AuthData, AuthParsedCredentials } from 'types/auth'
import getBrowserType from 'helpers/getBrowserType'

export const contentAuthorize = (
	contentId: string,
	subtype?: string,
): Promise<CollectionsType> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationV3AuthorizeUrl || ''

	const drmType =
		/* getBrowserType() === 'Safari' ||  */ subtype === 'LiveStream'
			? 'HLS'
			: 'DASH'

	const payload = [
		{
			Value: contentId,
			CollectionName: 'Post',
			Name: 'Content:Id',
		},
		{ CollectionName: 'Post', Name: 'Content:Formats', Value: drmType },
	]

	return fetcher<CollectionsType>(url, {
		method: 'post',
		body: JSON.stringify(payload),
	})
}

export const authenticateUser = (
	userData: AuthData,
	provider: 'google' | 'apple',
): Promise<CollectionsType> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationAuthenticateUrl || ''
	const { name, email, picture, sub }: AuthParsedCredentials = jwtDecode(
		userData.credential,
	)
	let deviceId = localStorage.getItem('Custom:Device:UniqueId')
	if (!deviceId) {
		deviceId = uuidv4()
		localStorage.setItem('Custom:Device:UniqueId', deviceId)
	}
	const { deviceName, deviceSubType, deviceType, deviceModel } = getDeviceData()

	const payload = [
		{
			Name: 'Custom:Device:UniqueId',
			Value: deviceId,
		},
		{
			Name: 'Custom:User:Name',
			Value: name,
		},
		{
			Name: 'Social:Auth:Token',
			Value: userData.credential,
		},
		{
			Name: 'Social:User:Id',
			Value: sub,
		},
		{
			Name: 'Social:Auth:Provider',
			Value: provider,
		},
		{
			Name: 'Custom:User:Avatar',
			Value: picture,
		},
		{
			Name: 'Custom:Device:Type',
			Value: deviceType,
		},
		{
			Name: 'Custom:Device:Name',
			Value: deviceName,
		},
		{
			Name: 'Custom:Device:SubType',
			Value: deviceSubType,
		},
		{
			Name: 'Custom:Device:Model',
			Value: `${deviceModel}`,
		},
		{
			Name: 'Custom:Device:Locale',
			Value: getLanguage(),
		},
		{
			Name: 'Custom:User:Email',
			Value: email,
		},
	]

	return fetcher<CollectionsType>(url, {
		method: 'post',
		body: JSON.stringify(payload),
	})
}

export const registerUser = (
	userData: AuthData,
	provider: 'google' | 'apple',
): CollectionsType | Promise<CollectionsType> => {
	const { name, email, picture, sub }: AuthParsedCredentials = jwtDecode(
		userData.credential,
	)
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationRegisterUrl || ''

	const todayISOString = new Date().toISOString()
	let deviceId = localStorage.getItem('Custom:Device:UniqueId')
	if (!deviceId) {
		deviceId = uuidv4()
		localStorage.setItem('Custom:Device:UniqueId', deviceId)
	}
	const { deviceName, deviceSubType, deviceType, deviceModel } = getDeviceData()
	const invitationId = localStorage.getItem('invitationId') || ''
	const cid = localStorage.getItem('cid') || ''
	const role = localStorage.getItem('role') || 'Actor'

	const payload = [
		{
			Name: 'Custom:User:Id',
			Value: cid,
		},
		{
			Name: 'Custom:User:Type',
			Value: 'Creator',
		},
		{
			Name: 'Customer:Role',
			Value: role,
		},
		{
			Name: 'Invitation:Code',
			Value: invitationId,
		},
		{
			Name: 'Custom:License:Accept:Date',
			Value: todayISOString,
		},
		{
			Name: 'Custom:Policy:Accept:Date',
			Value: todayISOString,
		},

		{
			Name: 'Custom:Device:UniqueId',
			Value: deviceId,
		},
		{
			Name: 'Custom:User:Name',
			Value: name,
		},
		{
			Name: 'Social:Auth:Token',
			Value: userData.credential,
		},
		{
			Name: 'Social:User:Id',
			Value: sub,
		},
		{
			Name: 'Social:Auth:Provider',
			Value: provider,
		},
		{
			Name: 'Custom:User:Avatar',
			Value: picture,
		},
		{
			Name: 'Custom:Device:Type',
			Value: deviceType,
		},
		{
			Name: 'Custom:Device:Name',
			Value: deviceName,
		},
		{
			Name: 'Custom:Device:SubType',
			Value: deviceSubType,
		},
		{
			Name: 'Custom:Device:Model',
			Value: `${deviceModel}`,
		},
		{
			Name: 'Custom:Device:Locale',
			Value: getLanguage(),
		},
		{
			Name: 'Custom:User:Email',
			Value: email,
		},
		{
			Name: 'Customer:Process:Status',
			Value: 'Registered',
		},
		{
			Name: 'Customer:Settings:Application:Language',
			Value: 'en-US',
		},
		{
			Name: 'Customer:Settings:Notification:Email',
			Value: '1',
		},
		{
			Name: 'Customer:Settings:Tutorial:Agent',
			Value: 'BRUCE',
		},
		{
			Name: 'Customer:Network:Open',
			Value: '0',
		},
	]

	return fetcher<CollectionsType>(url, {
		method: 'post',
		body: JSON.stringify(payload),
	})
}

export const renewToken = async (): Promise<CollectionsType> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationRenewTokenUrl || ''
	const userData = getUser()
	const deviceId = localStorage.getItem('Custom:Device:UniqueId')

	const payload = [
		{
			CollectionName: 'Post',
			Name: 'Custom:User:Token',
			Value: userData ? userData['Custom:User:Token'] : '',
		},
		{
			CollectionName: 'Post',
			Name: 'Custom:Refresh:Token',
			Value: userData ? userData['Custom:Refresh:Token'] : '',
		},
		{
			CollectionName: 'Post',
			Name: 'Custom:Device:UniqueId',
			Value: deviceId,
		},
	]

	return fetcher(url, { method: 'post', body: JSON.stringify(payload) })
}
