import { Outlet } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import Header from './components/Header'
import Footer from './components/Footer'
import Nav from './components/Nav'
import NotificationSidebar from './sidebars/NotificationSidebar'
import FilterSidebar from './sidebars/FilterSidebar'
import GlobalModal from './components/GlobalModal'
import QRCodeSidebar from './sidebars/QRCodeSidebar'
import RoleInfoSidebar from './sidebars/RoleInfoSidebar'
import ProductionInfoSidebar from './sidebars/ProductionInfoSidebar'
import LocalSearchSidebar from './sidebars/LocalSearchSidebar'
import UserInfoSidebar from './sidebars/UserInfoSidebar'
import DocumentViewerSidebar from './sidebars/DocumentViewerSidebar'
import FeedbackSidebar from './sidebars/FeedbackSidebar'
import AddSceneSidebar from './sidebars/AddSceneSidebar'
import BlurBackground from './components/BlurBackground'
import useContent from 'hooks/api/useContent'
import { useFilterStore, useUserStore } from 'store'
import s from './index.module.scss'

const Layout = (): JSX.Element => {
	const [user] = useUserStore(useShallow((state) => [state.user]))
	const { content } = useContent(user?.CustomerChatId)
	const openFilter = useFilterStore(
		useShallow((state) => state.openFilterSidebar),
	)

	return (
		<>
			<BlurBackground />
			<Header />
			<div className={cn(s.wrapper, { [s.reduceWidth]: openFilter })}>
				<Nav />
				{/* <main className={s.main}> */}
				<Outlet />
				{/* </main> */}
			</div>
			<Footer />
			{content ? <NotificationSidebar chatData={content} /> : null}
			<FilterSidebar />
			<RoleInfoSidebar />
			<ProductionInfoSidebar />
			<UserInfoSidebar />
			<GlobalModal />
			<QRCodeSidebar />
			<LocalSearchSidebar />
			<DocumentViewerSidebar />
			<FeedbackSidebar />
			<AddSceneSidebar />
		</>
	)
}

export default Layout
