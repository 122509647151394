import classNames from 'classnames'
import { t } from 'helpers'
import { SidebarTabsOptions } from 'types/app'
import s from './index.module.scss'
import { CSSProperties } from 'react'

interface Props {
	active: string | number
	options: SidebarTabsOptions[]
	onChange: (v: string | number) => void
	type?: 'fit' | 'full'
	activeClass?: string
	style?: CSSProperties
	tabStyle?: CSSProperties
}

const SidebarTabs = ({
	active,
	options,
	onChange,
	type = 'full',
	activeClass,
	style,
	tabStyle,
}: Props): JSX.Element => {
	return (
		<div className={classNames(s.tabs, s[type])} style={style}>
			{options?.map((tab) => (
				<div
					key={tab?.value}
					onClick={() => onChange(tab?.value)}
					className={classNames(
						s.tab,
						tab?.value === active ? activeClass : '',
						{
							[s.active]: tab?.value === active,
						},
					)}
					style={tabStyle}>
					{typeof tab?.label === 'string' ? (
						<span className="body1-m">{t(tab?.label)}</span>
					) : (
						tab?.label
					)}
				</div>
			))}
		</div>
	)
}

export default SidebarTabs
