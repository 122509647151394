import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import Modal from 'components/Modal'
import { t } from 'helpers'
import { getDeviceData } from 'helpers/deviceData'
import { useUserStore } from 'store'
import { PATHS } from 'pages/Router'

const MobileBlockModal = (): JSX.Element | null => {
	const [showMessage, setShowMessage] = useState(false)
	const { pathname } = useLocation()
	const user = useUserStore(useShallow((state) => state.user))

	useEffect(() => {
		const isMobile = getDeviceData()?.deviceType === 'MOBILE'

		const userPath =
			'/' + PATHS.PROFILE?.replace(':userId', user?.ContentId || '')

		if (isMobile && user?.ContentId && pathname !== userPath) {
			setShowMessage(true)
		} else setShowMessage(false)
	}, [pathname, user?.ContentId])

	return showMessage ? (
		<Modal
			onClose={() => null}
			style={{
				backgroundColor: 'var(--mono900)',
				zIndex: 9999999999,
			}}>
			<div
				style={{
					padding: '20px',
					boxSizing: 'border-box',
					borderRadius: '25px',
					backgroundColor: 'var(--elev400)',
					maxWidth: '800px',
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '20px',
				}}>
				<>
					<h4 className="h4-m" style={{ textAlign: 'center' }}>
						{t('APP_MOBILE_DEVICE_ERROR')}
					</h4>
				</>
			</div>
		</Modal>
	) : null
}

export default MobileBlockModal
