import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import cn from 'classnames'
import StepWrapper from 'components/Wizard/StepWrapper'
import Profile from './components/Profile'
import Notifications from './components/Notifications'
import Subscription from './components/Subscription'
import ValidationWrapper from './components/ValidationWrapper'

import { useAppStore } from 'store'
import s from './index.module.scss'

export const settingsSteps = {
	profile: {
		name: 'APP_SETTINGS_PROFILE',
		key: 'profile',
		main: true,
		substeps: [
			{
				name: 'APP_SETTINGS_PROFILE',
				key: 'profile',
				component: Profile,
				index: 0,
			},
		],
	},
	notifications: {
		name: 'APP_SETTINGS_NOTIFICATIONS',
		key: 'notifications',
		main: true,
		substeps: [
			{
				name: 'APP_SETTINGS_NOTIFICATIONS',
				key: 'notifications',
				component: Notifications,
				index: 0,
			},
		],
	},
	validation: {
		name: 'APP_VALIDATION_TITLE',
		key: 'validation',
		main: true,
		substeps: [
			{
				name: 'APP_VALIDATION_TITLE',
				key: 'validation',
				component: ValidationWrapper,
				index: 0,
			},
		],
	},
	// subscription: {
	// 	name: 'APP_SETTINGS_SUBSCRIPTION',
	// 	key: 'subscription',
	// 	main: true,
	// 	substeps: [
	// 		{
	// 			name: 'APP_SETTINGS_SUBSCRIPTION',
	// 			key: 'subscription',
	// 			component: Subscription,
	// 			index: 0,
	// 		},
	// 	],
	// },
}

const Settings = (): JSX.Element => {
	const navigate = useNavigate()

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname + '#' + settingsSteps.profile?.key + '-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	return (
		<div className={s.container}>
			<div className={cn(s.wrapper, 'flex')}>
				<StepWizard
					initialStep={0}
					instance={assignStepWizard}
					isHashEnabled={true}
					className={s.wizard}>
					{Object.values(settingsSteps)
						?.map((step) => step.substeps)
						?.flat()
						?.map((step) => (
							<StepWrapper
								key={step.name + '_' + step.index}
								hashKey={step.key + '-' + step.index}
								stepName={step.key + '-' + step.index}>
								<step.component />
							</StepWrapper>
						))}
				</StepWizard>
			</div>
		</div>
	)
}

export default Settings
