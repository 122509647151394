import { useMemo } from 'react'
import Section from '../Section'
import { FilterSection } from '../../FilterSidebar'
import { t } from 'helpers'
import { AttributeSelector } from 'types/app'
import {
	attributeBasicFields,
	basicFields,
	personalFields,
} from 'pages/Portfolio/fields'

const userStatusOptions = [
	{
		value: '',
		label: 'APP_ALL_STATUS',
	},
	{
		value: 'Active',
		label: 'APP_ACTIVE_STATUS',
	},
	{
		value: 'Invited',
		label: 'APP_INACTIVE_STATUS',
	},
]

const quickSections: FilterSection[] = [
	{
		title: '',
		fields: [
			{
				name: 'imdbLink',
				label: '',
				type: 'checkbox',
				optionName: 'IMDB|IMDb',
				options: [],
			},
			{ ...basicFields.name, name: 'userSearch' },
			{
				name: 'userStatus',
				label: 'APP_USER_STATUS_LABEL',
				type: 'select',
				options: userStatusOptions,
			},
			basicFields.email,
			basicFields.profession,
		],
	},
	{
		title: '',
		fields: [
			{
				name: 'Customer:Age',
				label: 'APP_CUSTOMER_AGE_LABEL',
				type: 'range',
				min: 0,
				max: 100,
				options: [],
			},
		],
	},
	{
		title: '',
		fields: [personalFields.gender],
	},
	{
		title: '',
		fields: [
			attributeBasicFields.body.selectors.find((selector) =>
				selector?.name?.includes('Type'),
			) as AttributeSelector,
		],
	},
]

interface Props {
	searchFilter: string
}

const Quick = ({ searchFilter }: Props): JSX.Element => {
	const filters = useMemo(
		() =>
			searchFilter
				? quickSections?.map((section) => ({
						...section,
						fields: section?.fields?.filter((field) =>
							t(field.label)
								?.toLowerCase()
								?.includes(searchFilter?.toLowerCase()),
						),
				  }))
				: quickSections,
		[searchFilter],
	)

	return (
		<>
			{filters?.map((section) => (
				<Section
					section={section}
					key={section.title || section?.fields?.[0]?.name}
				/>
			))}
		</>
	)
}

export default Quick
