import { useLocation, useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import ProductionCard from 'components/ProductionCard'
import RoleCard from 'components/RoleCard'
import useContent from 'hooks/api/useContent'
import { useProductionStore } from 'store'
import { Content } from 'types/app'
import { PATHS } from 'pages/Router'

const Aside = (): JSX.Element | null => {
	const { pathname } = useLocation()
	const { productionRoleId, productionId } = useParams()
	const [currentProd, currentRole] = useProductionStore(
		useShallow((state) => [state.production, state.roles[state.currentRole]]),
	)
	const { content: production } = useContent(productionId, true)
	const { content: role } = useContent(productionRoleId, true)

	return pathname.includes(PATHS.PROJECT) ? (
		<>
			{productionId && !productionRoleId ? (
				<ProductionCard
					content={
						{
							...(production || {}),
							ContentColor: currentProd.color,
							'Globalizationen-USContentTitle': currentProd.name,
							'Globalizationen-USContentDescription': currentProd.description,
							ContentDirectorName: currentProd?.directorName,
							ContentProductionType: currentProd?.productionType,
							ContentEventRehearsalDate: JSON.stringify(currentProd?.rehearsal),
							ContentEventFilmingDate: JSON.stringify(currentProd?.filming),
						} as Content
					}
					showDescription
					type="aside"
				/>
			) : productionRoleId ? (
				<RoleCard
					content={
						{
							...(role || {}),
							'Globalizationen-USContentTitle': currentRole?.title,
							'Globalizationen-USContentDescription': currentRole?.character,
							ContentRoleAge: currentRole?.age || '',
							ContentRoleDialect: currentRole?.language || '',
							ContentRoleGender: currentRole?.gender || '',
							ContentShootingDays: currentRole?.shootingDays || '',
							ContentEventDeadline: currentRole?.deadline || '',
							ContentColor: currentRole?.color,
						} as Content
					}
					type="aside"
				/>
			) : null}
		</>
	) : null
}

export default Aside
