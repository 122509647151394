import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useShallow } from 'zustand/react/shallow'
import dayjs from 'dayjs'
import { sendFeedback } from 'api/user'
import Loader from 'components/Loader'
import Sidebar from 'components/Sidebar'
import Textarea from 'components/UI/Textarea'
import { t } from 'helpers'
import { useAppStore, useUserStore } from 'store'

const FeedbackSidebar = (): JSX.Element => {
	const [loading, setLoading] = useState(false)
	const message = useRef<HTMLTextAreaElement>(null)
	const [openSidebar, setOpenSidebar] = useAppStore(
		useShallow((state) => [
			state.openFeedbackSidebar,
			state.setOpenFeedbackSidebar,
		]),
	)

	const sendUserFeedback = async (): Promise<void> => {
		setLoading(true)
		try {
			const { user } = useUserStore.getState()
			const body = `${user?.ContentTitle} (${user?.ContentId})<br />URL<br />${
				window.location.href
			}<br />Local Date<br />${new Date().toISOString()}<br />Message<br />${
				message.current?.value
			}`
			await sendFeedback(
				'Feedback by ' +
					user?.ContentTitle +
					' at ' +
					dayjs().format('MM-DD-YYYY HH:mm'),
				body,
			)
			toast.info(t('APP_FEEDBACK_SUCCESS'))
		} catch (error) {
			console.log('error:', error)
			toast.info(t('APP_FEEDBACK_ERROR'))
		} finally {
			setLoading(false)
			setOpenSidebar(false)
		}
	}

	useEffect(() => {
		if (!openSidebar && message?.current) {
			message.current.value = ''
		}
	}, [openSidebar])

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => {
				setOpenSidebar(false)
			}}
			header={{
				title: 'APP_FEEDBACK_SIDEBAR_TITLE',
				description: 'APP_FEEDBACK_SIDEBAR_DESCRIPTION',
			}}
			button={{
				children: t('APP_SEND_MESSAGE'),
				onClick: sendUserFeedback,
			}}
			style={{ height: '100%' }}>
			<>
				<Loader adapt loading={loading} />
				<Textarea
					name="feedback_comment"
					label=""
					inputRef={message}
					rows={20}
					placeholder={t('APP_APP_FEEDBACK_PLACEHOLDER')}
				/>
			</>
		</Sidebar>
	)
}

export default FeedbackSidebar
