import { useEffect, useMemo, useRef } from 'react'
import Card from 'components/Wizard/Card'
import useRelations from 'hooks/api/useRelationsContent'
import { getPinnedMedia } from 'helpers'
import { usePortfolioStore } from 'store'
import { showreelsFields } from '../../fields'
import { Content } from 'types/app'
import AttributeLargeCard from 'pages/Portfolio/components/AttributeLargeCard'
import { PORTFOLIO_TYPES, RELATIONS } from 'types/enums'

const Showreels = ({ index = 0 }: { index?: number }): JSX.Element => {
	const { data } = useRelations([RELATIONS.BEST_PERFORMANCES])

	const videos = useMemo(
		() => (data ? data[RELATIONS.BEST_PERFORMANCES] : []),
		[data],
	)

	const dataLoadRef = useRef(false)

	useEffect(() => {
		if (videos?.length && !dataLoadRef.current) {
			dataLoadRef.current = true

			const pinned = getPinnedMedia(
				showreelsFields?.[0]?.selectors?.[0]?.name?.replaceAll(':', ''),
			)

			const { setCardValues } = usePortfolioStore.getState()
			videos?.forEach((content) => {
				const field = showreelsFields?.find(
					(f) => f.index === content?.relationIndex,
				)
				if (field) {
					const names = field.selectors?.map((sel) => sel.name)
					const values = names
						?.map((name) => ({
							[name]: name?.includes('Pinned')
								? pinned?.[field.index as number]
								: (content[name as keyof Content] as string) || '',
						}))
						?.reduce((prev, next) => ({ ...prev, ...next }), {})
					setCardValues(
						{
							[field.value]:
								content?.ContentSquareImageUrl || content?.ContentId,
							...values,
							contentId: content?.ContentId,
						},
						field.value,
					)
				}
			})
		}
	}, [videos])

	return (
		<Card step="showreels" noHint>
			<div
				className="grid2column"
				style={{
					height: '100%',
				}}>
				{showreelsFields
					?.slice(4 * index, 4 * index + 4)
					?.map((field) =>
						field ? (
							<AttributeLargeCard
								key={field.value}
								generalType={PORTFOLIO_TYPES.SHOWREEL}
								type={field.value}
								cardData={field}
							/>
						) : null,
					)}
			</div>
		</Card>
	)
}

export default Showreels
