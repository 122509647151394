import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { deleteRelation, getRelations } from 'api/relations'
import { contentDelete, getContent, getPlaylistItems } from 'api/content'
import { PlusIcon } from 'assets/icons'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Button from 'components/UI/Button'
import useAccessRelation from 'hooks/api/useAccessRelation'
import { prepareMessageData, t, wait } from 'helpers'
import contentParser from 'helpers/propertiesParser'
import { getUser } from 'helpers/storage'
import { useAppStore, useModalStore } from 'store'
import { PATHS } from 'pages/Router'
import { Content, Relation, SidebarTabsOptions } from 'types/app'
import { CASTING_STATUS, MESSAGE_TYPE, RELATIONS } from 'types/enums'
import { User } from 'types/user'
import s from '../../index.module.scss'
import styles from './index.module.scss'
import { EventBus } from 'helpers/EventBus'

interface Props {
	options: SidebarTabsOptions[]
	users: User[]
	setLoading: (loading: boolean) => void
}

const CastingUsers = ({ options, users, setLoading }: Props): JSX.Element => {
	const navigate = useNavigate()
	const [activeTab, setActiveTab] = useState(options?.[0].value)
	const [roleId] = useAppStore(useShallow((state) => [state.roleInfoSidebar]))
	const swiperRef = useRef<SwiperClass>()
	const { data } = useAccessRelation()
	const currentRoleRelations = data?.filter((rel) => rel.sourceId === roleId)

	const lists = Object.values(options)
		?.map((option) => {
			const relation = currentRoleRelations?.find(
				(relation) => relation.status === option.value,
			)

			return {
				relation,
				users: users
					?.map((user) =>
						relation && user?.ContentId === relation.targetId ? user : null,
					)
					?.filter((v) => !!v),
			}
		})
		?.filter((list) => (!!list?.relation && list?.users?.length ? list : []))

	const notifyOption = [
		CASTING_STATUS.WAITING,
		CASTING_STATUS.DECLINED,
		CASTING_STATUS.SELECTED,
	]

	const notifyUsers = (users: User[], status: CASTING_STATUS): void => {
		const { roleInfoSidebar, setRoleInfoSidebar } = useAppStore.getState()
		const data = {
			userIds: users?.map((user) => user?.ContentId),
			sourceId: roleInfoSidebar,
			key: 'NOTIFYUSERS',
			messageValue: t('APP_NOTIFYUSERS_MESSAGE_BODY')?.replace(
				'[YourName]',
				getUser()?.['Custom:User:Name'] || '',
			),
			redirect: window.location.pathname + window.location.hash,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.CASTING,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
		setRoleInfoSidebar('')
	}

	const deleteCasting = async (
		user: User,
		relation: Relation,
	): Promise<void> => {
		setLoading(true)
		try {
			const relations = await getRelations(
				`${relation.sourceId}_Content_Access`,
				RELATIONS.RELATIONSHIP,
				RELATIONS.CONTENT,
			)
			const contents = await Promise.all(
				relations?.pageContent?.map((rel) => getContent(rel.targetId)),
			).then((res) => res?.map((data) => contentParser(data)))

			const playlist = contents?.find(
				(content) => content.ContentOwnerId === user?.ContentId,
			)
			const playlistId = playlist?.ContentId

			const playlistItems = await getPlaylistItems({
				ContentId: playlistId,
			} as Content)

			const chatRelations = await getRelations(
				playlistId || '',
				'Content',
				RELATIONS.CHAT,
			)
			const voiceChatId = chatRelations?.pageContent?.[0]?.targetId

			const references = await getRelations(
				voiceChatId,
				'Content',
				RELATIONS.REFERENCE,
			)
			const textChatId = references?.pageContent?.[0]?.targetId

			await Promise.all(
				playlistItems?.pageContent?.map((rel) => contentDelete(rel.targetId)),
			)
			await contentDelete(voiceChatId)
			await contentDelete(textChatId)

			if (playlistId) {
				await contentDelete(playlistId)
				await deleteRelation(playlistId, 'Content', RELATIONS.CHAT, voiceChatId)
				await deleteRelation(
					`${relation.sourceId}_Content_Access`,
					RELATIONS.RELATIONSHIP,
					RELATIONS.CONTENT,
					playlistId,
				)
			}
			await deleteRelation(
				relation.sourceId,
				relation.type as 'Content',
				relation.relation as RELATIONS,
				relation.targetId,
			)
			EventBus.$emit('refreshCDboard')
			await wait(1000)
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setActiveTab(options?.[0].value)
			swiperRef.current?.slideTo(0)
		}
	}, [options])

	return (
		<>
			<SidebarTabs
				type="fit"
				active={activeTab}
				onChange={(v) => {
					setActiveTab(v as CASTING_STATUS)
					swiperRef.current?.slideTo(
						options?.findIndex((opt) => opt.value === v),
					)
				}}
				options={options}
				activeClass={s.activeTab}
			/>
			<Swiper
				allowTouchMove={false}
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper
				}}
				style={{ width: '100%' }}>
				{lists?.map((list, index) => (
					<SwiperSlide key={index}>
						{list?.users?.length && list.relation ? (
							<>
								<Items
									users={list.users as User[]}
									deleteCasting={deleteCasting}
									relation={list.relation}
								/>
								<span
									onClick={() =>
										notifyUsers(
											list.users as User[],
											options[index].value as CASTING_STATUS,
										)
									}
									className={cn('body2-m', styles.notify)}>
									{list.users?.length &&
									notifyOption.includes(options[index].value as CASTING_STATUS)
										? t('APP_NOTIFY_LABEL')
										: ''}
								</span>
							</>
						) : (
							<span>{t('APP_RESULT_NOT_FOUND')}</span>
						)}
					</SwiperSlide>
				))}
			</Swiper>
		</>
	)
}

export default CastingUsers

const Items = ({
	users,
	relation,
	deleteCasting,
}: {
	users: User[]
	relation: Relation
	deleteCasting: (user: User, relation: Relation) => void
}): JSX.Element => (
	<div className={styles.container}>
		{users?.map((user) => (
			<div key={user?.ContentId} className={styles.wrapper}>
				<div
					onClick={() => {
						const { setOpen } = useModalStore.getState()
						setOpen({
							show: true,
							children: (
								<div
									className="card"
									style={{
										maxWidth: '400px',
										display: 'flex',
										flexDirection: 'column',
										gap: '20px',
									}}>
									<>
										<h4 className="h4-m" style={{ textAlign: 'center' }}>
											{t('APP_CONFIRM_CASTING_DELETE')}
										</h4>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
											}}>
											<Button
												style={{ width: '100%' }}
												onClick={() => {
													deleteCasting(user, relation)
													setOpen(null)
												}}>
												{t('APP_DELETE')}
											</Button>
											<Button
												style={{ width: '100%' }}
												variant="themed"
												onClick={() => {
													setOpen(null)
												}}>
												{t('APP_CANCEL')}
											</Button>
										</div>
									</>
								</div>
							),
						})
					}}
					className={styles.remove}>
					<PlusIcon style={{ transform: 'rotate(45deg)' }} />
				</div>
				<a
					style={{ display: 'flex' }}
					href={
						window.location.origin +
						'/' +
						PATHS.PROFILE?.replace(':userId', user?.ContentId)
					}
					target="_blank">
					<img
						src={user?.CustomerImageSquareUrl}
						alt={user?.ContentTitle}
						className={styles.image}
						style={{
							border: `1px solid var(--${user?.CustomerRole?.toLowerCase()})`,
						}}
					/>
				</a>
				<span
					className="caption-s textClamp"
					style={{
						textAlign: 'center',
						WebkitLineClamp: '2',
					}}
					dangerouslySetInnerHTML={{
						__html: user?.ContentTitle?.replaceAll(' ', '<br/>'),
					}}></span>
			</div>
		))}
	</div>
)
