import { ChangeEvent } from 'react'
import { FlagImage } from 'react-international-phone'
import { useShallow } from 'zustand/react/shallow'
import Select from 'components/UI/Select'
import { useAppStore } from 'store'

interface Props {
	name?: string
	label?: string
	value: string
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const CountrySelector = ({
	name,
	label,
	value,
	onChange,
}: Props): JSX.Element => {
	const countries = useAppStore(useShallow((state) => state.countries))
	const options = countries?.map((country) => ({
		value: `${country.name}`,
		// value: `${country.name}, ${country.iso2?.toUpperCase()}`,
		label: country.name,
		element: (
			<div
				style={{
					display: 'flex',
					borderRadius: '50%',
					overflow: 'hidden',
					width: '20px',
					height: '20px',
					position: 'relative',
				}}>
				<FlagImage
					style={{
						width: '30px',
						height: '30px',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
					iso2={country.iso2}
				/>
			</div>
		),
	}))

	return (
		<Select
			name={name || 'Customer:Location:Country'}
			label={label || 'APP_LOCATION_LABEL'}
			options={options}
			inputProps={{
				name: name || 'Customer:Location:Country',
				value,
			}}
			onChange={onChange}
		/>
	)
}

export default CountrySelector
