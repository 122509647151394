import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import {
	LogoutIcon,
	MessageIcon,
	NotificationIcon,
	ProfileIcon,
	QRCodeIcon,
	SettingsIcon,
} from 'assets/icons'
import useUser from 'hooks/api/useUser'
import { getUser, logout } from 'helpers/storage'
import { t } from 'helpers'
import { useAppStore } from 'store'
import { PATHS } from 'pages/Router'
import s from './index.module.scss'
import { SIDEBARS } from 'types/enums'

const Menu = (): JSX.Element => {
	const { user } = useUser(getUser()?.['Custom:User:Id'], true)
	const navigate = useNavigate()
	const [showDropdown, setShowDropdown] = useState(false)

	return (
		<div className={s.icons}>
			{user ? (
				<>
					<div
						className={s.icon}
						style={{ backgroundColor: 'unset' }}
						onClick={() => {
							navigate(
								window.location.pathname +
									`?sidebar=${SIDEBARS.NOTIFICATIONS}` +
									window.location.hash,
							)
						}}>
						<NotificationIcon color="var(--mono400)" />
					</div>
					<div
						className={s.headerDropdown}
						onMouseOver={() => setShowDropdown(true)}
						onMouseLeave={() => setShowDropdown(false)}>
						<div
							className={cn(s.icon, s.avatar)}
							style={{
								borderColor: user?.CustomerRole
									? `var(--${user.CustomerRole.toLowerCase()})`
									: '',
							}}>
							<img
								src={user.CustomerImageSquareUrl}
								alt="user avatar"
								onError={(e) => {
									const target = e.target as HTMLImageElement
									target.src = '/placeholder.png'
								}}
							/>
						</div>
						<MenuDropdown
							show={showDropdown}
							hideDropdown={() => setShowDropdown(false)}
						/>
					</div>
				</>
			) : null}
		</div>
	)
}

export default Menu

const MenuDropdown = ({
	show,
	hideDropdown,
}: {
	show: boolean
	hideDropdown: () => void
}): JSX.Element => {
	const navigate = useNavigate()
	const { user } = useUser(getUser()?.['Custom:User:Id'])

	const navigateToProfile = (): void => {
		hideDropdown()
		navigate('/user/' + user?.ContentId)
	}

	const navigateToSettings = (): void => {
		hideDropdown()
		navigate(PATHS.SETTINGS)
	}

	const openQRSidebar = (): void => {
		const { setOpenQRSidebar } = useAppStore.getState()
		setOpenQRSidebar(true)
	}

	const openFeedbackSidebar = (): void => {
		const { setOpenFeedbackSidebar } = useAppStore.getState()
		setOpenFeedbackSidebar(true)
	}

	return (
		<div className={cn(s.dropdown, { [s.show]: show })}>
			<div className={s.item} onClick={navigateToProfile}>
				<div>
					<ProfileIcon width="24" />
				</div>
				<span className="body1-m">{t('APP_PROFILE_DROPDOWN')}</span>
			</div>
			<div className={s.item} onClick={navigateToSettings}>
				<div>
					<SettingsIcon />
				</div>
				<span className="body1-m">{t('APP_SETTINGS_DROPDOWN')}</span>
			</div>
			<div className={s.item} onClick={openQRSidebar}>
				<div>
					<QRCodeIcon />
				</div>
				<span className="body1-m">{t('APP_QRCODE_DROPDOWN')}</span>
			</div>
			<div className={s.item} onClick={openFeedbackSidebar}>
				<div>
					<MessageIcon />
				</div>
				<span className="body1-m">{t('APP_FEEDBACK_DROPDOWN')}</span>
			</div>
			<div className={s.divider} />
			<div
				className={s.item}
				onClick={() => {
					hideDropdown()
					logout()
					navigate('/')
				}}>
				<div>
					<LogoutIcon />
				</div>
				<span className="body1-m">{t('APP_LOGOUT_DROPDOWN')}</span>
			</div>
		</div>
	)
}
