import { Fragment, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import cn from 'classnames'
import Tooltip from 'components/Tooltip'
import { useAppStore, usePortfolioStore } from 'store'
import { t } from 'helpers'
import { PATHS } from 'pages/Router'
import { actorNetworkSteps } from 'pages/Network/Actor/ActorNetwork'
import { directorNetworkSteps } from 'pages/Network/CastingDirector/DirectorNetwork'
import { actorCastingSteps } from 'pages/Casting/Actor/ActorCasting'
import { directorCastingSteps } from 'pages/Casting/CastingDirector/DirectorCasting'
import { contributorCastingSteps } from 'pages/Casting/Contributor/ContributorCasting'
import { profileSteps } from 'pages/Portfolio/components/StepContent/StepContent'
// import { productionsSteps } from 'pages/Productions/Productions'
import { settingsSteps } from 'pages/Settings/Settings'
import { CUSTOMER_ROLE } from 'types/user'
import { Step } from 'types/app'
import s from './index.module.scss'
import { agencyNetworkSteps } from 'pages/Network/Agency/AgencyNetwork'

type Steps = {
	[key in PATHS]: {
		[key in CUSTOMER_ROLE]: Step[]
	}
}

const Tabs = (): JSX.Element => {
	const { pathname } = useLocation()
	const userRole = pathname?.split('/')[1]

	const currentNav = pathname?.split('/')[2] as PATHS

	const steps = useMemo(() => {
		const role = userRole as CUSTOMER_ROLE

		const step = {
			[PATHS.NETWORK]: {
				[CUSTOMER_ROLE.ACTOR]: Object.values(actorNetworkSteps),
				[CUSTOMER_ROLE.AGENCY]: Object.values(agencyNetworkSteps),
				[CUSTOMER_ROLE.CASTING_DIRECTOR]: Object.values(directorNetworkSteps),
				[CUSTOMER_ROLE.CONTRIBUTOR]: [],
			},
			[PATHS.CASTING]: {
				[CUSTOMER_ROLE.ACTOR]: Object.values(actorCastingSteps),
				[CUSTOMER_ROLE.AGENCY]: Object.values(directorCastingSteps),
				[CUSTOMER_ROLE.CASTING_DIRECTOR]: Object.values(directorCastingSteps),
				[CUSTOMER_ROLE.CONTRIBUTOR]: Object.values(contributorCastingSteps),
			},
			[PATHS.PORTFOLIO]: {
				[CUSTOMER_ROLE.ACTOR]: Object.values(profileSteps),
			},
			// [PATHS.PRODUCTIONS]: {
			// 	[CUSTOMER_ROLE.CASTING_DIRECTOR]: Object.values(productionsSteps),
			// },
			// [PATHS.INSPIRATION]: {
			// 	[CUSTOMER_ROLE.CASTING_DIRECTOR]: inspirationSteps,
			// },
			// [PATHS.SETTINGS]: {
			// 	'*': settingsSteps,
			// },
		} as unknown as Steps

		if (userRole === PATHS.SETTINGS) return Object.values(settingsSteps)

		return role && step[currentNav] && step[currentNav][role]
			? step[currentNav][role]
			: []
	}, [userRole, currentNav])

	// return steps?.length > 1 ||
	// 	(steps?.length === 1 && steps[0].substeps?.length > 1) ? (
	return (
		<div className={cn(s.container, 'flex')}>
			{steps?.map((step) => (
				<Tab key={step.key + '-' + step.name} step={step} />
			))}
		</div>
	)
}

export default Tabs

const Tab = ({
	step: { substeps, key, name, dynamic, altName = '', hideSubstepsUntilActive },
}: {
	step: Step
}): JSX.Element => {
	const { hash } = useLocation()
	const { productionId } = useParams()

	const parsedHash = hash?.slice(1)?.split('-')
	const activeStepKey = parsedHash[0]
	const activeStepIndex = parsedHash[1]
	const dynamicSubstepsActive = substeps?.find(
		(sub) => sub.index === +activeStepIndex,
	)
	const dynamicTab = dynamic && dynamicSubstepsActive

	const currentStepActive = key === activeStepKey

	const active =
		(currentStepActive && dynamicTab) ||
		(currentStepActive && !dynamic && dynamicSubstepsActive)

	return (
		<div className={s.wrapper}>
			{dynamic && !dynamicTab ? (
				<></>
			) : (
				<div
					className={cn(s.tab, {
						[s.active]: active,
					})}
					onClick={() => {
						const { stepWizard } = useAppStore.getState()
						stepWizard?.goToNamedStep(`${key}-0`)
						const { setCardData } = usePortfolioStore.getState()
						setCardData(null)
					}}>
					<span className="body1-m">
						{t(
							productionId &&
								key === directorCastingSteps.myproduction.key &&
								!dynamic &&
								altName
								? altName
								: name,
						)}
					</span>
				</div>
			)}
			{substeps?.filter((step) => !step?.hide)?.length > 1 ? (
				<div
					className={s.substepsWrapper}
					style={{
						width:
							active && (!hideSubstepsUntilActive || +activeStepIndex > 0)
								? substeps?.length * 28 + (substeps?.length - 1) * 14 + 'px'
								: '0px',
						marginLeft:
							dynamic && !dynamicTab ? '-10px' : active ? '20px' : '0px',
					}}>
					{substeps?.map((substep) => (
						<Tooltip key={key + '-' + substep.index} title={substep.name}>
							<div
								className={cn(s.substep, {
									[s.active]:
										currentStepActive &&
										substep.index?.toString() === activeStepIndex,
								})}
								onClick={() => {
									if (!substep.disabled) {
										const { stepWizard } = useAppStore.getState()
										stepWizard?.goToNamedStep(`${key}-${substep.index}`)
										const { setCardData } = usePortfolioStore.getState()
										setCardData(null)
									}
								}}>
								<div />
							</div>
						</Tooltip>
					))}
				</div>
			) : null}
		</div>
	)
}
