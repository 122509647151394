import { useShallow } from 'zustand/react/shallow'
import { useMemo, useState } from 'react'
import { SearchIcon } from 'assets/icons'
import Sidebar from 'components/Sidebar'
import Input from 'components/UI/Input'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Filter from './components/Filter'
import Quick from './components/Quick'
import CastingFilter from './components/CastingFilter'
import { t } from 'helpers'
import { useFilterStore } from 'store'
import { AttributeSelector } from 'types/app'

export type FilterSection = {
	title: string
	fields: AttributeSelector[]
	hideByDefault?: boolean
}

const tabs = ['quick', 'filters', 'casting']

const FilterSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar, resetFilters] = useFilterStore(
		useShallow((state) => [
			state.openFilterSidebar,
			state.setOpenFilterSidebar,
			state.resetFilters,
		]),
	)
	const [activeTab, setActiveTab] = useState(tabs[0])
	const [searchFilter, setSearchFilter] = useState('')

	const onClose = (): void => {
		setOpenSidebar(false)
	}

	const content = useMemo(() => {
		const component = {
			[tabs[0]]: <Quick searchFilter={searchFilter} />,
			[tabs[1]]: <Filter searchFilter={searchFilter} />,
			[tabs[2]]: <CastingFilter searchFilter={searchFilter} />,
		}

		return component[activeTab]
	}, [activeTab, searchFilter])

	return (
		<Sidebar
			open={openSidebar}
			onClose={onClose}
			iconClose
			header={{
				title: 'APP_FILTER_SIDEBAR_TITLE',
			}}
			disableBlur>
			<>
				<SidebarTabs
					active={activeTab}
					onChange={(v) => {
						if ([v, activeTab].includes(tabs[2])) {
							resetFilters()
						}
						setActiveTab(v as string)
						setSearchFilter('')
					}}
					options={tabs?.map((tab) => ({
						value: tab,
						label: `APP_${tab?.toUpperCase()}_TAB`,
					}))}
				/>
				{activeTab === tabs[0] ? null : (
					<Input
						variant="default"
						name="filterSearch"
						placeholder={t('APP_SEARCH_FILTER_PLACEHOLDER')}
						value={searchFilter}
						onChange={(e) => setSearchFilter(e.target.value)}
						endAdornment={<SearchIcon color="var(--mono300)" />}
					/>
				)}

				{content}
			</>
		</Sidebar>
	)
}

export default FilterSidebar
