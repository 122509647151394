import Card from 'components/Wizard/Card'

const data = [
	{
		name: 'pools',
		value: 4,
	},
	{
		name: 'active projects',
		value: 2,
	},
	{
		name: 'successful projects',
		value: 12,
	},
	{
		name: 'search result',
		value: 421,
	},
	{
		name: 'other',
		value: 30,
	},
]

const KPI = (): JSX.Element => {
	return (
		<Card wrapperStyle={{ justifyContent: 'space-between' }}>
			<>
				{data.map((d) => (
					<div key={d.name}>
						<h2 className="h2-m">{d.value}</h2>
						<span className="body1-m" style={{ color: 'var(--mono300)' }}>
							{d.name}
						</span>
					</div>
				))}
			</>
		</Card>
	)
}

export default KPI
