import { useRef } from 'react'
import { toast } from 'react-toastify'
import { addRelation } from 'api/relations'
import { createCustomer } from 'api/user'
import Button from 'components/UI/Button'
import Input from 'components/UI/Input'
import Card from 'components/Wizard/Card'
import { t } from 'helpers'
import contentParser from 'helpers/propertiesParser'
import useRelations from 'hooks/api/useRelationsContent'
import { RELATIONS } from 'types/enums'
import { User } from 'types/user'

const Boards = (): JSX.Element => {
	const { data, refresh } = useRelations([RELATIONS.PROJECT])
	const boards = data ? (data[RELATIONS.PROJECT] as unknown as User[]) : []
	const nameRef = useRef<HTMLInputElement>(null)

	const create = async (): Promise<void> => {
		try {
			const value = nameRef.current?.value || ''
			const response = await createCustomer('Project', value)
			if (response) {
				const newBoard = contentParser<User>(response)

				await addRelation({
					relation: RELATIONS.CREATOR,
					targetId: newBoard.CustomUserId,
					type: 'Customer',
				})
				await addRelation({
					relation: RELATIONS.PROJECT,
					targetId: newBoard.CustomUserId,
					type: 'Customer',
				})
			}

			refresh()
			if (nameRef.current) nameRef.current.value = ''
			toast.info(t('APP_BOARD_CREATED'))
		} catch (error) {
			console.log('error:', error)
			toast.info(t('APP_ERROR'))
		}
	}

	return (
		<Card step="boards">
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					gap: '44px',
				}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '20px',
					}}>
					<span className="body1-m">{t('APP_CREATE_BOARD')}</span>
					<Input inputRef={nameRef} />
					<Button
						variant="themed"
						onClick={() => {
							if (nameRef.current?.value) create()
							else toast.info(t('APP_FILL_ALL_FIELDS'))
						}}
						style={{ width: '100%', placeContent: 'center' }}>
						{t('APP_CREATE')}
					</Button>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}>
					<span className="body1-m">{t('APP_BOARDS_LIST')}</span>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '12px',
							marginTop: '20px',
							width: '100%',
						}}>
						{boards?.length ? (
							boards?.map((board) => (
								<div
									key={board.ContentId}
									style={{
										borderRadius: '15px',
										padding: '20px',
										boxSizing: 'border-box',
										backgroundColor: 'var(--elev300)',
										display: 'flex',
										flexDirection: 'column',
										width: 'fit-content',
										gap: '8px',
									}}>
									<span className="body1-m">{board.ContentTitle}</span>
									<span
										className="caption-m"
										style={{ color: 'var(--mono300)' }}>
										{board.CustomUserUserName}
									</span>
								</div>
							))
						) : (
							<span className="body1-m">{t('APP_NO_BOARDS')}</span>
						)}
					</div>
				</div>
			</div>
		</Card>
	)
}
export default Boards
