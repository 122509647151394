import { useMemo } from 'react'
import CountryFlag from 'components/CountryFlag'
import { ft, ib } from 'helpers/constants'
import { useAppStore } from 'store'
import { SelectOption } from 'types/app'
import s from './index.module.scss'

interface Props {
	index: number
	array: SelectOption[]
	option: SelectOption
	showFlag: boolean
	onClick: () => void
	name: string
}

const ItemValue = ({
	index,
	array,
	showFlag,
	option,
	onClick,
	name,
}: Props): JSX.Element => {
	const { countries } = useAppStore.getState()

	const iso2 = showFlag
		? option?.value === 'ENGLISH'
			? 'GB'
			: countries?.find(
					(country) => country.iso2 === option.label?.toLowerCase().slice(0, 2),
			  )?.iso2
		: null

	const valueLabel = useMemo(() => {
		if (name?.includes('Weight') || name?.includes('Height')) {
			const weightType = name?.includes('Weight')
			const v = option.label ? +option.label : 0
			if (weightType) return `${v} kg / ${(v * ib).toFixed(1)} Ib`
			else return `${v} cm / ${(v * ft).toFixed(1)} ft`
		}
	}, [option.label, name])

	return (
		<div className={s.container} onClick={onClick}>
			{iso2 ? <CountryFlag code={iso2} /> : null}
			{option?.color?.startsWith('#') ? (
				<div className={s.color} style={{ backgroundColor: option.color }} />
			) : null}
			<span>
				{valueLabel ||
					(option?.label
						? option.label + (array?.length - 1 === index ? '' : ',')
						: 'N/A')}
			</span>
		</div>
	)
}

export default ItemValue
