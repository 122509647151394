import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCastingStore, useUserStore } from 'store'
import { t } from 'helpers'
import { PATHS } from 'pages/Router'
import s from '../Tabs/index.module.scss'

const CustomTabs = (): JSX.Element => {
	const customTabs = useCastingStore(
		useShallow((state) => state.customCastingTabs),
	)
	const navigate = useNavigate()
	const { hash } = useLocation()
	const { productionId, roleId, userId } = useParams()

	return (
		<div
			style={{ overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
			<div
				className={classNames(s.container, 'hideScrollBar')}
				style={{
					overflowX: 'auto',
					width: 'auto',
					maxWidth: '100%',
				}}>
				{customTabs?.map((tab, index) => (
					<div
						key={tab?.name + index}
						className={classNames(s.tab, {
							[s.active]:
								tab?.substeps?.[0]?.key + '-' + tab?.substeps?.[0]?.index ===
								hash?.slice(1),
						})}
						onClick={() => {
							const { user } = useUserStore.getState()
							navigate(
								`/${user?.CustomerRole?.toLowerCase()}/${
									PATHS.CASTING
								}/${productionId}/${roleId}/${userId}` +
									`#${
										tab?.substeps?.[0]?.key + '-' + tab?.substeps?.[0]?.index
									}`,
							)
						}}>
						<span className="body1-m">{t(tab?.name)}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default CustomTabs
