import { useMemo } from 'react'
import Section from '../Section'
import { t } from 'helpers'
import { PORTFOLIO_TYPES } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	contactFields,
	personalFields,
	skillFields,
} from 'pages/Portfolio/fields'
import { FilterSection } from '../../FilterSidebar'
import { AttributeSelector } from 'types/app'

const attr = PORTFOLIO_TYPES.ATTRIBUTE
const skill = PORTFOLIO_TYPES.SKILL

const filterSections: FilterSection[] = [
	{
		title: 'APP_CONTACTS_FILTER_SECTION',
		fields: Object.values(contactFields) as AttributeSelector[],
		hideByDefault: true,
	},
	{
		title: 'APP_PERSONAL_FILTER_SECTION',
		fields: Object.values(personalFields) as AttributeSelector[],
		hideByDefault: true,
	},
	{
		title: 'APP_BODY_FILTER_SECTION',
		fields: [
			...attributeBasicFields.hair.selectors,
			...attributeBasicFields.eyes.selectors,
			...attributeBasicFields.body.selectors,
			// ...attributeBasicFields.skin.selectors,
		],
		hideByDefault: true,
	},
	{
		title: `APP_${attr}_${skillFields.driving.value}`.toUpperCase(),
		fields: skillFields.driving.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${attr}_${attributeSpecialFields.ability.value}`.toUpperCase(),
		fields: attributeSpecialFields.ability.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${attr}_${attributeBasicFields.voice.value}`.toUpperCase(),
		fields: attributeBasicFields.voice.selectors,
		hideByDefault: true,
	},
	{
		title:
			`APP_${attr}_${attributeSpecialFields.specialFeatures.value}`.toUpperCase(),
		fields: attributeSpecialFields.specialFeatures.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.athletics.value}`.toUpperCase(),
		fields: skillFields.athletics.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.specialSports.value}`.toUpperCase(),
		fields: skillFields.specialSports.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.spokenLanguage.value}`.toUpperCase(),
		fields: skillFields.spokenLanguage.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.actingLanguage.value}`.toUpperCase(),
		fields: skillFields.actingLanguage.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.singing.value}`.toUpperCase(),
		fields: skillFields.singing.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.dancing.value}`.toUpperCase(),
		fields: skillFields.dancing.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.instruments.value}`.toUpperCase(),
		fields: skillFields.instruments.selectors,
		hideByDefault: true,
	},

	{
		title: `APP_${skill}_${skillFields.otherSkill.value}`.toUpperCase(),
		fields: skillFields.otherSkill.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.specialSkill.value}`.toUpperCase(),
		fields: skillFields.specialSkill.selectors,
		hideByDefault: true,
	},
]

interface Props {
	searchFilter: string
}

const Filter = ({ searchFilter }: Props): JSX.Element => {
	const filters = useMemo(
		() =>
			searchFilter
				? filterSections?.map((section) => ({
						...section,
						fields: section?.fields?.filter((field) =>
							t(field.label)
								?.toLowerCase()
								?.includes(searchFilter?.toLowerCase()),
						),
				  }))
				: filterSections,
		[searchFilter],
	)

	return (
		<>
			{filters?.map((section) => (
				<Section section={section} key={section.title} />
			))}
		</>
	)
}

export default Filter
