import Sidebar from 'components/Sidebar'
import { useCastingStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import Message from './components/Message'
import { EventBus } from 'helpers/EventBus'
import { t } from 'helpers'

const SubmitCastingSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar] = useCastingStore(
		useShallow((state) => [
			state.openSubmitCastingSidebar,
			state.setOpenSubmitCastingSidebar,
		]),
	)

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			header={{
				title: 'APP_SUBMIT_CASTING_SIDEBAR_TITLE',
				description: 'APP_SUBMIT_CASTING_SIDEBAR_DESCRIPTION',
			}}
			button={{
				children: t('APP_SUBMIT'),
				onClick: () => EventBus.$emit('submitCastingScenesSidebar'),
			}}>
			<>{openSidebar ? <Message /> : null}</>
		</Sidebar>
	)
}

export default SubmitCastingSidebar
