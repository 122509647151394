import {
	Dispatch,
	Fragment,
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
} from 'react'
import classNames from 'classnames'
import NetworkCard from 'components/NetworkCard'
import { useIntersectionObserver, useStickyContainer } from 'hooks'
import { t } from 'helpers'
import { User } from 'types/user'
import s from './index.module.scss'

interface Props {
	users: {
		key: string
		data: User[] | undefined
	}[]
	setUser: (user: User | null) => void
	letters: string[]
	activeLetter: string
	setActiveLetter: Dispatch<SetStateAction<string>>
}

const List = ({
	users,
	setUser,
	letters,
	activeLetter,
	setActiveLetter,
}: Props): JSX.Element => {
	const visibleList = useRef<string[]>([])
	const uniqueLetters = [
		...new Set(
			[
				...new Set(users?.map((u) => u.key)),
				users?.length > 0 ? letters[letters?.length - 1] : '',
			]?.filter((v) => !!v),
		),
	]

	useStickyContainer(
		'network-alphabet-list',
		(e) => {
			e.classList.add(s.sticky)
			const neighbor = e.parentElement?.childNodes[1] as HTMLDivElement
			if (neighbor) neighbor.style.marginLeft = '38px'
		},
		(e) => {
			e.classList.remove(s.sticky)
			const neighbor = e.parentElement?.childNodes[1] as HTMLDivElement
			if (neighbor) neighbor.style.marginLeft = '0px'
		},
	)

	const onScrollAlphabet = useCallback(
		(letter: string, visible: boolean): void => {
			let newArray: string[] = []
			if (visible) newArray = visibleList.current.concat(letter)
			else newArray = visibleList.current.filter((l) => l !== letter)

			const sorted = newArray.sort((a, b) => a.localeCompare(b))
			if (sorted[0]) setActiveLetter(sorted[0])

			visibleList.current = sorted
		},
		[setActiveLetter],
	)

	return (
		<>
			<span className="body1-b">{t('APP_USER_LIST_TITLE')}</span>
			<div className={s.wrapper}>
				<div className={s.letterList} id={'network-alphabet-list'}>
					{uniqueLetters?.map((letter, index, array) => (
						<Fragment key={letter}>
							<span
								onClick={() => {
									setActiveLetter(letter)
									const element = document.querySelector(
										`[data-key=${letter?.toLowerCase()}]`,
									)
									if (element) element.scrollIntoView({ behavior: 'smooth' })
									else {
										const letterBefore = array[index - 1]
										const el = document.querySelector(
											`[data-key=${letterBefore?.toLowerCase()}]`,
										)
										if (el) el.scrollIntoView({ behavior: 'smooth' })
									}
								}}
								className="caption-r"
								style={{
									color:
										activeLetter === letter || activeLetter === array[index - 1]
											? 'var(--theme-primary)'
											: 'var(--mono300)',
								}}>
								{letter}
							</span>
							{array[index + 1] !==
							letters[letters?.findIndex((l) => l === letter) + 1] ? (
								<div
									className={s.letterDivider}
									style={{
										backgroundColor:
											activeLetter === letter
												? 'var(--theme-primary)'
												: 'var(--mono300)',
									}}
								/>
							) : null}
						</Fragment>
					))}
				</div>
				<div className={s.listWrapper}>
					{users?.map((list) => (
						<Item
							key={list.key}
							item={list}
							setUser={setUser}
							onScrollAlphabet={onScrollAlphabet}
						/>
					))}
				</div>
			</div>
		</>
	)
}

export default List

const Item = ({
	item,
	setUser,
	onScrollAlphabet,
}: {
	setUser: (user: User | null) => void
	item: {
		key: string
		data: User[] | undefined
	}
	onScrollAlphabet: (l: string, v: boolean) => void
}): JSX.Element => {
	const id = 'users-list_' + item.key
	const visible = useIntersectionObserver(id, '0%', 0.6)

	useEffect(() => {
		onScrollAlphabet(item.key, visible)
	}, [visible, item.key, onScrollAlphabet])

	return (
		<div className={s.list}>
			<h4 className="h4-m" data-key={item.key?.toLowerCase()}>
				{item.key}
			</h4>
			<div className={classNames('grid6column', s.userList)} id={id}>
				{item.data?.map((user) => (
					<NetworkCard
						key={user.ContentId}
						data={user}
						onClick={() => setUser(user)}
					/>
				))}
			</div>
		</div>
	)
}
