import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import {
	CastingIcon,
	EditIcon,
	FilterIcon,
	InfoFilledIcon,
	PeopleIcon,
	PlusIcon,
} from 'assets/icons'
import Card from 'components/Wizard/Card'
import ProductionCard from 'components/ProductionCard'
import Button from 'components/UI/Button'
import Recent from './components/Recent'
import useContentSearch from 'hooks/api/useContentSearch'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useCollection from 'hooks/api/useCollection'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'
import { Content } from 'types/app'
import { PATHS } from 'pages/Router'
import s from './index.module.scss'
import { directorCastingSteps } from '../../DirectorCasting'

const MyProductions = (): JSX.Element => {
	const navigate = useNavigate()
	const { hash } = useLocation()
	const [search] = useAppStore(
		useShallow((state) => [state.localSearchSidebar?.search]),
	)
	const { contents, refresh } = useContentSearch(true, 'Collection', search)

	const { data } = useRelationsContent(
		[RELATIONS.FAVCOLLECTION],
		undefined,
		'Customer',
		'?reverse=true&orderBy=UpdatedDate&itemLimit=10',
	)

	const favContent = data?.[RELATIONS.FAVCOLLECTION]

	useEffect(() => {
		if (hash?.includes(directorCastingSteps.myproduction.key + '-0'))
			EventBus.$emit('refreshMyProduction')
		else {
			const { localSearchSidebar, setLocalSearchSidebar } =
				useAppStore.getState()
			if (localSearchSidebar?.search) setLocalSearchSidebar(null)
		}
	}, [hash])

	useEffect(() => {
		EventBus.$on('refreshMyProduction', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshMyProduction')
		}
	}, [refresh])

	const openProduction = (prod: Content): void => {
		if (prod?.ContentSubType === 'Collection') {
			const { user } = useUserStore.getState()
			const { stepWizard } = useAppStore.getState()
			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${
					PATHS.PROJECT
				}/${prod.ContentId}`,
			)
			stepWizard?.nextStep()

			addRelation({
				relation: RELATIONS.FAVCOLLECTION,
				targetId: prod.ContentId,
				type: 'Customer',
			})
		}
	}

	const openRoles = (prod: Content): void => {
		if (prod?.ContentSubType === 'Collection') {
			const { user } = useUserStore.getState()
			const { stepWizard } = useAppStore.getState()
			stepWizard?.goToStep(stepWizard.currentStep + 3)
			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${
					PATHS.PROJECT
				}/${prod.ContentId}#` +
					directorCastingSteps.myproduction?.substeps?.[3]?.key +
					'-' +
					directorCastingSteps.myproduction?.substeps?.[3]?.index,
			)

			addRelation({
				relation: RELATIONS.FAVCONTENT,
				targetId: prod.ContentId,
				type: 'Customer',
			})
		}
	}

	const openProductionCreation = (): void => {
		const { user } = useUserStore.getState()
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${PATHS.PROJECT}`,
		)
		const { stepWizard } = useAppStore.getState()
		stepWizard?.nextStep()
	}

	const openCasting = (prodId: string, roleId: string): void => {
		const { user } = useUserStore.getState()
		const { stepWizard } = useAppStore.getState()
		stepWizard?.goToStep(2)
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${
				PATHS.CASTING
			}/${prodId}/${roleId}#` +
				directorCastingSteps.board.key +
				'-' +
				1,
		)
	}

	return (
		<Card
			step="myproductions"
			noHint
			headerChildrens={
				<Button
					style={{
						gap: '8px',
						color: 'var(--mono100)',
						height: 'fit-content',
					}}
					onClick={() => {
						const { setLocalSearchSidebar, localSearchSidebar } =
							useAppStore.getState()
						setLocalSearchSidebar({
							open: true,
							search: localSearchSidebar?.search || '',
						})
					}}>
					{t('APP_SHOW_FILTERS')}
					<FilterIcon color={search ? 'var(--theme-primary)' : undefined} />
				</Button>
			}>
			<>
				{favContent?.length ? (
					<Recent
						contents={favContent}
						openCasting={openCasting}
						openProduction={openProduction}
					/>
				) : null}
				<span className="body1-b">{t('APP_PRODUCTIONS_LIST')}</span>
				<div className={s.gridWrapper}>
					<div onClick={openProductionCreation} className="card addItemCard">
						<PlusIcon color="var(--mono400)" />
						<span className="body1-m" style={{ color: 'var(--mono400)' }}>
							{t('APP_ADD_PROJECT_LABEL')}
						</span>
					</div>
					{contents?.map((content) => (
						<ProductionItem
							key={content.ContentId}
							content={content}
							openProduction={openProduction}
							openCasting={openCasting}
							openRoles={openRoles}
						/>
					))}
				</div>
			</>
		</Card>
	)
}

export default MyProductions

const ProductionItem = ({
	content,
	openProduction,
	openCasting,
	openRoles,
}: {
	content: Content
	openProduction: (c: Content) => void
	openCasting: (prodId: string, roleId: string) => void
	openRoles: (c: Content) => void
}): JSX.Element => {
	const { collection = [] } = useCollection(content)
	const role = collection?.[0]

	return (
		<ProductionCard
			key={content.ContentId}
			content={content}
			children={
				<>
					<div
						className="hoverButtonWrapper"
						onClick={() => openProduction(content)}>
						<EditIcon />
						<span>{t('APP_OPEN_HOVER_LABEL')}</span>
					</div>
					<div
						className="hoverButtonWrapper"
						onClick={() => {
							const { setProdInfoSidebar } = useAppStore.getState()
							setProdInfoSidebar(content.ContentId)
						}}>
						<InfoFilledIcon />
						<span>{t('APP_DETAILS_HOVER_LABEL')}</span>
					</div>
					<div
						className="hoverButtonWrapper"
						onClick={() => openCasting(content?.ContentId, role?.ContentId)}>
						<CastingIcon />
						<span>{t('APP_CASTINGS_HOVER_LABEL')}</span>
					</div>
					<div
						className="hoverButtonWrapper"
						onClick={() => openRoles(content)}>
						<PeopleIcon />
						<span>{t('APP_ROLES_HOVER_LABEL')}</span>
					</div>
				</>
			}
		/>
	)
}
