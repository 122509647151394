import { toast } from 'react-toastify'
import { useShallow } from 'zustand/react/shallow'
import { useState } from 'react'
import PhoneInput from 'components/UI/PhoneInput'
import Sidebar from 'components/Sidebar'
import CountrySelector from 'components/CountrySelector'
import Input from 'components/UI/Input'
import Select from 'components/UI/Select'
import { optionsParser, t } from 'helpers'
import { useAppStore, useNetworkStore } from 'store'
import { DROPDOWN_OPTIONS } from 'types/enums'

const properties = [
	'name',
	'email',
	'mobilephone',
	'location',
	'city',
	'gender',
	'roleType',
	'photo',
]

const AddUserSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar] = useNetworkStore(
		useShallow((state) => [
			state.openAddUserSidebar,
			state.setOpenAddUserSidebar,
		]),
	)
	const [values, setValues] = useState<{ [key: string]: string }>(
		properties?.reduce(
			(prev, next) => ({
				...prev,
				[next]: '',
			}),
			{},
		),
	)

	const addUser = (): void => {
		if (Object.values(values)?.length === properties?.length) {
			const { stepWizard } = useAppStore.getState()
			stepWizard?.nextStep()
			const { setParsedUsers } = useNetworkStore.getState()
			setParsedUsers([
				{
					name: values?.name,
					email: values?.email,
					phone: values?.mobilephone,
					location: values?.location,
					city: values?.city,
					gender: values?.gender,
					roleType: values?.roleType,
					photo: values?.photo,
				},
			])

			setOpenSidebar(false)
		} else toast.error('APP_FILL_ALL_FIELDS')
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			header={{
				title: 'APP_NETWORK_ADD_USER_SIDEBAR_TITLE',
				description: 'APP_NETWORK_ADD_USER_SIDEBAR_DESCRIPTION',
			}}
			button={{
				onClick: addUser,
				children: t('APP_ADD_USER_BUTTON'),
			}}>
			<>
				{properties?.map((name) =>
					name === 'location' ? (
						<CountrySelector
							key={name}
							value={values[name]}
							onChange={(e) =>
								setValues((prev) => ({ ...prev, [name]: e.target.value }))
							}
						/>
					) : name === 'mobilephone' ? (
						<PhoneInput
							key={name}
							label={`APP_${name?.toUpperCase()}_LABEL`}
							defaultValue={values[name]}
							onChange={(e) =>
								setValues((prev) => ({ ...prev, [name]: e.target.value }))
							}
						/>
					) : name === 'gender' || name === 'roleType' ? (
						<Select
							key={name}
							onChange={(e) =>
								setValues((prev) => ({ ...prev, [name]: e.target.value }))
							}
							name={name}
							label={`APP_${name?.toUpperCase()}_LABEL`}
							inputProps={{
								value: values[name],
							}}
							options={optionsParser(
								name === 'gender'
									? DROPDOWN_OPTIONS.GENDERS
									: DROPDOWN_OPTIONS.ROLE_TYPE,
							)}
						/>
					) : (
						<Input
							key={name}
							label={`APP_${name?.toUpperCase()}_LABEL`}
							value={values[name]}
							onChange={(e) =>
								setValues((prev) => ({ ...prev, [name]: e.target.value }))
							}
						/>
					),
				)}
			</>
		</Sidebar>
	)
}

export default AddUserSidebar
