import { useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import {
	ArrowShortIcon,
	FemaleGenderIcon,
	LikeIcon,
	MaleGenderIcon,
	OtherGenderIcon,
} from 'assets/icons'
import Sidebar from 'components/Sidebar'
import CastingUsers from './components/CastingUsers'
import useContent from 'hooks/api/useContent'
import useAccessRelation from 'hooks/api/useAccessRelation'
import useUsers from 'hooks/api/useUsers'
import { filterDuplicates } from 'helpers/propertiesParser'
import { optionsParser, t, textParser } from 'helpers'
import { useAppStore, useModalStore, useUserStore } from 'store'
import { CASTING_STATUS, DROPDOWN_OPTIONS } from 'types/enums'
import { CUSTOMER_ROLE, User } from 'types/user'
import s from './index.module.scss'

const RoleInfoSidebar = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state.user))
	const director =
		user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.CASTING_DIRECTOR
	const [roleId, setOpenSidebar] = useAppStore(
		useShallow((state) => [state.roleInfoSidebar, state.setRoleInfoSidebar]),
	)
	const modalOpened = useModalStore(useShallow((state) => state.open?.show))
	const [loading, setLoading] = useState(false)
	const [extendDesc, setExtendDesc] = useState(false)
	const { content } = useContent(roleId)

	const { data } = useAccessRelation()

	const { users } = useUsers(
		data
			?.filter((relation) => relation.sourceId === roleId)
			?.map((relation) => relation.targetId),
	)

	const list = useMemo(
		() => filterDuplicates(users || [], 'ContentId') as User[],
		[users],
	)

	const languageOptions = optionsParser(DROPDOWN_OPTIONS.LANGUAGES_CHECKBOX)
	const genderOptions = optionsParser(DROPDOWN_OPTIONS.GENDERS)

	return (
		<Sidebar
			open={!!roleId}
			onClose={() => setOpenSidebar('')}
			iconClose={modalOpened}
			loading={loading}
			header={{
				title: 'APP_ROLE_INFO_SIDEBAR_TITLE',
			}}>
			<>
				<div className={s.wrapper}>
					<div className={s.info}>
						<h4>{content?.['Globalizationen-USContentTitle']}</h4>
						<div className={s.gender}>
							{!content?.ContentRoleGender ? null : content?.ContentRoleGender ===
							  'MALE' ? (
								<MaleGenderIcon />
							) : content?.ContentRoleGender === 'FEMALE' ? (
								<FemaleGenderIcon />
							) : (
								<OtherGenderIcon />
							)}
							<span className="body2-m">
								{
									genderOptions?.find(
										(option) => option.value === content?.ContentRoleGender,
									)?.label
								}
							</span>
						</div>
					</div>
					<span className="body1-b">{t('APP_DESCRIPTION_LABEL')}</span>
					{content?.['Globalizationen-USContentDescription'] ? (
						<div
							className={s.desc}
							style={{
								height: extendDesc ? 'auto' : '34px',
								overflow: 'hidden',
							}}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '5px',
								}}>
								{textParser(
									content?.['Globalizationen-USContentDescription'],
								)?.map((text) => (
									<span
										key={text}
										className="body2-s"
										style={{
											position: 'relative',
										}}>
										{text}
									</span>
								))}
							</div>
							<div
								style={{
									marginLeft: 'auto',
									display: 'flex',
									cursor: 'pointer',
									height: 'fit-content',
									transform: 'translateY(25%)',
								}}
								onClick={() => setExtendDesc((prev) => !prev)}>
								<ArrowShortIcon
									style={{
										transform: extendDesc
											? 'translateY(-50%) rotate(-90deg)'
											: 'translateY(-50%) rotate(90deg)',
									}}
								/>
							</div>
						</div>
					) : null}
					<div className="grid3column">
						<div className={s.item}>
							<span className="caption-m">{t('APP_ROLE_TYPE_LABEL')}</span>
							<span>{content?.ContentRoleType || 'N/A'}</span>
						</div>
						<div className={s.item}>
							<span className="caption-m">{t('APP_LANGUAGE_LABEL')}</span>
							<span>
								{languageOptions.find(
									(option) => option.value === content?.ContentRoleDialect,
								)?.label || 'N/A'}
							</span>
						</div>
						<div className={s.item}>
							<span className="caption-m">{t('APP_ROLE_SHOOTING_DAYS')}</span>
							<span>
								{content?.ContentShootingDays
									? `
							${content?.ContentShootingDays} day`
									: 'N/A'}
							</span>
						</div>
					</div>
				</div>

				{director ? (
					<>
						<CastingUsers
							setLoading={setLoading}
							users={list}
							options={[
								{
									label: 'APP_INVITED_LABEL',
									value: CASTING_STATUS.INVITED,
								},
								{
									label: 'APP_REJECTED_LABEL',
									value: CASTING_STATUS.REJECTED,
								},
								{
									label: 'APP_PENDING_LABEL',
									value: CASTING_STATUS.WAITING,
								},
							]}
						/>
						<CastingUsers
							setLoading={setLoading}
							users={list}
							options={[
								{
									label: (
										<div
											style={{
												width: '24px',
												height: '24px',
												position: 'relative',
											}}>
											<span
												style={{
													color: 'var(--mono300)',
													position: 'absolute',
													top: '50%',
													left: '50%',
													transform: 'translate(-50%, -50%)',
												}}>
												★
											</span>
										</div>
									),
									value: CASTING_STATUS.FAVORITE,
								},
								{
									label: <LikeIcon color="var(--mono300)" />,
									value: CASTING_STATUS.LIKED,
								},
								{
									label: (
										<div
											style={{
												padding: '12px',
												position: 'relative',
											}}>
											<div
												style={{
													position: 'absolute',
													top: '50%',
													left: '50%',
													transform: 'translate(-50%, -50%)',
													width: '12px',
													height: '12px',
													border: '1px solid var(--mono300)',
													borderRadius: '50%',
												}}
											/>
										</div>
									),
									value: CASTING_STATUS.APPLIED,
								},
								{
									label: (
										<LikeIcon
											color="var(--mono300)"
											style={{ transform: 'rotate(180deg)' }}
										/>
									),
									value: CASTING_STATUS.DISLIKED,
								},
							]}
						/>
						<CastingUsers
							setLoading={setLoading}
							users={list}
							options={[
								{
									label: 'APP_SELECTED_LABEL',
									value: CASTING_STATUS.SELECTED,
								},
								{
									label: 'APP_DECLINED_LABEL',
									value: CASTING_STATUS.DECLINED,
								},
							]}
						/>
					</>
				) : null}
			</>
		</Sidebar>
	)
}

export default RoleInfoSidebar
