import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip'
import { t } from 'helpers'
import s from './index.module.scss'

const Tooltip = ({ title, children, ...props }: TooltipProps): JSX.Element => (
	<MuiTooltip
		{...props}
		className={s.tooltip}
		componentsProps={{
			tooltip: {
				sx: {
					bgcolor: 'var(--elev400)',
					color: 'var(--mono200)',
					fontSize: '14px',
					fontFamily: 'Lato Regular',
				},
			},
		}}
		title={t((title || '') as string)}>
		<div style={{ display: 'flex' }}>{children}</div>
	</MuiTooltip>
)

export default Tooltip
