import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { ArrowShortIcon } from 'assets/icons'
import InputLabel from 'components/UI/InputLabel'
import { usePortfolioStore } from 'store'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { SelectOption } from 'types/app'
import { contactFields } from 'pages/Portfolio/fields'
import s from './index.module.scss'

const Agency = (): JSX.Element => {
	const [agency, openSidebar] = usePortfolioStore(
		useShallow((state) => [
			state.cardValues['contact'][contactFields.agency.name],
			state.openAgencySidebar,
		]),
	)

	const { data } = useCustomerSearch({
		fetch: true,
		filters: [
			{
				propName: 'Customer:Role',
				propValue: 'Agency',
			},
		],
	})

	const agencyOptions: SelectOption[] = data
		? data?.map((user) => ({
				value: user.ContentId,
				label: user.ContentTitle || user.ContentId,
				element: (
					<div
						style={{
							width: '26px',
							height: '26px',
							overflow: 'hidden',
							borderRadius: '50%',
						}}>
						<img
							src={user?.CustomerImageSquareUrl}
							alt={user?.ContentTitle}
							style={{ width: '100%', height: '100%' }}
						/>
					</div>
				),
		  }))
		: []

	const value = agencyOptions.find((option) => option.value === agency)

	return (
		<div
			className={s.container}
			onClick={() => {
				const { setOpenAgencySidebar } = usePortfolioStore.getState()
				setOpenAgencySidebar(true)
			}}>
			<InputLabel
				label={contactFields.agency.label}
				status={openSidebar ? 'loading' : agency ? 'filled' : 'none'}
			/>
			<div className={classNames('card', s.wrapper)}>
				<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
					{value?.element}
					<span style={{ color: 'var(--mono200)' }}>{value?.label}</span>
				</div>
				<div style={{ display: 'flex' }}>
					<ArrowShortIcon color="var(--mono200)" />
				</div>
			</div>
		</div>
	)
}

export default Agency
