import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { SearchIcon } from 'assets/icons'
import NetworkCard from 'components/NetworkCard'
import Sidebar from 'components/Sidebar'
import Input from 'components/UI/Input'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { useModalStore, usePortfolioStore, useUserStore } from 'store'
import { contactFields } from 'pages/Portfolio/fields'

const AgencySidebar = (): JSX.Element => {
	const [modalOpened] = useModalStore(useShallow((state) => [state.open]))
	const [openSidebar, setOpenSidebar] = usePortfolioStore(
		useShallow((state) => [
			state.openAgencySidebar,
			state.setOpenAgencySidebar,
		]),
	)
	const [searchFilter, setSearchFilter] = useState('')

	const { data = [] } = useCustomerSearch({
		fetch: true,
		filters: [
			{
				propName: 'Customer:Role',
				propValue: 'Agency',
			},
		],
	})

	const onClick = (userId: string): void => {
		const ignore = (): void => {
			const { updateFields } = usePortfolioStore.getState()
			updateFields([
				{
					Name: contactFields.agency.name,
					Value: userId,
				},
				{
					Name: 'Custom:Portfolio:Agency:Authorized',
					Value: 'NO',
				},
			])
			setOpenSidebar(false)
			setOpen(null)
		}

		const { user } = useUserStore.getState()
		const { setOpen } = useModalStore.getState()
		setOpen({
			show: true,
			onClose: () => {
				ignore()
			},
			children: (
				<div
					className="card"
					style={{
						maxWidth: '500px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '20px',
					}}>
					<>
						<h4 className="h4-m">{t('APP_ACCEPT_AGENCY_DATA_POLICY')}</h4>
						<span>
							{t('APP_AGENCY_DATA_POLICY')?.replace(
								'[Your Name]',
								user?.ContentTitle || '',
							)}
						</span>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={() => {
									const { updateFields, setCardValues } =
										usePortfolioStore.getState()
									setCardValues(
										{
											[contactFields.agency.name]: userId,
										},
										'contact',
									)
									updateFields([
										{
											Name: contactFields.agency.name,
											Value: userId,
										},
										{
											Name: 'Custom:Portfolio:Agency:Authorized',
											Value: 'YES',
										},
									])
									setOpenSidebar(false)
									setOpen(null)
								}}>
								{t('APP_AUTHORIZE')}
							</Button>
							<Button
								style={{ width: '100%' }}
								onClick={() => {
									ignore()
								}}>
								{t('APP_IGNORE')}
							</Button>
						</div>
					</>
				</div>
			),
		})
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			iconClose={modalOpened?.show}
			header={{
				title: t('APP_AGENCY_SIDEBAR_TITLE'),
			}}>
			<>
				<Input
					variant="default"
					name="filterSearch"
					placeholder={t('APP_SEARCH_FILTER_PLACEHOLDER')}
					value={searchFilter}
					onChange={(e) => setSearchFilter(e.target.value)}
					endAdornment={<SearchIcon color="var(--mono300)" />}
				/>
				{data?.map((user) => (
					<NetworkCard
						style={{
							width: '100%',
						}}
						variant="small"
						key={user?.ContentId}
						data={user}
						onClick={() => onClick(user?.ContentId)}
					/>
				))}
			</>
		</Sidebar>
	)
}

export default AgencySidebar
