import { createWithEqualityFn } from 'zustand/traditional'
import { FilterStore } from 'types/store'

const useStore = createWithEqualityFn<FilterStore>(
	(set) => ({
		filters: {},
		setFilters: (filter) =>
			set((state) => ({
				filters: {
					...state.filters,
					...filter,
				},
			})),
		openFilterSidebar: false,
		setOpenFilterSidebar: (open) => set({ openFilterSidebar: open }),
		resetFilters: () => set({ filters: {} }),
	}),
	Object.is,
)

export default useStore
