import { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'react-toastify'
import {
	createContent,
	createContentAsset,
	createContentUpload,
	partialContentUpdate,
} from 'api/content'
import { multiRelationUpdate } from 'api/relations'
import { upload } from 'api/aws'
import Sidebar from 'components/Sidebar'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import FileUpload from 'components/FileUpload'
import InstructionsSidebar from './components/InstructionsSidebar'
import Instructions from './components/Instructions'
import Details from './components/Details'
import Loader from 'components/Loader'
import useContent from 'hooks/api/useContent'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useContentFullRelation from 'hooks/api/useContentFullRelation'
import { capitalize, t, wait } from 'helpers'
import { getVideoDuration } from 'helpers/getVideoMetadata'
import { EventBus } from 'helpers/EventBus'
import { videoUploadWorkflowId } from 'helpers/constants'
import { useAppStore, useProductionStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'
import { Attachment } from 'types/app'
import s from './index.module.scss'

const tabs = [
	{
		label: 'APP_DETAILS_TAB',
		value: 0,
	},
	{
		label: 'APP_INSTRUCTIONS_TAB',
		value: 1,
	},
	{
		label: 'APP_UPLOAD_TAB',
		value: 2,
	},
]

const AddSceneSidebar = (): JSX.Element => {
	const { productionId, roleId, productionRoleId, userId } = useParams()
	const [
		openSidebar,
		setOpenSidebar,
		scene,
		setScene,
		instructionSidebarOpened,
	] = useAppStore(
		useShallow((state) => [
			state.addSceneSidebar.open,
			state.setOpenAddSceneSidebar,
			state.addSceneSidebar.scene,
			state.addSceneSidebar.setScene,
			state.addSceneSidebar.openInstructions,
		]),
	)
	const [currentScene] = useProductionStore(
		useShallow((state) => [state.currentScene]),
	)
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(0)
	const [uploaded, setUploaded] = useState<{ file: File; duration: number }>()
	const [loading, setLoading] = useState(false)

	const { content: production } = useContent(productionId)
	const { content: role } = useContent(roleId || productionRoleId)
	const { data } = useRelationsContent(
		roleId ? [RELATIONS.CONTENT] : [],
		`${roleId}_Content_Access`,
		'Relationship',
	)
	const playlistContent = userId
		? data?.[RELATIONS.CONTENT]?.find(
				(content) => content?.ContentOwnerId === userId,
		  )
		: null

	const { contents } = useContentFullRelation(
		currentScene ? productionRoleId : undefined,
		RELATIONS.PLAYLIST,
	)
	const fullSceneContent = contents?.find((c) => currentScene === c.ContentId)

	useEffect(() => {
		if (
			openSidebar &&
			fullSceneContent &&
			fullSceneContent?.assets?.length &&
			fullSceneContent?.assets?.filter((a) => !a.isDeleted)?.length &&
			!scene?.attachments?.length
		) {
			setScene({
				attachments: fullSceneContent?.assets
					?.filter((a) => !a.isDeleted)
					?.map(
						(asset) =>
							({
								name: asset.fileName,
								url: asset.publicUrl,
								id: asset.id,
								contentId: fullSceneContent.ContentId,
							} as Attachment),
					),
			})
		}
	}, [openSidebar, fullSceneContent, scene?.attachments, setScene])

	const clearValues = (): void => {
		setActiveTab(0)
		swiperRef.current?.slideTo(0)
		setScene({
			title: '',
			duration: 0,
			code: '',
			attachments: [],
			dress: '',
			instructions: [],
		})
		const input = document.getElementById('scenevideo_new') as HTMLInputElement
		if (input) input.value = ''
		const { setCurrentScene } = useProductionStore.getState()
		setCurrentScene('')
		setOpenSidebar(false)
	}

	const onChange = async (file: File): Promise<void> => {
		const duration = await getVideoDuration(file)
		const fileDuration = duration ? +duration : 0
		const sceneDuration = scene?.duration
		if (
			fileDuration > 0 &&
			sceneDuration > 0 &&
			fileDuration <= sceneDuration
		) {
			setUploaded({
				file,
				duration: fileDuration,
			})
		} else {
			toast.info(t('APP_SCENE_DURATION_FILE_ERROR'))
			const input = document.getElementById(
				'scenevideo_new',
			) as HTMLInputElement
			if (input) input.value = ''
		}
	}

	const createScene = async (): Promise<void> => {
		try {
			setLoading(true)
			const { user } = useUserStore.getState()

			const response = await createContent({
				originalTitle: `${user?.ContentTitle} | ${production?.ContentTitle} | ${role?.ContentTitle} | ${scene?.title}`,
				localizations: {
					'en-US': {
						name: scene?.title,
					},
				},
				Type: 'Playlist',
				published: true,
				duration: (scene?.duration * 1000) as unknown as string,
				properties: {
					Duration: (scene?.duration * 1000)?.toString(),
					'Content:Tape:Type': capitalize(scene?.code),
					'Content:Role:Dress': scene.dress,
				},
				labels: {
					Instruction: scene.instructions,
				},
			})
			const id = roleId || productionRoleId
			if (response.id && id) {
				multiRelationUpdate(id, response.id)

				if (scene?.attachments?.length) {
					scene?.attachments.forEach(async (file) => {
						if (file instanceof File) {
							const script = await upload({
								fileObj: file,
								assetType: 'Content',
							})

							await createContentAsset(
								{
									...script,
									originalTitle: file.name,
									originalLanguage: 'en-US',
									type: 'Image',
									locale: 'en-US',
									localizations: {
										'en-US': { name: file.name },
									},
									originalFileName: file.name,
									objectUrl: script.fileAssetUrl,
									publicUrl: script.cdnUrl,
								},
								response.id,
							)
						}
					})
				}

				if (uploaded)
					await uploadSceneContent(
						uploaded.file,
						uploaded.duration,
						response.id,
					)
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			await wait(1000)
			EventBus.$emit('refreshScenes')
			if (!uploaded) clearValues()
		}
	}

	const editScene = async (): Promise<void> => {
		if (!productionRoleId) return
		const { setLoading } = useProductionStore.getState()
		try {
			setLoading(true)
			const { currentScene, createAsset } = useProductionStore.getState()

			await partialContentUpdate(currentScene, {
				localizations: {
					'en-US': {
						name: scene?.title,
					},
				},
				duration: scene?.duration * 1000,
				properties: {
					'Content:Tape:Type': scene?.code,
					Duration: scene?.duration * 1000,
					'Content:Role:Dress': scene.dress,
				},
				labels: {
					Instruction: scene.instructions,
				},
			})

			if (scene?.attachments?.length) {
				scene?.attachments.forEach(async (file) => {
					if (file instanceof File) {
						const image = await upload({
							fileObj: file,
							assetType: 'Content',
						})

						await createAsset(
							image,
							file.name,
							image.originalFileName,
							image.fileAssetUrl,
							image.cdnUrl,
							currentScene,
						)
					}
				})
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			clearValues()
			setLoading(false)
			await wait(1000)
			EventBus.$emit('refreshScenes')
		}
	}

	const uploadSceneContent = async (
		file: File,
		duration: number,
		sceneId: string,
	): Promise<void> => {
		try {
			setLoading(true)

			const uploadedFile = await upload({
				fileObj: file,
				assetType: 'Content',
			})

			const uploadData = {
				...uploadedFile,
				originalTitle: file.name,
				workflowId: videoUploadWorkflowId,
				originalLanguage: 'en-US',
				localizations: {
					'en-US': { name: uploadedFile.originalFileName },
				},
				type: 'Video',
				originalFileName: uploadedFile.originalFileName,
				objectUrl: uploadedFile.fileAssetUrl,
				publicUrl: uploadedFile.cdnUrl,
				duration: duration ? duration * 1000 : 0,
				exhibitionWindow: {
					'--': {
						availableFrom:
							uploadedFile.availableFrom?.toISOString &&
							uploadedFile.availableFrom.toISOString(),
						availableUntil:
							uploadedFile.availableUntil?.toISOString &&
							uploadedFile.availableUntil.toISOString(),
					},
				},
				allowMinting: true,
				allowRemix: true,
				allowComments: true,
				published: true,
			}

			const content = await createContentUpload(uploadData)

			multiRelationUpdate(sceneId, content.id)
			if (playlistContent)
				multiRelationUpdate(
					playlistContent?.ContentId,
					content.id,
					RELATIONS.PLAYLIST,
					scene.title,
				)

			toast.info(t('APP_SCENE_UPLOADED_SUCCESSFULLY'))
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			clearValues()
		}
	}

	return (
		<>
			<Loader loading={loading} adapt />
			<InstructionsSidebar />
			<Sidebar
				open={openSidebar}
				onClose={clearValues}
				iconClose={instructionSidebarOpened}
				button={{
					children: t('APP_DONE'),
					disabled: !scene.title || !scene.duration,
					onClick: currentScene ? editScene : createScene,
				}}
				header={{
					title: t('APP_ADD_SCENE_SIDEBAR_TITLE')?.replace(
						'{Name}',
						scene?.title || '',
					),
					description: 'APP_ADD_SCENE_SIDEBAR_DESCRIPTION',
				}}>
				<>
					<SidebarTabs
						active={activeTab}
						options={tabs?.slice(0, roleId && userId ? 3 : 2)}
						onChange={(v) => {
							setActiveTab(v as number)
							swiperRef.current?.slideTo(v as number)
						}}
					/>
					<Swiper
						spaceBetween={20}
						onSwiper={(swiper: SwiperClass) => {
							swiperRef.current = swiper
						}}
						allowTouchMove={false}
						style={{ width: '100%', height: '100%' }}>
						<SwiperSlide className={s.slide}>
							<Details />
						</SwiperSlide>
						<SwiperSlide className={s.slide}>
							<Instructions content={fullSceneContent} />
						</SwiperSlide>
						<SwiperSlide className={s.slide}>
							<FileUpload
								fileType="Video"
								label="APP_SCENE_VIDEO_LABEL"
								form="rectangle-h"
								direction="vertical"
								itemTitle=""
								hintText=""
								name="scene_video"
								formatsText="APP_SHOWREELS_FORMATS"
								qualityText="APP_SHOWREELS_QUALITY"
								fileInputProps={{
									id: 'scenevideo_new',
									accept: 'video/*',
								}}
								value={uploaded?.file || null}
								onChange={(e) => {
									if (e.target.files && e.target.files[0])
										onChange(e.target.files[0])
								}}
							/>
						</SwiperSlide>
					</Swiper>
				</>
			</Sidebar>
		</>
	)
}

export default AddSceneSidebar
