import { createWithEqualityFn } from 'zustand/traditional'
import { CastingStore } from 'types/store'

const useStore = createWithEqualityFn<CastingStore>(
	(set) => ({
		sceneUploadContent: null,
		uploadedSceneContent: undefined,
		setSceneUploadContent: (content, uploaded) =>
			set({ sceneUploadContent: content, uploadedSceneContent: uploaded }),
		customCastingTabs: null,
		setCustomCastingTabs: (tabs) => set({ customCastingTabs: tabs }),
		openCastingChat: false,
		setOpenCastingChat: (open) => set({ openCastingChat: open }),
		openCastingRoles: false,
		setOpenCastingRoles: (open) => set({ openCastingRoles: open }),
		openReviewNdaSidebar: null,
		setOpenReviewNdaSidebar: (open) => set({ openReviewNdaSidebar: open }),
		chatContentId: '',
		setChatContentId: (id) => set({ chatContentId: id }),
		uploadedSceneIds: [],
		setUploadedSceneIds: (id, duration) =>
			set((state) => ({
				uploadedSceneIds: [...(state.uploadedSceneIds || []), { id, duration }],
			})),
		openMessageToActorSidebar: false,
		setOpenMessageToActorSidebar: (open) =>
			set({ openMessageToActorSidebar: open }),
		openSubmitCastingSidebar: false,
		setOpenSubmitCastingSidebar: (open) =>
			set({ openSubmitCastingSidebar: open }),
		openRejectNdaSidebar: false,
		setOpenRejectNdaSidebar: (open) => set({ openRejectNdaSidebar: open }),
		openRequestSidebar: null,
		setOpenRequestSidebar: (open) => set({ openRequestSidebar: open }),
		castingLoading: false,
		setCastingLoading: (loading) => set({ castingLoading: loading }),
	}),
	Object.is,
)

export default useStore
